import styled from "styled-components";
import { notoSans } from "../../../fonts";

export const Wrapper = styled.div`
  background-color: black;
`;

export const HeaderContainer = styled.div`
  position: relative;
  padding: 20px 5vw;
  background: black;
  display: flex;
  align-items: center;
  gap: 3%;
  border-bottom: solid white 1px;
`;
export const HeaderText = styled.div`
  ${notoSans};
  font-weight: 900;
  font-size: 3.8vw;
  color: white;
`;

export const Container = styled.div`
  position: relative;
  background-color: black;
  width: 100%;
  background-image: url("/images/station/summary/background.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
`;

export const TextContainer = styled.div`
  position: inherit;
  font-size: 1.6vw;
  padding: 4vw;
  max-height: 48.5vw;
  overflow-y: scroll;
  z-index: 0;
`;

export const TextContent = styled.div`
  color: white;
  padding-bottom: 0.5vw;
`;

export const Asset = styled.img`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  z-index: 0;
`;

export const OnOffButton = styled.div`
  position: absolute;
  //width: fit-content;
  //height: fit-content;
  left: 87vw;
  bottom: 3vw;
  color: white;
  z-index: 10;
  cursor: pointer;
  font-size: 1vw;
  font-family: ${notoSans};
  font-weight: 900;
  line-height: 0.328;
  padding: 1vw 0.5vw;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
`;
