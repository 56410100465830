import * as S from "./illustImage.style";

import { useEffect, useRef } from "react";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

function IllustImage({ src, keyframe, animation, animationName }) {
  const imgRef = useRef(null);
  const isInViewport = useIntersectionObserver(imgRef);

  useEffect(() => {
    if (imgRef.current) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (!entry.target.classList.contains("animation"))
                entry.target.classList.add("animation");
            } else {
              if (entry.target.classList.contains("animation")) {
                entry.target.classList.remove("animation");
              }
            }
          });
        },
        { threshold: 0 }
      );
      observer.observe(imgRef.current);
    }
  }, [imgRef.current]);

  return (
    <S.Container>
      <S.ImageContent
        src={src}
        keyframe={keyframe}
        animation={animation}
        animationName={"ani"}
        ref={imgRef}
        className={isInViewport ? "animation" : ""}
      />
    </S.Container>
  );
}

export default IllustImage;
