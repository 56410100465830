import * as S from "./interview.style";
import {
  SectionOneThumbInfos,
  SectionTwoThumbInfos,
  SectionThreeThumbInfos,
} from "./interview.model";
import { useEffect, useState } from "react";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import Gnb from "../../components/organisms/Gnb/Gnb";
import Footer from "../../components/organisms/Footer/Footer";

function Interview() {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const onToggleModal = () => {
    setOpen(!open);
  };
  const onClickThumbnail = (videoUrl) => {
    setUrl(videoUrl);
    onToggleModal();
  };

  return (
    <>
      <S.Container>
        <S.ContainerTitle>인터뷰 영상 모음</S.ContainerTitle>
        <S.Section>
          <S.Line />
          <S.SectionTitle>광주역</S.SectionTitle>
          <S.ThumbContainer>
            {SectionOneThumbInfos.map(({ thumb, video }) => {
              return (
                <S.Thumb
                  key={`key-${thumb}`}
                  src={thumb}
                  onClick={() => onClickThumbnail(video)}
                />
              );
            })}
            )}
          </S.ThumbContainer>
        </S.Section>
        <S.Section>
          <S.Line />
          <S.SectionTitle>전남대학교</S.SectionTitle>
          <S.ThumbContainer>
            {SectionTwoThumbInfos.map(({ thumb, video }) => {
              return (
                <S.Thumb
                  key={`key-${thumb}`}
                  src={thumb}
                  onClick={() => onClickThumbnail(video)}
                />
              );
            })}
            )}
          </S.ThumbContainer>
        </S.Section>
        <S.Section>
          <S.Line />
          <S.SectionTitle>광주교도소</S.SectionTitle>
          <S.ThumbContainer>
            {SectionThreeThumbInfos.map(({ thumb, video }) => {
              return (
                <S.Thumb
                  key={`key-${thumb}`}
                  src={thumb}
                  onClick={() => onClickThumbnail(video)}
                />
              );
            })}
          </S.ThumbContainer>
        </S.Section>
      </S.Container>

      {open && <VideoPlayer toggleModal={onToggleModal} url={url} />}
      {/*<Footer />*/}
    </>
  );
}

export default Interview;
