import styled from "styled-components";
import { notoSans } from "../../../fonts";

export const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 25px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  ${notoSans};
  font-weight: 900;
  z-index: 10;
`;

export const Title = styled.div`
  color: white;
  font-size: max(1.5vw, 20px);
`;

export const NavContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
`;

export const Navigator = styled.div`
  color: white;
  margin-right: 5vw;
  font-size: max(1.2vw, 16px);
  float: left;
  cursor: pointer;
  font-weight: 900;
  height: 1.6vw;
  position: relative;
`;

export const AccountNavigator = styled.div`
  color: white;
  margin-right: 5vw;
  border-radius: 50%;
  width: 1.6vw;
  height: 1.6vw;
  float: left;
  cursor: pointer;
  font-weight: 900;
  background-image: url("/images/login/account.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
`;
