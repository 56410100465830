import { QuoteSvg, QuoteImg } from "../../atoms/Quote.svg";
import * as S from "./QuoteBox.style";

const QuoteBox = ({
  drilledRef,
  position,
  contents,
  source,
  isAbsolute,
  color,
  exception,
}) => {
  return (
    <S.Container
      isAbsolute={isAbsolute}
      color={color}
      ref={drilledRef}
      position={position}
    >
      {exception === "station-section-6" ? (
        <>
          {contents}
          {source && <span>{source}</span>}
        </>
      ) : (
        <>
          <QuoteImg isStart={true} color={color} />
          {contents}
          {source && <span>{source}</span>}
          {/*<QuoteSvg isStart={false} color={color} />*/}
          <QuoteImg isStart={false} color={color} />
        </>
      )}
    </S.Container>
  );
};

export default QuoteBox;
