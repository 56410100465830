import * as S from "./dropdown.style";

function Dropdown({ items, isShow, setIsShow }) {
  const onClickDropdownItem = (ref) => {
    window.scroll(ref.current.offsetTop + 100);
  };
  return (
    <S.Container isShow={isShow}>
      {items.map((item) => {
        return (
          <S.Content
            key={`dropdown-${item.name}`}
            onClick={() => {
              // window.scrollTo({ top: item.ref.offsetTop, behavior: "smooth" });
              item.onClick();
            }}
          >
            {item.name}
          </S.Content>
        );
      })}
    </S.Container>
  );
}

export default Dropdown;
