import * as S from "./university.summary.style";
import { Mark } from "../../molecules/Mark/Mark";
import { useState } from "react";
import { universityIllustRefs } from "../../../pages/University/University";

function UniversitySummary({ sumarries, marks }) {
  const [on, setOn] = useState(true);
  return (
    <S.Wrapper
      ref={(el) => {
        universityIllustRefs.current[universityIllustRefs.current.length - 1] =
          el;
      }}
    >
      <S.HeaderContainer>
        <S.HeaderText>전남대 피해자 지도</S.HeaderText>
      </S.HeaderContainer>

      <S.Container>
        <S.OnOffButton onClick={() => setOn((prevState) => !prevState)}>
          지역&건물표시 {on ? "OFF" : "ON  "}
        </S.OnOffButton>
        <S.Asset hidden={!on} src={"/images/university/summary/marks.png"} />
        <S.TextContainer>
          {sumarries.map((summary, idx) => {
            return (
              <S.TextContent>
                <span>{summary}</span>
                <br />
              </S.TextContent>
            );
          })}
        </S.TextContainer>
        {marks.map((mark) => (
          <Mark mark={mark} key={`mark-${mark.top}-${mark.right}`} />
        ))}
      </S.Container>
    </S.Wrapper>
  );
}
export default UniversitySummary;
