import styled from "styled-components";

const Top = styled.div`
  margin-bottom: 0.5vw;
`;

const Bottom = styled.div`
  margin-top: 0.5vw;
`;

const TopImg = styled.img`
  width: 3vw;
  margin-bottom: 2vw;
`;
const BottomImg = styled.img`
  width: 3vw;
  margin-top: 2vw;
`;

export const QuoteImg = ({ isStart, color }) => {
  if (isStart) {
    if (color === "white") {
      return (
        <TopImg
          src={"/images/quote/white_start.png"}
          color={color}
          // style={"margin-bottom: "}
        />
      );
    } else {
      return <TopImg src={"/images/quote/black_start.png"} color={color} />;
    }
  } else {
    if (color === "white") {
      return <BottomImg src={"/images/quote/white_end.png"} color={color} />;
    } else {
      return <BottomImg src={"/images/quote/black_end.png"} color={color} />;
    }
  }
};

export const QuoteSvg = ({ isStart, color }) =>
  isStart ? (
    <Top>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="45px"
        height="35px"
      >
        <text
          kerning="auto"
          font-family="AppleGothic"
          fill="rgb(0, 0, 0)"
          transform="matrix( 48.5377473453189, 0, 0, 51.1126272817664,0.03636331452708, 66.0825522313189)"
          font-size="1.643px"
        >
          <tspan
            font-size="1.643px"
            font-family="Source Han Sans K"
            fill={color}
          >
            &#8220;
          </tspan>
        </text>
      </svg>
    </Top>
  ) : (
    <Bottom>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="45px"
        height="34px"
      >
        <text
          kerning="auto"
          font-family="AppleGothic"
          fill="rgb(0, 0, 0)"
          transform="matrix( 48.5377473453189, 0, 0, 51.1126272817664,0.03636331452708, 66.9881494815677)"
          font-size="1.643px"
        >
          <tspan
            font-size="1.643px"
            font-family="Source Han Sans K"
            fill={color}
          >
            &#8221;
          </tspan>
        </text>
      </svg>
    </Bottom>
  );
