import { useAtom } from "jotai";
import { usersAtom } from "../../stores/user";
import { useEffect } from "react";
import { getCookie } from "../../logics/auth";
import jwtDecode from "jwt-decode";

const LoginCheck = () => {
  const [user, setUser] = useAtom(usersAtom);
  useEffect(() => {
    let accessToken = getCookie("accessToken");
    if (!accessToken) {
      setUser({ isSignIn: false });
      const url = new URL(window.location.href);
      if (url.pathname !== "/login") {
        window.location.href = "/login";
      }
      return;
    }
    const decoded = jwtDecode(accessToken);
    const exp = decoded.exp;
    if (exp * 1000 < Date.now()) {
      // 만료
      setUser({ isSignIn: false });
      const url = new URL(window.location.href);
      if (url.pathname !== "/login") {
        window.location.href = "/login";
      }
    } else {
      // 유효한 토큰
      setUser({ isSignIn: true, id: user.id });
    }
  }, []);
};

export default LoginCheck;
