/**
 * Animation key frame 기본들
 * animation-name: hand_move4;
 * animationDuration: 3s;
 * animationDelay: 1s;
 * animationDirection: alternate;
 * animationIterationCount: infinite;
 * animationPlayState: running;
 * animationTimingFunction: cubic-bezier(0.88, 0.97, 0, 1.01);
 * animationFillMode : forwards;
 * */
import {
  prison_section_1_image_1_animation,
  prison_section_1_image_1_keyframe,
  prison_section_1_image_2_animation,
  prison_section_1_image_2_keyframe,
  prison_section_1_image_3_animation,
  prison_section_1_image_3_keyframe,
  prison_section_1_image_4_animation,
  prison_section_1_image_4_keyframe,
  prison_section_1_image_5_animation,
  prison_section_1_image_5_keyframe,
  prison_section_1_image_6_animation,
  prison_section_1_image_6_keyframe,
  prison_section_1_image_7_animation,
  prison_section_1_image_7_keyframe,
  prison_section_2_image_1_animation,
  prison_section_2_image_1_keyframe,
  prison_section_2_image_2_animation,
  prison_section_2_image_2_keyframe,
  prison_section_2_image_3_animation,
  prison_section_2_image_3_keyframe,
  prison_section_2_image_4_animation,
  prison_section_2_image_4_keyframe,
  prison_section_2_image_5_animation,
  prison_section_2_image_5_keyframe,
  prison_section_2_image_6_animation,
  prison_section_2_image_6_keyframe,
  prison_section_2_image_7_animation,
  prison_section_2_image_7_keyframe,
  prison_section_2_image_8_animation,
  prison_section_2_image_8_keyframe,
  prison_section_3_image_2_animation,
  prison_section_3_image_2_keyframe,
  prison_section_3_image_3_animation,
  prison_section_3_image_3_keyframe,
  prison_section_3_image_4_animation,
  prison_section_3_image_4_keyframe,
  prison_section_3_image_5_animation,
  prison_section_3_image_5_keyframe,
  prison_section_4_image_2_animation,
  prison_section_4_image_2_keyframe,
  prison_section_4_image_3_animation,
  prison_section_4_image_3_keyframe,
  prison_section_4_image_4_animation,
  prison_section_4_image_4_keyframe,
  prison_section_4_image_5_animation,
  prison_section_4_image_5_keyframe,
  section_1_image_1_animation,
  section_1_image_2_animation,
  section_1_image_2_keyframe,
  section_1_image_3_animation,
  section_1_image_3_keyframe,
  section_1_image_4_animation,
  section_1_image_4_keyframe,
  section_1_image_5_animation,
  section_1_image_5_keyframe,
  section_1_image_6_animation,
  section_1_image_6_keyframe,
  section_2_image_2_animation,
  section_2_image_2_keyframe,
  section_2_image_3_animation,
  section_2_image_3_keyframe,
  section_2_image_4_animation,
  section_2_image_4_keyframe,
  section_2_image_5_animation,
  section_2_image_5_keyframe,
  section_3_image_2_animation,
  section_3_image_2_keyframe,
  section_3_image_3_animation,
  section_3_image_3_keyframe,
  section_3_image_4_animation,
  section_3_image_4_keyframe,
  section_3_image_5_animation,
  section_3_image_5_keyframe,
  section_3_image_6_animation,
  section_3_image_6_keyframe,
  section_4_image_2_animation,
  section_4_image_2_keyframe,
  section_4_image_3_animation,
  section_4_image_3_keyframe,
  section_4_image_4_animation,
  section_4_image_4_keyframe,
  section_4_image_5_animation,
  section_4_image_5_keyframe,
  section_4_image_6_animation,
  section_4_image_6_keyframe,
  section_5_image_1_animation,
  section_5_image_1_keyframe,
  section_5_image_2_animation,
  section_5_image_2_keyframe,
  section_5_image_3_animation,
  section_5_image_3_keyframe,
  section_5_image_4_animation,
  section_5_image_4_keyframe,
  section_5_image_5_animation,
  section_5_image_5_keyframe,
  section_5_image_6_animation,
  section_5_image_6_keyframe,
  section_6_1_image_1_animation,
  section_6_1_image_1_keyframe,
  section_6_1_image_2_animation,
  section_6_1_image_2_keyframe,
  section_6_1_image_3_animation,
  section_6_1_image_3_keyframe,
  section_6_1_image_4_animation,
  section_6_1_image_4_keyframe,
  section_6_1_image_5_animation,
  section_6_1_image_5_keyframe,
  section_6_1_image_6_animation,
  section_6_1_image_6_keyframe,
  section_6_2_image_1_animation,
  section_6_2_image_1_keyframe,
  section_6_2_image_2_animation,
  section_6_2_image_2_keyframe,
  section_6_2_image_3_animation,
  section_6_2_image_3_keyframe,
  section_6_2_image_4_animation,
  section_6_2_image_4_keyframe,
  section_6_2_image_5_animation,
  section_6_2_image_5_keyframe,
  section_6_2_image_6_animation,
  section_6_2_image_6_keyframe,
  section_7_image_1_animation,
  section_7_image_1_keyframe,
  section_7_image_2_animation,
  section_7_image_2_keyframe,
  section_7_image_3_animation,
  section_7_image_3_keyframe,
  section_7_image_4_animation,
  section_7_image_4_keyframe,
  section_7_image_5_animation,
  section_7_image_5_keyframe,
  section_7_image_6_animation,
  section_7_image_6_keyframe,
  section_7_image_7_animation,
  section_7_image_7_keyframe,
  university_section_1_image_1_animation,
  university_section_1_image_2_animation,
  university_section_1_image_2_keyframe,
  university_section_1_image_3_animation,
  university_section_1_image_3_keyframe,
  university_section_1_image_4_animation,
  university_section_1_image_4_keyframe,
  university_section_1_image_5_animation,
  university_section_1_image_5_keyframe,
  university_section_1_image_6_animation,
  university_section_1_image_6_keyframe,
  university_section_1_image_7_animation,
  university_section_1_image_7_keyframe,
  university_section_2_image_2_animation,
  university_section_2_image_2_keyframe,
  university_section_2_image_3_animation,
  university_section_2_image_3_keyframe,
  university_section_2_image_4_animation,
  university_section_2_image_4_keyframe,
  university_section_3_image_2_animation,
  university_section_3_image_2_keyframe,
  university_section_3_image_3_animation,
  university_section_3_image_3_keyframe,
  university_section_3_image_4_animation,
  university_section_3_image_4_keyframe,
  university_section_4_image_2_animation,
  university_section_4_image_2_keyframe,
  university_section_4_image_3_animation,
  university_section_4_image_3_keyframe,
  university_section_4_image_4_animation,
  university_section_4_image_4_keyframe,
  university_section_4_image_5_animation,
  university_section_4_image_5_keyframe,
  university_section_4_image_6_animation,
  university_section_4_image_6_keyframe,
  university_section_4_image_7_animation,
  university_section_4_image_7_keyframe,
  university_section_4_image_8_animation,
  university_section_4_image_8_keyframe,
  university_section_4_image_9_animation,
  university_section_4_image_9_keyframe,
  university_section_5_image_2_animation,
  university_section_5_image_2_keyframe,
  university_section_5_image_3_animation,
  university_section_5_image_3_keyframe,
  university_section_5_image_4_animation,
  university_section_5_image_4_keyframe,
  university_section_5_image_5_animation,
  university_section_5_image_5_keyframe,
  university_section_5_image_6_animation,
  university_section_5_image_6_keyframe,
} from "./keyframes";

const left = "25%";
const middle = "50%";
const right = "70%";
const top = "10%";
const bottom = "50%";

const interviewBaseURL =
  "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/interview/";
export const stationIllustrations = [
  {
    illustCnt: 1,
    index: {
      number: 1,
      color: "#0d347f",
    },
    title: "시위대 차량시위와 계엄군의 대응",
    illust: [
      {
        quotation: {
          position: { horizontal: middle, vertical: top },
          contents:
            "시위대의 차량이 \n" +
            "광주역 분수대로 돌진해 \n" +
            "경계석을 넘어 멈췄어요",
          source: "- 12대대 중사 이창원 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "운전수를 붙잡아 \n" + "진압봉 등으로 때려서 \n" + "죽었을 것입니다",
        source: "- 12대대 중사 이창원 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "곽석기",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section1/" +
            "kwaksukgi.mp4",
        },
        {
          name: "김용하",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section1/" +
            "kimyoungha.mp4",
        },
      ],
      sentences: [
        {
          source: "11대대 중위 양성택",
          contents:
            "광주역에서 폭도들의 차량에 타이어를 사격하도록 하였으나, 계속 밀려와 마지못해 운전수를 향해 자위권 발동으로 사격한 것으로 알고 있음.",
        },
        {
          source: "11대대 대위 천말수",
          contents:
            "광주역에서 시위대가 타고 오는 차량의 바퀴를 조준사격 하라는 지시가 있어 버스 바퀴를 사격함.",
        },
        {
          source: "11대대 하사 정영태",
          contents: "대대장이 시위대는 하반신, 차량은 바퀴를 쏘라고 지시함.",
        },
        {
          source: "12대대 중사 신동면",
          contents:
            "광주역으로 돌진하던 버스 기사를 붙잡아 동료들이 보도블럭 등으로 머리를 찍어 사망케 함. 진압 시 군홧발로 뒤통수를 찍어 2~3명이 사망함.",
        },
        {
          source: "12대대 중사 윤행근",
          contents:
            "광주역에서 금남로 방면(좌측) 도로상에서 진압봉에 맞아 쓰러진 사람을 동료인 정광준 중사가 돌로 머리를 내리쳐 ‘퍽’ 소리가 크게 났고, 현장에서 사망함.",
        },
        {
          source: "12대대 하사 이승수",
          contents:
            "1톤 트럭이 분수대 방향으로 돌진하여 군인들을 덮치고 도망가는 운전수를 붙잡아 동료들의 다친 모습을 보고 격분한 정광준 중사가 보도블럭으로 운전수(빨간색 티셔츠 착용)의 머리를 내리쳐 죽였음. \n" +
            "* 이 사고로 같은 지역대 장원복, 호근철, 천광열 중사 등 4명이 상이용사가 되었음.",
        },
        {
          source: "12대대 중사 이창원",
          contents:
            "광주역 분수대로 돌진한 차량이 경계석을 넘어 멈추자 운전수를 붙잡아 진압봉 등으로 때려서 죽었을 것임.",
        },
        {
          source: "12대대 하사 조영국",
          contents:
            "시위대 차량 10여 대가 접근하여 12, 13대대 인원들이 집중 사격. 다수의 시위대가 죽었을 것임.",
        },
        {
          source: "12대대 하사 송기용",
          contents: "첫날 24:00경 시위대 차량 8~12대의 사격으로 응사함. ",
        },
        {
          source: "12대대 화기 중사 이창원",
          contents:
            "광주역 분수대에 버스가 돌진하였고 어떤 차량은 분수대 경계석을 넘어 멈췄는데 여기에 운전수가 있었고 이 운전수를 붙잡아서 진압봉 등으로 때렸는데 아마 죽었을 것임.",
        },
        {
          source: "13대대 하사 곽석기",
          contents:
            "광주역 방향에서 기관총과 소총 연발 소리(기관총 및 소총소기 구분할 수 있음)가 많이 들렸고, 분수대를 향해 돌진하는 시위대 차량에 사격하는 것을 목격함.",
        },
        {
          source: " ",
          contents: " ",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section1/1.png",
          animation: section_1_image_1_animation,
          keyframe: section_1_image_1_animation,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section1/2.png",
          animation: section_1_image_2_animation,
          keyframe: section_1_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section1/3.png",
          animation: section_1_image_3_animation,
          keyframe: section_1_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section1/4.png",
          animation: section_1_image_4_animation,
          keyframe: section_1_image_4_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 2, color: "#0d347f" },
    title: "전남대(지휘본부)에서 광주역으로 실탄 지원 및 보급",
    illust: [
      {
        quotation: {
          position: { horizontal: middle, vertical: top },
          contents:
            "광주역에서 \n" + "시위대에 의해서 \n" + "고립됐을 때였어요",
          source: "- 여단 본부 경리계 중사 조병호 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "작전참모와 \n" +
          "경비소대 경호 인원들이 \n" +
          "실탄 추진 보급을 \n" +
          "나갔어요",
        source: "- 여단 본부 경리계 중사 조병호 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "박성현",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section2/" +
            "parkseunghyun.mp4",
        },
        {
          name: "최명용",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section2/" +
            "choimyungyoung.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 인사계 상사 김석송",
          contents:
            "전남대 이학부 1층 강의실에 보관된 탄약을 지키기 위해 병사 2명과 함께 숙영했음.",
        },
        {
          source: "여단 본부 경리계 중사 조병호",
          contents:
            "광주역에서 고립됐을 때 작전참모와 경비소대 경호 인원들이 실탄 추진 보급을 나감.",
        },
        {
          source: "본부대 병사 안홍식",
          contents:
            "야간에 광주역에서 부대원들이 고립되어 작전참모 인솔하에 실탄을 보급하러 분수대까지 나간 적이 있음.",
        },
        {
          source: "11대대 중위 양성택",
          contents:
            "광주역에서 실탄은 5분대기조에게만 실탄 박스를 지급한 것으로 알고 있음.",
        },
        {
          source: "11대대 하사 정영태",
          contents: "광주역에서 실탄을 받았음.",
        },
        {
          source: "11대대 중사 최정식",
          contents:
            "광주역에서 중대장만 실탄을 휴대하고, 팀원들은 휴대하지 않았음.",
        },
        {
          source: "11대대 하사 오인수",
          contents:
            "광주역에서 헌병(헌병으로 추정된 이유는 군복을 입었으나 계급장과 이름표가 없었음)이 실탄 (20발 1탄창)을 줘서 받았음.",
        },
        {
          source: "15대대 하사 강민구",
          contents: "광주역에서 중대장들은 실탄 10발씩 휴대하고 있었음.",
        },
        {
          source: "15대대 중위 박은배",
          contents:
            "광주역에서 중대장은 12발이 들어있는 탄창을 휴대하고 있었고, 부중대장 및 팀원은 받지 않았음.",
        },
        {
          source: "16대대 중사 한장희",
          contents:
            "중대장들은 처음부터 실탄 12발을 휴대하고 있었고, 팀원들은 교도소에 와서 실탄 20발 1탄창을 받았음.",
        },
        {
          source: "16대대 작전장교 대위 권명신",
          contents:
            "야간에 11대대 보급관이 다 죽게 생겼다고 해서 탄약을 추진한다며 차량에 탄약을 적재하여 전남대 앞 사거리로 갔는데 위험해서 갈 수 없다고 했으나 완강히 가야 한다고 하여 조심히 가라고 했던 기억이 있음.",
        },
        {
          source: " ",
          contents: " ",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section2/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section2/2.png",
          animation: section_2_image_2_animation,
          keyframe: section_2_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section2/3.png",
          animation: section_2_image_3_animation,
          keyframe: section_2_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section2/4.png",
          animation: section_2_image_4_animation,
          keyframe: section_2_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section2/5.png",
          animation: section_2_image_5_animation,
          keyframe: section_2_image_5_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 3, color: "#0d347f" },
    title: "신안동 사거리 계엄군 사망사건",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: top },
          contents:
            "23:00경 광주역 방향에서 \n" +
            "시민군 트럭 1대가 고속으로 돌진하여 \n" +
            "부대원들이 피했으나...",
          source: "- 16대대 작전장교 대위 권명신 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "대대장 짚차 운전관 \n" +
          "정관철 중사는 피하지 못하고 \n" +
          "육교 건너편 개울가로 \n" +
          "떨어져 사망했습니다",
        source: "- 16대대 작전장교 대위 권명신 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "권명신_현장증언",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section3/" +
            "kwonmyeongsin.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 정보보좌관 대위 최명용",
          contents: "16대대 정관철 중사가 죽었을 때도 사격하여 많이 죽었음.",
        },
        {
          source: "11대대 대위 김철규",
          contents: "야간에 정관철 중사가 사망 후, 사격 소리가 들렸음. ",
        },
        {
          source: "12대대 중사 김귀삼",
          contents:
            "동료가 죽인 버스 운전수를 붙잡아 보도블럭으로 찍어 사망케 함.",
        },
        {
          source: "16대대 작전장교 대위 권명신",
          contents:
            "23:00경 전남대 방향에서 트럭 1대가 고속으로 돌진하여 부대원들이 피했으나 대대장 짚차 운전관 정관철 중사는 피하지 못하고 육교 건너편 개울가로 떨어져 사망함.",
        },
        {
          source: "16대대 화기 중사 강사현",
          contents:
            "정중사를 덮친 차량 운전석에는 2명이 탑승해 있었음. 트럭이 돌진할 때 운전수가 핸들을 급하게 틀어 지역대 병력을 치지 않고 정관철 중사를 덮치고, 트럭이 쇄석장을 들이받고 멈췄음. 그러나 정관철 중사는 허리 이하 하체가 잘려 나갔음. 정관철 중사를 덮친 운전수는 전남대 2층에 수감하고 교도소까지 이송해 갔음.",
        },
        {
          source: " ",
          contents: " ",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section3/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section3/2.png",
          animation: section_3_image_2_animation,
          keyframe: section_3_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section3/3.png",
          animation: section_3_image_3_animation,
          keyframe: section_3_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section3/4.png",
          animation: section_3_image_4_animation,
          keyframe: section_3_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section3/5.png",
          animation: section_3_image_5_animation,
          keyframe: section_3_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section3/6.png",
          animation: section_3_image_6_animation,
          keyframe: section_3_image_6_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 4, color: "#0d347f" },
    title: "지휘관의 권총발사와 발포명령",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: top },
          contents:
            "광주역을 중심으로 \n" +
            "시위대의 차량공격이 격렬해지자\n" +
            "전남대에서 임시 지휘소를 운영하고 있던\n" +
            "여단장이 움직였죠.",
          source: "- 여단 본부 정보보좌관 대위 최명용 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "광주역 발포와 관련해서 \n" +
          "계엄군의 증언은 두 가지로 나뉘어 확인된다.\n" +
          "최명용, 김종원은 최세창 여단장 \n" +
          "권총 발사를 목격하였고, \n" +
          "그 외 대부분은 \n" +
          "대대장의 발포로 증언하고 있다.",
        // source: "",
      },
      videos: [
        {
          name: "김종원",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section4/" +
            "kimjongwon.mp4",
        },
        {
          name: "김종원_3D",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section4/" +
            "kimjongwon_3d.mp4",
        },
        {
          name: "최명용",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section4/" +
            "choimyeongyoung.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 정보보좌관 대위 최명용",
          contents:
            "광주역 건물 내에 최세창 여단장이 임시 지휘소를 운영하여 현장에 있었고 여단장이 광주역에서 자신의 권총을 공중으로 3발 발사한 것을 목격함.",
        },
        {
          source: "15대대 하사 허조",
          contents:
            "광주역에서 16대대 정관철 중사 사망으로 대대장이 권총을 발사함.",
        },
        {
          source: "15대대 하사 김성규",
          contents: "광주역에서 여단장이 왔고, 중대장으로부터 발포지시를 받음.",
        },
        {
          source: "15대대 하사 김종원",
          contents:
            "광주역 분수대에서 21:00경 여단장이 권총으로 실탄 3발을 공중으로 발사하는 장면을 목격, 중대장이 지금부터 사격해도 된다고 하여 사격함.",
        },
        {
          source: " ",
          contents: " ",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section4/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section4/2.png",
          animation: section_4_image_2_animation,
          keyframe: section_4_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section4/3.png",
          animation: section_4_image_3_animation,
          keyframe: section_4_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section4/4.png",
          animation: section_4_image_4_animation,
          keyframe: section_4_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section4/5.png",
          animation: section_4_image_5_animation,
          keyframe: section_4_image_5_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 5, color: "#0d347f" },
    title: "기관총 설치와 운용",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: top },
          contents:
            "광주역을 향해 \n" +
            "시위대 차량의 공격이\n" +
            "점차 가해지고 있는 상황이었어요",
          source: "- 정보 보좌관 대위 최명용 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "M60 기관총을 설치하여\n" +
          "돌진하는 시위대 차량을 향해\n" +
          "사격하는 것을 목격했어요",
        source: "- 11대대 하사 홍종윤 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "곽석기",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section5/" +
            "kwaksukki.mp4",
        },
        {
          name: "김귀삼",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section5/" +
            "kimgwisam.mp4",
        },
      ],
      sentences: [
        {
          source: "11대대 하사 홍종윤",
          contents:
            "광주역에서 건물 옥상에 M60기관총을 설치하여 돌진하는 시위대 차량을 향해 사격하는 것을 목격함.",
        },
        {
          source: "12대대 중사 김귀삼",
          contents:
            "광주 신역 빌딩에 M60기관총을 설치하여 위협사격 및 분수대 오거리 우측 건물 옥상에 M60기관총 예광탄으로 위협사격 함.",
        },
        {
          source: "12대대 하사 김승식",
          contents:
            "시청에서 방송국이 불타고 있을 때 M16, M60기관총 소리가 들렸음.",
        },
        {
          source: "13대대 하사 곽석기",
          contents:
            "광주역 방향에서 기관총과 소총 연발 소리(기관총 및 소총 소리 구분할 수 있음)\n" +
            "많이 들렸고, 분수대를 향해 돌진하는 시위대 차량에 사격하는 것을 목격함.",
        },
        {
          source: " ",
          contents: " ",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section5/1.png",
          animation: section_5_image_1_animation,
          keyframe: section_5_image_1_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section5/2.png",
          animation: section_5_image_2_animation,
          keyframe: section_5_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section5/3.png",
          animation: section_5_image_3_animation,
          keyframe: section_5_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section5/4.png",
          animation: section_5_image_4_animation,
          keyframe: section_5_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section5/5.png",
          animation: section_5_image_5_animation,
          keyframe: section_5_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section5/6.png",
          animation: section_5_image_6_animation,
          keyframe: section_5_image_6_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 2,
    index: { number: 6, color: "#0d347f" },
    title: "집단발포와 발포에 의한 현장피해자",
    illust: [
      {
        quotation: {
          position: { horizontal: middle, vertical: bottom },
          contents: "그러면\n발포 승인한 것으로 알겠다.",
          exception: "station-section-6",
          // source: "강OO 인터뷰 내용 중에서",
        },
      },
      {
        quotation: {
          position: { horizontal: left, vertical: top },
          contents:
            "시청에서\n광주역으로 이동중\n중대장이 전봇대에서 서서 시민군을 향해\n조준 사격하는 장면을 목격했어요",
          source: "- 12대대 하사 김승식 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "광주역 분수대 근처에서\n" + "정렬된 시신 10여 구를\n" + "목격했어요",
        source: "- 13대대 하사 곽석기 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "김귀삼",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section6/" +
            "kimgwisam.mp4",
        },
        {
          name: "윤행근",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section6/" +
            "yoonhanggeun.mp4",
        },
        {
          name: "이양교",
          src:
            interviewBaseURL +
            "video/" +
            "station/" +
            "section6/" +
            "leeyangyo.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 통신대 중사 김OO",
          contents:
            "여단장이 유선으로 전교사령관에게 발포 건의를 했으나, 승인 받지 못하고, 정호영 특전사령관과 통화에서 “그러면 발포 승인한 것으로 알겠다.”라고 통화하였음. ",
        },
        {
          source: "12대대 중사 신동면",
          contents: "광주역 분수대에서 1명이 죽어있는 것을 목격하였음.",
        },
        {
          source: "12대대 하사 김승식",
          contents:
            "광주 시내에서 7공수 소속의 지역대장이었던 정호열이 전봇대에 서서 시민군을 향해 조준 사격하는 장면을 목격했음.",
        },
        {
          source: "13대대 하사 곽석기",
          contents: "광주역 분수대 근처에서 정렬된 시신 10여 구 목격함.",
        },
        {
          source: "13대대 작전 하사 김완영",
          contents: "5월 20일 야간 광주역에서 발포했었음.",
        },
        {
          source: "15대대 하사 허조",
          contents:
            "광주역에서 16대대 정관철 중사 사망으로 대대장이 권총을 발사함.",
        },
        {
          source: "15대대 하사 김성규",
          contents: "광주역에서 희생자 1명을 목격하였음.",
        },
        {
          source: " ",
          contents: " ",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/1-1.png",
          animation: section_6_1_image_1_animation,
          keyframe: section_6_1_image_1_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/1-2.png",
          animation: section_6_1_image_2_animation,
          keyframe: section_6_1_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/1-3.png",
          animation: section_6_1_image_3_animation,
          keyframe: section_6_1_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/1-4.png",
          animation: section_6_1_image_4_animation,
          keyframe: section_6_1_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/1-5.png",
          animation: section_6_1_image_5_animation,
          keyframe: section_6_1_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/1-6.png",
          animation: section_6_1_image_6_animation,
          keyframe: section_6_1_image_6_keyframe,
        },
      ],
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/2-1.png",
          animation: section_6_2_image_1_animation,
          keyframe: section_6_2_image_1_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/2-2.png",
          animation: section_6_2_image_2_animation,
          keyframe: section_6_2_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/2-3.png",
          animation: section_6_2_image_3_animation,
          keyframe: section_6_2_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/2-4.png",
          animation: section_6_2_image_4_animation,
          keyframe: section_6_2_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/2-5.png",
          animation: section_6_2_image_5_animation,
          keyframe: section_6_2_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section6/2-6.png",
          animation: section_6_2_image_6_animation,
          keyframe: section_6_2_image_6_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 7, color: "#0d347f" },
    title: "전남대 주둔지 복귀",
    illust: [
      {
        quotation: {
          position: { horizontal: right, vertical: top },
          contents:
            "집단 발포 이후 시위대가 흩어지고\n" +
            "주둔지 복귀를 위한\n" +
            "퇴로를 확보하기 시작했어요",
          source: "- 15대대 본부 하사 허조 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "광주역에서 \n" + "다음 날 새벽 3~4시경\n" + "전남대로 복귀했어요",
        source: "- 15대대 본부 하사 허조 인터뷰 내용 중에서 -",
      },
      videos: [],
      sentences: [
        {
          source: "11대대 하사 오인수",
          contents: "광주역에서 날이 밝았을 때 전남대로 복귀하였음.",
        },
        {
          source: "12대대 본부 중사 김귀삼",
          contents:
            "광주 신역 광장에서 폭도를 잡아 오면 1차 집합소(진압 현장 뒤쪽)에서 관리했고, 정보처 주관 여단 참모부 간부들이 전남대로 데려가 심문했음.",
        },
        {
          source: "12대대 화기 하사 송기용",
          contents:
            "02:00경 광주 신역에서 전남대로 철수할 때 KBS방송국이 불타고 있는 것을 목격함.",
        },
        {
          source: "12대대 중사 윤행근",
          contents:
            "다음 날 새벽 광주역에서 분수대를 바라봤을 때 분수대 우측에 죽어있는 시위대 시신 1구를 2½톤(덮개 설치)에 싣는 것을 목격하고 진압 종료 후 쓰러져 있는 시위대를 전남대로 데려갔음.",
        },
        {
          source: "15대대 본부 하사 허조",
          contents: "광주역에서 다음 날 새벽 3~4시경 전남대로 복귀하였음.",
        },
        {
          source: " ",
          contents: " ",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section7/1.png",
          animation: section_7_image_1_animation,
          keyframe: section_7_image_1_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section7/2.png",
          animation: section_7_image_2_animation,
          keyframe: section_7_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section7/3.png",
          animation: section_7_image_3_animation,
          keyframe: section_7_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section7/4.png",
          animation: section_7_image_4_animation,
          keyframe: section_7_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section7/5.png",
          animation: section_7_image_5_animation,
          keyframe: section_7_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section7/6.png",
          animation: section_7_image_6_animation,
          keyframe: section_7_image_6_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/station/illustration/section7/7.png",
          animation: section_7_image_7_animation,
          keyframe: section_7_image_7_keyframe,
        },
      ],
    ],
  },
];
export const universityIllustrations = [
  {
    illustCnt: 1,
    index: {
      number: 1,
      color: "#0d347f",
    },
    title: "시위대 상황과 계엄군의 대응",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: bottom },
          contents:
            "장갑차 구멍에 \n" +
            "최루탄을 투척하여 중학생 등\n" +
            "6~7명이 기어 나온 것을\n" +
            "진압봉으로 구타 했었어요",
          source: "- 15대대 하사 허조 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "시위대가 정문을 밀고 들어와 \n" +
          "밀쳐내는 과정에서 \n" +
          "5~6명이 곤봉에 맞아\n" +
          "피를 흘리며 널브러져 있는 모습을 \n" +
          "목격했습니다.\n",
        source: "- 12대대 하사 최재복 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "최용범",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section1/" +
            "choiyoungbeom.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 대위 양규용",
          contents:
            "전남대에 많은 시위대 인원들이 있었고, 시위대의 분위기도 살벌했으며, 500MD 헬기에서 가스를 살포하여 힘들었음.",
        },

        // {
        //   source: "여단 본부 중사 김OO",
        //   contents:
        //     "전남대 앞에서 장갑차가 하천 도랑에 빠져서 잡아보니 고등학생이었고, 이들은 장갑차를 후진시키지도 못했음.",
        // },

        {
          source: "12대대 하사 최재복",
          contents:
            "시위대가 정문을 밀고 들어와 밀쳐내는 과정에서 5~6명이 곤봉에 맞아 피를 흘리며 널브러져 있는 모습을 목격했음.",
        },

        {
          source: "12대대 하사 하경호",
          contents:
            "검거 작전 시 민가에 들어가 가택수색도 하고, 주로 도로상에서 10여 명을 검거하였음.",
        },

        {
          source: "13대대 하사 천정욱",
          contents:
            "시위대가 정문 안쪽으로 들어왔을 때, 13대대장이 “대대 돌격 앞으로”(진압봉으로 진압)하여 시위대를 정문 밖으로 밀쳐낼 때 전남대 정문 안쪽 배수로 안에 쓰러진 시위대 몇 명이 놓여진 것을 보았음. 정문을 지나 육교 위에서 진압할 때 총소리가 났으며 위협사격인 듯했음.",
        },

        {
          source: "13대대 하사 김완영",
          contents:
            "전남대에서 시위대와 대치할 때 배수로 주변에 다친 사람과 포박한 사람들을 모아놓았었음.",
        },

        {
          source: "13대대 중사 나경순",
          contents:
            "전남대로 시위대가 APC로 여러 차례 밀고 들어와 공포스러운 분위기였음.",
        },

        {
          source: "15대대 하사 허조",
          contents:
            "전남대 앞에서 시동이 꺼져 있는 APC(타이어)를 빼앗았고, 장갑차 구멍에 최루탄을 투척하여 중학생 등 6~7명이 기어 나온 것을 진압봉으로 구타했었음.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section1/1.png",
          animation: university_section_1_image_1_animation,
          keyframe: university_section_1_image_1_animation,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section1/2.png",
          animation: university_section_1_image_2_animation,
          keyframe: university_section_1_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section1/3.png",
          animation: university_section_1_image_3_animation,
          keyframe: university_section_1_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section1/4.png",
          animation: university_section_1_image_4_animation,
          keyframe: university_section_1_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section1/5.png",
          animation: university_section_1_image_5_animation,
          keyframe: university_section_1_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section1/6.png",
          animation: university_section_1_image_6_animation,
          keyframe: university_section_1_image_6_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section1/7.png",
          animation: university_section_1_image_7_animation,
          keyframe: university_section_1_image_7_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 2, color: "#0d347f" },
    title: "전남대 정,후문 집단발포 상황",
    illust: [
      {
        quotation: {
          position: { horizontal: middle, vertical: bottom },
          contents:
            "전남대에 도착하자마자 중대장급 이상에게\n" +
            "실탄을 지급했고, 사병에게는\n" +
            "전남대에서 교도소로 이동할 때 지급했습니다",
          source: "- 여단 본부 정보보좌관 최명용 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents: "헬기에서 길이가 더 긴 \n" + "진압봉을 투하해 줬어요 \n",
        source: "- 여단 본부 통신병 방온식 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "박성현",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section2/" +
            "parksunghyun.mp4",
        },
        {
          name: "박성현_현장증언",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section2/" +
            "parksunghyun_site.mp4",
        },
        {
          name: "윤춘기_3D",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section2/" +
            "yoonchunki.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 정보보좌관 대위 최명용",
          contents:
            "당시 지휘부는 ‘무조건 진압해야 한다’는 생각을 했고, 사격 승인에 대해 책임지기 싫어했으며, 실탄을 지급해 놓고 ‘위급할 때 사용해라’며 암묵적으로 사격을 묵인한 것이고, 전남대에 도착하자마자 중대장급 이상에게 실탄을 지급했음. 사병에게는 전남대에서 교도소로 이동할 때 지급하였음.",
        },
        {
          source: "여단 본부대장 소령 김승부",
          contents:
            "광주역(5. 20.)이나 전남대(5. 21.)에서 시민군이 총을 들고 사격을 하는 등 생명에 위협을 느껴 병사들에게 실탄을 지급한 것으로 알고 있음.",
        },
        {
          source: "여단 본부 대위 양규용",
          contents:
            "전남대에 많은 시위대 인원들이 있었고, 시위대의 분위기도 살벌했으며, 500MD 헬기에서 가스를 살포하여 힘들었음.",
        },
        {
          source: "여단 본부 통신병 방온식",
          contents:
            "2일 차 오후에 UH-1H에서 질식가스를 살포(추정)했는지 본인과 주변 인원(계엄군, 시위대)들이 잠깐 정신을 잃고 스러졌었고 이후 헬기에서 길이가 더 긴 진압봉을 투하해 줬음.",
        },
        {
          source: "12대대 하사 김승식",
          contents:
            "전남대 정문 진압 작전 중, 좌측방향(10시~11시 방향) 상공에 UH-1H 1대가 M60 기관총을 장착하여 도청 방향으로 난사하고 있는 것을 직접 목격(출입문에 M60 1정 장착, 반대쪽은 못 봄) 대부분 대대원들은 목격하였음.",
        },
        {
          source: "12대대 하사 조영국",
          contents:
            "낮에 동기생 3명과 함께 시위 진압 시 시위대들이 쇠파이프, 병, 칼, 등을 던졌고, 어느 골목에서 교련복을 착용한 고등학생이 두부 측면에 관통상을 입고 땅바닥에 하얀 골수가 약 1미터 정도 퍼져 있었고 피가 흥건한 상태로 죽어있었음.",
        },
        {
          source: "13대대 하사 천정욱",
          contents:
            "정문을 지나 육교 위에서 진압할 때 총소리가 났으며 위협사격인 듯했음.",
        },
        {
          source: "13대대 중사 김진수",
          contents:
            "정문에서 진압 작전 시 발포하여 2명이 희생된 것을 목격했고, 이 시신을 어떻게 처리했는지는 모름. 2일차 상무대에서 사격명령이 안 내려왔으니 대기하라고 하여 대기하다가 점심때 사격명령이 떨어져서 조준사격 했음.",
        },
        {
          source: "15대대 하사 신승모",
          contents: "당시 실탄은 전남대에서 경계근무 서면서 20발을 지급받았음.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section2/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section2/2.png",
          animation: university_section_2_image_2_animation,
          keyframe: university_section_2_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section2/3.png",
          animation: university_section_2_image_3_animation,
          keyframe: university_section_2_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section2/4.png",
          animation: university_section_2_image_4_animation,
          keyframe: university_section_2_image_4_keyframe,
        },
      ],
    ],
  },
  //  3번이 갑자기 추가됨
  {
    illustCnt: 1,
    index: { number: 3, color: "#CE813D" },
    title: "사망자 가(암)매장",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: top },
          contents:
            "그때는 전부다 교련복을 입었고,\n" +
            "본부장이 총을 딱 꺼내\n" +
            "제일 앞에 오는 놈을 딱 쐈어요.\n" +
            "나는 옆에 있었고, 한 10M까지 왔어.\n" +
            "바로 즉사했잖아.\n" +
            "그러니까 다 멈추데",
          source: "- 여단 본부 중사 윤춘기 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "학교 본부 옆 야산에 3구,\n" +
          "이학부 뒤 야산에 4구를\n" +
          "1.5~2미터 이내로 굴토한 후\n" +
          "입은 옷 그대로 매장했습니다\n",
        source: "- 여단 본부 정보보좌관 최명용 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "류구",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section3/" +
            "ryukoo.mp4",
        },
        {
          name: "유종경",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section3/" +
            "youjongkyung.mp4",
        },
        {
          name: "최명용",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section3/" +
            "choimyeongyoung.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 정보보좌관 대위 최명용",
          contents:
            "당시 시위 현장에서 사망한 시신을 여단 상황실 건물 옆(학교 본부)에 모아 둔 12구(총상과 구타로 인해 17살 인원을 포함)가 있었음. 여단장 지시로 5. 20. 20:00~21:00 어간 본부대 인원과 12대대 하사관들과 함께 들 것을 이용하여 학교본부 옆 야산(산이 시작되는 부근)에 3구, 이학부 뒤 야산(경사가 완만)에 4구를 1.5~2미터 이내로 굴토한 후 입은 옷 그대로 매장했음.",
        },

        {
          source: "여단 본부 상사 김석송",
          contents:
            "야간 취침 시간 전쯤 조병호 중사가 인원을 요청하여 따라갔더니 ‘오늘 산에 처리해야 할 일이 있다. 건물 뒤편에서 기다리라’고 하였고, 잠시 후 두 명(조명호 중사, 지원병)이 천으로 감싼 시신 1구를 어깨에 둘러메고 왔음. \n\n 매장 장소는 건물 뒤 야산 오르막 경사면이 끝나는 지점에 소나무가 많았고 평평한 장소에 배수로처럼 구덩이가 무릎 정도 높이로 길게 파인 곳에 시신을 매장 시 흙이 부족하여 옆에 있는 나뭇잎이 섞인 흙을 끌어모아 덮었음. (현장에서 직접 목격함)",
        },

        {
          source: "여단 본부 중사 김OO",
          contents:
            "주간에 경비소대 강일석 상사와 이상덕 중사가 시신 1구는 발을 하나씩 잡고 끌고 갔고, 1구는 어깨에 둘러메고 가는 등 시신 2~3구를 건물 뒤 야산 나무숲까지 올라가지 않고 마사 재질의 흙에 매장하는 것을 목격했음. (현장 방문하여 지목함)",
        },

        {
          source: "여단 본부 중사 윤춘기",
          contents:
            "현장에서 즉사한 사망자는 상하의 모두 교련복을 착용한 고등학생(머리카락이 짧았음)으로 보였고, 본부대장 및 본부대 인적불상 하사들이 건물 뒤 소나무가 많은 야산에 매장했음. (구덩이 파는 것만 목격함)",
        },

        {
          source: "여단 본부 하사 박제서",
          contents:
            "의무실에서 군의관을 도와 진압봉에 맞아 손이 짓이겨지고 피가 흥건한 부상자 등을 시위대들을 치료하였고, 2~3명 정도의 죽은 사람은 대대 인원들이 데려갔는데 어떻게 했는지 모름. 치료하다가 죽은 시위대가 4~5명 정도, 이미 죽은 상태로 의무대로 온 인원이 4~5명 정도였던 것으로 기억되고, 당시 군의관은 2~3명, 본인, 의무병 1~2명이 있었으며, 이름이나 고향 등 신상에 대한 기억은 안 남.",
        },

        {
          source: "여단 본부 병장 최용범",
          contents:
            "죽은 사람은 머리를 빡빡 삭발한 고등학생 2명만 보았고, 머리 등을 맞아 부상 당한 사람은 많이 보았음.",
        },

        {
          source: "12대대 폭파 하사 김승식",
          contents:
            "강당에서 빨간색 츄리닝을 착용한 13살 정도의 사람이 갑자기 보이지 않아 주변 동료에게 물어보니 죽었다고 했음. (지나가는 군인들이 한 대씩 때렸기 때문에 죽지 않았나 생각했음)",
        },

        {
          source: "12대대 하사 최여광",
          contents:
            "당시 시신을 돌로 묶어 ‘용지’에 수장했다는 것과 정문 야산에 매장했다는 얘기가 있었음.",
        },

        {
          source: "13대대 의무 중사 김진수",
          contents:
            "정문에서 진압작전시 발포하여 2명이 희생된 것을 목격했고, 이 시신을 어떻게 처리했는지는 모름.",
        },

        {
          source: "16대대 대위 권명신",
          contents:
            "2일 차 16대대가 후문 방어 시 정문 안쪽에 50여 명이 시체처럼 쓰러져 있었고, 일부는 사망했을 것임. \n\n후문에서 대학생 머리를 구타하여 사망하였다고 들었음.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section5/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section5/2.png",
          animation: university_section_5_image_2_animation,
          keyframe: university_section_5_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section5/3.png",
          animation: university_section_5_image_3_animation,
          keyframe: university_section_5_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section5/4.png",
          animation: university_section_5_image_4_animation,
          keyframe: university_section_5_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section5/5.png",
          animation: university_section_5_image_5_animation,
          keyframe: university_section_5_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section5/6.png",
          animation: university_section_5_image_6_animation,
          keyframe: university_section_5_image_6_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 4, color: "#2D9B76" },
    title: "이학부 구금자 가혹행위",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: bottom },
          contents:
            "제대로 걸어 다닌 사람이 없었고,\n" +
            "잡아 온 포로를 너무 심하게 두들겨 패서...\n",
          source: "- 여단 본부 경비소대 병장 최용범 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "넓은 강의실에 \n" +
          "붙잡힌 시위대를 무릎 꿇려서 \n" +
          "콩나물시루처럼 모아 놓았어요 \n",
        source: "- 12대대 본부 중사 신동면 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "최용범",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section4/" +
            "choiyongbeom.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 정보보좌관 최명용",
          contents:
            "수백 명의 시위대가 연행되어 1개의 교실에 어깨가 다닥다닥 붙을 정도로 밀집된 상태에서 관리하였음.",
        },
        {
          source: "여단 본부 경비소대 병장 강명한",
          contents:
            "강일석 상사는 포로들이 졸면 나무에 대검을 묶어 포로들을 찔렀고 피가 나면 의무병이었던 홍00이 테이프 등을 발라주었음.",
        },
        {
          source: "여단 본부 경비소대 병장 최용범",
          contents:
            "강의실에 수용된 포로는 강일석 상사가 앉아있는 강의실 앞쪽과 뒤쪽, 앞과 뒤를 오갈 수 있는 통로를 제외하고 강의실 가운데 쪽에 200여 명 정도가 가득 있었음.",
        },
        {
          source: "여단 본부 통신병 방온식",
          contents:
            "이학부 건물 2층에 체포한 시위대 100여 명이 런닝셔츠, 팬티, 교련복을 착용하고 꿇어앉아 있었고, 보안대 요원이 취조했음.",
        },
        {
          source: "12대대 중사 신동면",
          contents:
            "넓은 강의실에 붙잡힌 시위대를 무릎 꿇려서 콩나물시루처럼 모아 놓았음.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section3/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section3/2.png",
          animation: university_section_3_image_2_animation,
          keyframe: university_section_3_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section3/3.png",
          animation: university_section_3_image_3_animation,
          keyframe: university_section_3_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section3/4.png",
          animation: university_section_3_image_4_animation,
          keyframe: university_section_3_image_4_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 5, color: "#512392" },
    title: "전남대에서 교도소 이동상황",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: top },
          contents:
            "연행된 시위대를\n" +
            "약 60명 정도의 많은 인원을 태웠고,\n" +
            "불평을 호소하자 정보참모가 차량에\n" +
            "최루탄 2발을 터뜨렸어요",
          source: "- 여단 본부 정보보좌관 최명용 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "포로 200여 명을\n" +
          "교도소로 이동시키기 위해\n" +
          "차량 2대에 태웠고,\n" +
          "탑승 간 덮개 사이로 포로들이 삐져나와\n" +
          "진압봉으로 때려가며\n" +
          "강제로 태웠어요",
        source: "- 여단 본부 병장 강명한 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "최명용",
          src:
            interviewBaseURL +
            "video/" +
            "university/" +
            "section5/" +
            "choimyeongyoung.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 정보보좌관 대위 최명용",
          contents:
            "교도소로 가기 위해 사망자 및 연행된 시위대를 방송 차량(5/4톤)과 2½톤(방수 덮개 설치) 차량에 각각 약 60명 정도의 많은 인원을 태웠고, 불평을 호소하자 정보참모(윤수웅)가 2½톤 차량에 최루탄 2발을 터뜨렸음. \n 본인 책임하에 전남대 출발 시 폭도가 몰려와 사격을 하였고, 여럿이 죽었을 터인데 조치할 경황이 없어서 그대로 두고 교도소로 이동했음.",
        },
        {
          source: "여단 본부 대위 양규용",
          contents:
            "광주교도소 습격 얘기가 나와서 교도소로 이동했고 시위대로부터 빼앗은 차량을  타고 교도소로 이동하였음.",
        },
        {
          source: "여단 본부 상사 이수길",
          contents:
            "전남대학교에서 연행한 시위대 20~30여 명을 2½톤 트럭 2대 정도에 태우고(양쪽 의자에 앉아서 이동, 자신도 차량 뒷좌석에 시위대와 함께 탑승)교도소에 도착 후, 시위대 남성 3명이 쓰러져 사망하였으나 누가 어디에 가매장했는지는 모름.",
        },
        {
          source: "여단 본부 병장 강명한",
          contents:
            "포로 200여 명을 교도소로 이동시키기 위해 차량 2대(박스카, 2½톤 카고)에 태웠고, 탑승 간 덮개(호루) 사이로 포로들이 삐져나와 진압봉으로 때려가며 강제로 태웠음.",
        },
        {
          source: "여단 본부 병장 최용범",
          contents:
            "시위대 200여 명을 교도소로 이송하기 위해 방송차량 및 군용트럭에 짐짝 실 듯이 강제로 태우면서 덮개(호루) 사이로 인원들이 삐져나오면 진압봉으로 때렸고, 최용성 경비소대장(중위) 지시로 소대장은 방송차량 탑승, 본인은 군용트럭에 탑승에서 교도소로 이송했음.",
        },
        {
          source: "여단 본부 취사병 이상래",
          contents:
            "전남대에서 연행된 시위대를 방송차(박스)에 너무 많이 태워 교도소로 이송하면서 5~6명이 질식사 하였다고 들었음.",
        },
        {
          source: "12대대 하사 최재복",
          contents:
            "전남대에서 고속도로를 이용하여 이동 시 군용트럭 2대가 뒤에서 오더니 기관총을 난사하자, 병력이 일제히 바로 옆 보리밭으로 몸을 숨기고 대응 사격으로 차량 1대는 운전수가 맞았는지 처박혔고, 경황이 없어 시신을 확인하는 등의 절차 없이 그대로 두고 이동하였음.",
        },
        {
          source: "12대대 하사 이승수",
          contents:
            "전남대학교에서 광주교도소로 갈 때 죽은 사람과 시위대를 덮개가 씌워진 차량 적재함에 싣고, 누군가가 최루탄 1발은 터트렸음.",
        },

        {
          source: "12대대 중사 김귀삼",
          contents:
            "전남대에서 교도소로 이동 시 잡힌 포로를 2½톤 차량에 짐짝 쌓듯이 포개어 실었고, 이동 간 도망치지 못하도록 분말 최루가스를 차 안에 뿌려(화염방사기) 포로의 얼굴이 붓고 하얀 가루가 묻어 있었음.",
        },
        {
          source: "12대대 중사 임영규",
          contents:
            "전남대에 수용하고 있던 시위대를 교도소로 이동하기 위해 차량 2대(탑차와 호루가 씌워진 차량)로 옮겼던 것으로 기억함.",
        },
        {
          source: "13대대 중사 나경순",
          contents:
            "전남대에서 교도소로 이동 간에 실탄을 지급했음. (중대장이 탄통을 가지고 다니면서 분배했고 최초 20발을 지급해 주었고, 이후에 60발까지 휴대했었음)",
        },
        {
          source: "15대대 하사 김덕권",
          contents:
            "시위대가 몰려들 때 정문은 최세창이 지시하여 개방하였고, 이때 진압봉에 맞아 쓰러진 사람이 수십 명이 되었음.",
        },
        {
          source: "15대대 중대장 대위 이태희",
          contents: "전남대에서 교도소로 이송한 시위대는 70~80명 정도였음.",
        },
        {
          source: "15대대 중사 이대우",
          contents:
            "전남대학교에서 광주교도소로 이동 시 AMB(구급차)에 포로를 실었는데 대대장인지 윗선에 있는 사람이(본인은 대대장으로 기억하고 있음) 차 안에 최루탄 1발을 터트렸음.",
        },
        {
          source: "16대대 작전장교 대위 권명신",
          contents:
            "정문에서 11, 12대대가 시위대를 로터리 방향으로 밀쳐낸 후 교도소로 이동하기 위해 16대대가 전남대 정문에서 시위대를 차단하며, 이동할 수 있는 여건을 조성하고 마지막으로 교도소로 이동했음.\n교도소로 이동 간 시위대가 고속도로상에서 차량을 이용하여 접근하면서 총기를 난사하여 첫 번째 차량은 경황이 없어 통과하였으나, 대대장들이 두 번째 차량들이 접근했을 때 차량 바퀴를 조준하여 사격하라고 지시하여 사격하였고, 시위대 차량은 논바닥에 뒤집혔는데 사람은 죽지 않았음.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/2.png",
          animation: university_section_4_image_2_animation,
          keyframe: university_section_4_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/3.png",
          animation: university_section_4_image_3_animation,
          keyframe: university_section_4_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/4.png",
          animation: university_section_4_image_4_animation,
          keyframe: university_section_4_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/5.png",
          animation: university_section_4_image_5_animation,
          keyframe: university_section_4_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/6.png",
          animation: university_section_4_image_6_animation,
          keyframe: university_section_4_image_6_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/7.png",
          animation: university_section_4_image_7_animation,
          keyframe: university_section_4_image_7_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/8.png",
          animation: university_section_4_image_8_animation,
          keyframe: university_section_4_image_8_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/university/illustration/section4/9.png",
          animation: university_section_4_image_9_animation,
          keyframe: university_section_4_image_9_keyframe,
        },
      ],
    ],
  },
];
export const prisonIllustrations = [
  {
    illustCnt: 1,
    index: {
      number: 1,
      color: "#0d347f",
    },
    title: "연행자 이송과정의 질식사와 가(암)매장",
    illust: [
      {
        quotation: {
          position: { horizontal: right, vertical: bottom },
          contents:
            "영선반 인원 4~5명과 함께\n" +
            "시신 10구 이상을\n" +
            "직접 매장했습니다.\n",
          source: "- 여단 본부 영선반장 상사 홍봉칠 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "매장에 지원된 인원들은 대대별로\n" +
          "몇 명씩 보내라고 해서 편성되었고,\n" +
          "부대원 명단을 보여주었으나\n" +
          "기억이 안 납니다.\n",
        source: "- 여단 본부 정보보좌관 최명용 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "김승식_현장증언",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section1/" +
            "kimseungsik.mp4",
        },
        {
          name: "유종경",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section1/" +
            "youjongyeong.mp4",
        },
        {
          name: "최용범",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section1/" +
            "choimyeongyoung.mp4",
        },
        {
          name: "홍봉칠, 김승부",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section1/" +
            "hongbongchil-kimseungboo.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 정보보좌관 최명용",
          contents:
            "교도소 도착 첫날 야간에 전남대에서 이송된 시위대 인원 중 17명이 사망하여 하사관 및 병사(대대 소속)들과 함께 교도관 안내로 공동묘지에 매장하였음.",
        },

        {
          source: "여단 본부 영선반장 상사 홍봉칠",
          contents:
            "전남대에서 교도소로 이송된 시위대를 포함, 시신들은 종묘장에 모아두었다가 영선반 인원 4~5명과 함께 시신 10구 이상을 직접 매장했음.",
        },

        {
          source: "여단 본부 상사 김석송",
          contents:
            "4감시대 우측에 땅을 파라고 지시하였는데, 몇 구를 매장했는지, 지휘계통에 보고했는지는 기억나지 않음.",
        },

        {
          source: "여단 본부 상사 이수길",
          contents:
            "전남대학교에서 연행한 시위대 20~30여 명을 2½톤 트럭 2대 정도에 태우고 교도소에 도착 후, 3명이 사망하였으나 누가 어디에 매장했는지는 모름.",
        },

        {
          source: "여단 본부 중사 조병호",
          contents:
            "전남대에서 폭도를 2½ 톤 트럭(호로 장착)에 싣고 와 내려보니 10여 명이 사망하였으나, 어디에 매장했는지는 목격하지도, 듣지도 못했음.",
        },

        {
          source: "여단 본부 통신대 사진병 윤진형",
          contents:
            "3~4구의 시신은 얼굴에 이물질이 묻어 있어서 세숫대야에 물을 받아 얼굴을 씻기고 사진 촬영하였고, 담벽 근처에 매장했다고 들었음.",
        },

        {
          source: "여단 본부 병장 강명한",
          contents:
            "이송된 포로 중 13명 정도가 사망하여 1내무반(행정소대) 인원들이 리어카로 시신을 싣고 4감시대 방향으로 가는 것을 목격했으나, 매장장소는 모름.",
        },

        {
          source: "여단 본부 병장 김만중",
          contents:
            "방송 차량에 잔뜩 실려 온 인원들이 땅바닥에 팔이 뒤로 묶인 채 엎드려 있는 사이로 여러 명이 죽어있었는데 어떻게 처리했는지는 모름.",
        },

        {
          source: "여단 본부 병장 김만중, 최용범",
          contents:
            "교도소에 도착하여 시위대 인원 중 실신하거나 죽은 사람이 16명이었다고 하였음. (당시 동기생인 강명한씨가 현장에 있었고 숫자를 확인했음)",
        },

        {
          source: "여단 본부 취사병 이상래",
          contents:
            "전남대에서 연행된 시위대를 방송차(박스)에 너무 많이 태워 교도소로 이송하면서 5~6명이 질식사한 사실이 있었던 것으로 들었음.",
        },

        {
          source: "11대대 중대장 대위 김철규",
          contents:
            "교도소 도착 후 박스카(밀폐된 차량)에서 내린 시위대 몇 명이 죽은 것으로 기억하는데, 어떻게 처리했는지는 모름.",
        },

        {
          source: "12대대 중사 김귀삼",
          contents:
            "도착 직후 포로들이 많이 죽었고, 이중 인중에 총을 맞은 사람은 후두부가 없었으며, 시신은 쌀 포대로 머리부터 씌우는 것을 목격했으나, 매장은 모름.",
        },

        {
          source: "12대대 하사 홍덕택",
          contents:
            "교도소로 이송된 포로들을 마당에 내려놓았고, 이중 사망자도 있었는데, 누가 어디에 매장했는지는 못 봤음.",
        },

        {
          source: "12대대 하사 김영천",
          contents:
            "전남대에서 이송해온 것으로 추정되는 시신 십여 구가 담장 앞 공터에 있었고, 주변에 가매장했다고 들었음.",
        },

        {
          source: "12대대 하사 김승식",
          contents:
            "시위대를 싣고 온 트럭(2½톤)에서 동료들과 함께 사망자 12명과 중상자들을 내렸고, 시신은 여단 본부에서 매장한 것으로 알고 있음.",
        },

        {
          source: "13대대 하사 박춘남",
          contents:
            "교도소에 도착한 군용트럭에 부상자 및 사망자 등 30여 명 정도가 트럭 바닥에 깔려 있었고, 트럭에 올라가 시신을 발로 바닥으로 밀쳐냈음.",
        },

        {
          source: "15대대 중대장 대위 이태희",
          contents:
            "교도소로 이송된 시위대 70~80명을 상태별로 분류(사망, 부상)하였고, 통제를 잘 따르지 않으면 진압봉으로 때리는 등 끔찍한 행동을 자행했음.",
        },

        {
          source: "15대대 상사 박재만",
          contents:
            "전남대에서 교도소로 이동 시 데려온 시위대(호로 씌운 차로 옮겼음)가 죽어있는 것을 목격함.",
        },

        {
          source: "15대대 하사 허조",
          contents:
            "교도소에 도착하여 차량에서 시위대를 끌어 내릴 때 사망자를 목격하였음.",
        },

        {
          source: "16대대 작전장교 대위 권명신",
          contents:
            "상황 회의 시 시신 8구를 교도소 북쪽 야산 공동묘지에 매장했다는 얘기를 들었음.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section1/1.png",
          animation: prison_section_1_image_1_animation,
          keyframe: prison_section_1_image_1_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section1/2.png",
          animation: prison_section_1_image_2_animation,
          keyframe: prison_section_1_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section1/3.png",
          animation: prison_section_1_image_3_animation,
          keyframe: prison_section_1_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section1/4.png",
          animation: prison_section_1_image_4_animation,
          keyframe: prison_section_1_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section1/5.png",
          animation: prison_section_1_image_5_animation,
          keyframe: prison_section_1_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section1/6.png",
          animation: prison_section_1_image_6_animation,
          keyframe: prison_section_1_image_6_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section1/7.png",
          animation: prison_section_1_image_7_animation,
          keyframe: prison_section_1_image_7_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 2, color: "#0d347f" },
    title: "교도소 외역창고 구금, 연행자 가혹행위",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: bottom },
          contents:
            "방위병이라는 이유로\n" +
            "한 사람이 극심한 구타와\n" +
            "심지어 담뱃불로 눈을 지져버리는 등의\n" +
            "가혹행위를 당하는 것을 목격하였고,\n" +
            "그 방위병은 사망했을 것으로 생각됩니다",
          source: "- 강길조 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "시위대가 도망가지 못하도록 \n" +
          "쇠사슬로 문을 걸었고,\n" +
          "내부에 화장실이 없어\n" +
          "깡통에 대소변을 보게 했어요",
        source: "- 여단 본부 중사 이상덕 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "김병준",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section2/" +
            "kimbyeongjun.mp4",
        },
        {
          name: "이상덕",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section2/" +
            "leesangdeok.mp4",
        },
        {
          name: "이양교",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section2/" +
            "leeyangyo.mp4",
        },

        {
          name: "최용범",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section2/" +
            "choiyoongbeom.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부 정보보좌관 최명용",
          contents:
            "시위대 200여 명은 창고 내에 수용하였고, 환경이 좋지 않아 똥, 오물을 그대로 방치하는 등 인간적인 취급을 하지 않았으며, 그중에 죽은 인원도 있었음.",
        },
        {
          source: "여단 본부 중사 이상덕",
          contents:
            "수용실은 시위대가 도망가지 못하도록 쇠사슬로 문을 걸었고, 내부에 화장실이 없어 깡통에 대소변을 보게 했음.",
        },
        {
          source: "여단 본부 병장 최용범",
          contents:
            "당시 경찰은 포로 심문시 눈을 감게 하고, 양손을 책상 위에 손바닥을 올려놓게 하고 질문하였고, 겁에 질려 손을 거둬들이면 무지막지한 폭력을 행사함.",
        },
        {
          source: "11대대 중사 이양교",
          contents:
            "12대대 윤중사가 포로 2명을 전투화 발로 턱 부위를 걷어차 사망하였고, 옆에 있던 사람이 공포에 질려 사망하였음.",
        },
        {
          source: "12대대 하사 김흥식",
          contents:
            "여성이 3~4명 정도 있었고, 헌병으로 보이는 사람이 신상을 확인하였으며, 여성들은 두들겨 맞은 듯 쇄골 부위가 멍이 들어있었음.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section2/1.png",
          animation: prison_section_2_image_1_animation,
          keyframe: prison_section_2_image_1_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section2/2.png",
          animation: prison_section_2_image_2_animation,
          keyframe: prison_section_2_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section2/3.png",
          animation: prison_section_2_image_3_animation,
          keyframe: prison_section_2_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section2/4.png",
          animation: prison_section_2_image_4_animation,
          keyframe: prison_section_2_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section2/5.png",
          animation: prison_section_2_image_5_animation,
          keyframe: prison_section_2_image_5_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section2/6.png",
          animation: prison_section_2_image_6_animation,
          keyframe: prison_section_2_image_6_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section2/7.png",
          animation: prison_section_2_image_7_animation,
          keyframe: prison_section_2_image_7_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section2/8.png",
          animation: prison_section_2_image_8_animation,
          keyframe: prison_section_2_image_8_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 3, color: "#0d347f" },
    title: "교도소 일원의 총격전과 계엄군의 기관총 발포상황",
    illust: [
      {
        quotation: {
          position: { horizontal: left, vertical: top },
          contents:
            "주, 야간 구분 없이\n" +
            "M60 기관총으로\n" +
            "지나가는 차량애 대해\n" +
            "저격했어요",
          source: "- 12대대 화기 하사 최재복 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "주로 야간에\n" +
          "시민군의 습격이 있었으며,\n" +
          "교도소로 접근해오는 인원에 대해\n" +
          "사격을 했습니다.",
        source: "- 여단 본부대장 소령 김승부 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "김용하",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section3/" +
            "kimyoungha.mp4",
        },
        {
          name: "류구",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section3/" +
            "ryugoo.mp4",
        },
        {
          name: "윤행근",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section3/" +
            "yoonhangeun.mp4",
        },
        {
          name: "이양교",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section3/" +
            "leeyangyo.mp4",
        },
        {
          name: "최용범",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section3/" +
            "choiyoungbeom.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부대장 소령 김승부",
          contents:
            "도보로 고속도로 옆길을 따라 교도소에 도착하기 전 한 번의 총성이 있었고, 야간에 시민군의 습격이 있었으며, 접근해오는 인원에 대해 사격했음.",
        },
        {
          source: "여단 본부 정보보좌관 최명용",
          contents:
            "저녁에 광주-담양 방향으로 아기를 업고 가는 아주머니를 12대대 하사가 저격, 사망케 하여 본인이 죽을 만큼 때렸고, 시신과 아기는 주민이 곧바로 데려갔음.",
        },
        {
          source: "여단 본부 병장 최용범",
          contents:
            "4감시대에 저격수 배치, 고속도로를 지나는 차량을 저격, 차량과 사망자는 교도소로 끌고 들어왔고, 교도소로 옮겨진 짚차 운전석에 왼쪽 가슴에 총을 맞고 죽은 남자를 목격하였음.",
        },
        {
          source: "여단 본부 병장 김만중, 최용범",
          contents:
            "교도소 일대에 접근하는 인원과 차량은 시위대 여부를 확인하지 않은 상태에서 일방적으로 사격을 했음.",
        },
        {
          source: "여단 본부 통신병 방온식",
          contents:
            "교도소 입구 삼거리에 있는 주유소 옥상에서 시민군이 군인들을 향해 사격하여 이에 대응 사격으로 시민군 2~3명을 사살했다는 무전을 들었음.",
        },
        {
          source: "11대대 중사 임휘자",
          contents:
            "첫날 새벽 교도소 입구에서 접근하는 시민군을 향해 1지역대에서 사격을 하였고, 아침에 현장에 가보니 2명이 사망하였음.",
        },
        {
          source: "11대대 중사 이양교",
          contents:
            "시민군이 밤에 교도소를 향해 총격을 가하면 그 방향으로 사격하였고, 다음날 현장에서 시신을 회수해 왔음.",
        },
        {
          source: "12대대 하사 조영국",
          contents:
            "· 설탕 간판이 있는 고지에서 고등학생 3명을 붙잡았고, 이 중 1명이 도주하자, 사격하여 창자가 흘러나와 군의관 진료 후 후송시킨 것으로 알고 있음.\n" +
            "· 시위대 차량이 정문으로 접근했을 때 별도의 사격명령 없이 12, 13대대 인원들이 일제히 집중 사격하여 30~60여 명은 죽었겠다고 생각됨.",
        },
        {
          source: "12대대 하사 송기용",
          contents:
            "광주-담양 고속도로에서 시위대 차량 8~12대가 교도소로 오면서 사격하여 이에 응사하면서 10~20분간 총격전이 있었음.",
        },
        {
          source: "12대대 하사 김권식",
          contents:
            "새벽에 고속도로에서 버스, 군용트럭 등 시위대의 차량 15대 이상이 이동하며 사격하여 제지하였고 다음 날 현장에 가보니 많이 죽었다고 들었음.",
        },
        {
          source: "12대대 하사 김승식",
          contents:
            "야간에 시위대가 버스를 타고 와서 집중사격을 했고, 다음 날 아침에 가보니 시신 1구가 있어서 교도소로 가지고 왔음.",
        },
        {
          source: "12대대 하사 최재복",
          contents:
            "군용트럭 1대(3명 정도 탑승)가 교도소 정문 방향 중간쯤 진입 시 사격하여 사망자 등 3명이 죽은 것으로 기억하며 시신은 어떻게 했는지 모름.",
        },
        {
          source: "15대대 하사 신승모",
          contents:
            "고속도로에서 시위대 차량이 접근하여 사격, 현장 일대에 수색 시 버스와 차량 여러 대가 널브러져 있었고 떡, 사과박스도 있었음.",
        },
        {
          source: "15대대 하사 김종원",
          contents:
            "일자 미상 5~6시경 교도소 정문에 접근한 시위대 차량 10여 대에 대해 12, 13대대가 집중사격하였음",
        },
        {
          source: "15대대 중사 박성현",
          contents:
            "APC 1대가 접근하여 M60기관총으로 사격, 11톤 트럭을 타고 온 사람을 잡았고 위의 APC와 트럭 2대를 담양방면 비포장도로에 바리케이드로 활용했음.",
        },
        {
          source: "15대대 상사 박재만",
          contents:
            "야간에 고속도로에서 차량 2대가 접근하여 교전하였고, 다음날 낮에도 고속도로를 지나가는 차량 몇 대를 저격하여 잡았음.",
        },
        {
          source: "15대대 상사 최연문",
          contents:
            "도착 첫날 저녁에 고속도로에 시위대 차량(짚차) 1대가 와서 15대대가 사격하여 사망하였음.",
        },
        {
          source: "15대대 중사 이대우",
          contents:
            "고속도로에서 짚차 1대가 광주 방향에서 접근하면서 막대기를 창밖으로 내밀자, 총으로 오인하여 일제히 사격하여 1~2명이 죽고 1~2명은 생포하였음.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section3/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section3/2.png",
          animation: prison_section_3_image_2_animation,
          keyframe: prison_section_3_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section3/3.png",
          animation: prison_section_3_image_3_animation,
          keyframe: prison_section_3_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section3/4.png",
          animation: prison_section_3_image_4_animation,
          keyframe: prison_section_3_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section3/5.png",
          animation: prison_section_3_image_5_animation,
          keyframe: prison_section_3_image_5_keyframe,
        },
      ],
    ],
  },
  {
    illustCnt: 1,
    index: { number: 4, color: "#0d347f" },
    title: "교도소 일원의 사망자 가(암)매장",
    illust: [
      {
        quotation: {
          position: { horizontal: right, vertical: top },
          contents:
            "시신을 옮기는데\n" +
            "전혀 어려움이 없었고,\n" +
            "땅을 굴토하는데도\n" +
            "전혀 힘들지 않게 했던 것으로\n" +
            "기억합니다.",
          source: "- 여단 본부대장 소령 김승부 인터뷰 내용 중에서 -",
        },
      },
    ],
    textBook: {
      quotation: {
        contents:
          "공동묘지에 시신을 묻으라는\n" +
          "통신 내용을 듣고\n" +
          "공동묘지가 있는 것을 알았습니다.",
        source: "- 여단 본부 통신병 방온식 인터뷰 내용 중에서 -",
      },
      videos: [
        {
          name: "김용하",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section4/" +
            "kimyoungha.mp4",
        },
        {
          name: "박성현",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section4/" +
            "parkseunghyun.mp4",
        },
        {
          name: "최용범",
          src:
            interviewBaseURL +
            "video/" +
            "prison/" +
            "section4/" +
            "choiyoungbeom.mp4",
        },
      ],
      sentences: [
        {
          source: "여단 본부대장 소령 김승부",
          contents:
            "11~12구의 시체는 옷을 입은 상태로 가마니에 덥혀 있는 것을 별도의 표식을 하지 않고 4감시대 부근에 3개소의 구덩이에 매장했던 것으로 기억됨.",
        },
        {
          source: "여단 본부 정보보좌관 최명용",
          contents:
            "12, 13대대에서 저녁에 집중 사격하여 발생한 시신은 수습하지 않았고, 여단장 지시로 외곽에서 사망한 시신은 대대별로 현지에서 매장하였음.",
        },
        {
          source: "여단 본부 중사 윤춘기",
          contents:
            "시신 5구를 본부대 김석송 상사 등 대대 하사관들이 3감시대에서 4감시대 방향 약 150미터(추정) 떨어진 2중 철조망 사이에 매장하는 것을 목격했음.",
        },
        {
          source: "여단 본부 병장 김만중, 최용범 공통진술",
          contents:
            "사망자들은 본청 일대에 방치, 짚차에서 총상으로 사망한 시신은 차량에 1~2일 정도 방치하였다가 현 오수처리장이 있는 곳에 매장한 것으로 추정됨.",
        },
        {
          source: "여단 본부 취사병 이상래",
          contents:
            "2일차 아침에 시신(5-6구)을 묻고 오라는 지시를 받고, 병사 3명과 함께 가마니에 쌓여 손수레에 실려 있던 시신을 관사 앞 소나무 숲에 매장하였다고 함.",
        },
        {
          source: "11대대 하사 오인수",
          contents:
            "주유소 옥상에 저격수를 배치하여 지나가는 차량의 운전수 등을 저격하여 사망한 시신 몇 구를 가져와 침낭 안에 넣고 앞 야산에 가매장했다고 들었음.",
        },
        {
          source: "11대대 지역대장 대위 류구",
          contents:
            "주유소 부근에서 시위대 차량과 장갑차가 진입해 온 것을 기관총 난사로 2명 사망하여 보리밭에 가매장하도록 지시 및 결과를 보고 받은 바 있음.",
        },
        {
          source: "11대대 중대장 대위 박성현",
          contents:
            "당시 교도소 진입로 건너편 야산(현재의 금호아파트 부지)에 희생자를 많이 매장했다고 들었는데 11대대 3지역대에서 매장한 것임.",
        },
        {
          source: "11대대 중대장 대위 김철규",
          contents:
            "대대 정보장교가 1:5만 지도(휴대용)에 가매장 위치를 표시해 놓은 것을 직접 목격했고, 가매장은 1지역대(편종식 대위)가 담당한 것으로 알고 있음.",
        },
        {
          source: "11대대 중사 임휘자",
          contents:
            "새벽에 1지역대에서 교도소 입구로 접근하는 시민군을 향해 사격, 2명이 사망하여 교도소 앞 야산에 직접 매장하였음.",
        },
        {
          source: "11대대 중사 이양교",
          contents:
            "교도소 앞 보리밭에서 회수한 시신을 리어카를 이용하여 교도소 내로 옮기는 것을 2차례 목격했음.",
        },
        {
          source: "12대대 하사 최재복",
          contents:
            "시위대 3명이 탑승한 군용트럭이 정문 방향으로 진입 시 사격하여 3명이 죽은 것으로 기억, 13대대에서 광주-담양 고속도로 주변으로 매복 후 복귀 시 사살한 시위대를 차량으로 싣고 들어온 모습을 목격한 적이 있음.",
        },
        {
          source: "12대대 하사 최여광",
          contents:
            "시신들은 여단 본부 상사들이 전담해서 처리했다고 들었고, 작전에 투입하지 못한 인원(여단/대대 인원)을 시신처리 요원으로 편성했다고 들었음.",
        },
        {
          source: "12대대 하사 김흥식",
          contents:
            "교전 후 발생한 시신과 부상자들은 정문 앞 공터에 30~40여 명이 있었고, 가마니에 덥혀 있는 시신을 옮기는 것을 목격했는데, 처리 여부는 모름.",
        },
        {
          source: "12대대 하사 송기용",
          contents:
            "고속도로에서 부상자와 사망자(4~6구)를 교도소로 데려와 부상자는 응급실로, 사망자는 얼굴을 닦고 사진을 찍고, 고참 상사들이 매장했다고 들었음.",
        },

        {
          source: "12대대 하사 이승수",
          contents:
            "13대대 하사로부터 포로 20여 명 중 1~2명이 죽었고, 시민군과 교전 후 3명이 죽어 대대 동기생들이 매장했다가 비행장으로 이동 시 회수했다고 함.",
        },

        {
          source: "12대대 하사 김성수",
          contents:
            "교도소 입구 공터에 시내 및 교도소 인근에서 죽은 사람을 수시로 데려와 모아 놓았고, 보병들이 1~2감시대 사이에 매장했던 것으로 알고 있음.",
        },

        {
          source: "12대대 중사 임영규",
          contents:
            "주간에 김석송 상사 등 본부대 인원들이 4감시대 주변 소장관사 뒤 울타리 내부에 구덩이 하나를 크게 파서 몇 구(10구 미만으로 기억)를 매장했음.",
        },

        {
          source: "13대대 중사 김진수",
          contents:
            "희생된 시신을 트럭으로 싣고 오면 현재의 오수처리장 일대에 들것을 이용하여 옮겨 매장했음.",
        },

        {
          source: "15대대 하사 허조",
          contents:
            "시신은 땅거미가 질 때 모아둔 위치에서 손수레를 이용하여 고속도로 방향(4감시대 방향으로 추정)으로 실어 나르는 것을 1차례 목격했음.",
        },

        {
          source: "15대대 하사 김종원",
          contents:
            "시신은 저녁에 가마니에 싸고 구르마로 대략 3~4번에 걸쳐 3감시대 우측 버드나무 사이에 매장(1구덩이 1구씩)한 것으로 기억됨.",
        },

        {
          source: "15대대 하사 신승모",
          contents:
            "교전 후 발생한 시신은 4감시대 주변 정화조 뚜껑 뒤편의 미루나무 사이에 매장한 것으로 들었음.",
        },

        {
          source: "15대대 하사 김연철",
          contents:
            "저격하여 사망한 시신 7~8구가 부패하기 시작하자 2~3일 뒤 사진을 찍고 대대별로 4감시대 일대 매장했음.",
        },

        {
          source: "15대대 하사 김덕권",
          contents:
            "아침에 시신 2구가 가마니에 싸여 있는 것을 보았고 구르마를 이용해 고속도로 방향 향나무 종묘장(위장수로 보였음) 인근에 매장했다고 들었음.",
        },

        {
          source: "15대대 상사 박재만",
          contents:
            "고속도로에서 사망한 인원은 교도소로 이동할 수 있는 통로를 통해 옮긴 후, 3감시대 일대 시신 10구 정도를 매장하는 것을 목격했음.",
        },

        {
          source: "15대대 상사 최연문",
          contents:
            "4감시대 주변 주벽-개인호(도랑)-밭-철조망-고속도로로 형성되어 있는 밭고랑에 가매장 했다는 소리를 들었음.",
        },

        {
          source: "15대대 중사 안종연",
          contents: "시위대 7명이 사망하여 울타리 부근에 매장했다고 들었음.",
        },

        {
          source: "15대대 중사 김용하",
          contents:
            "3감시대와 고속도로 사이 밭이랑 사이에 시신 5~6구 정도를 흙을 배수로에 덮는 형태로 매장하는 것을 목격함.",
        },

        {
          source: "15대대 중사 전성열",
          contents:
            "일자불상 경 시민들이 지나가면 사격하여 사망한 시신(10구 미만)을 교도소 담장 외곽 배수로에 줄줄이 놓여있는 것을 보았고, 매장여부는 모름.",
        },
      ],
    },
    images: [
      [
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section4/1.png",
          animation: null,
          keyframe: null,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section4/2.png",
          animation: prison_section_4_image_2_animation,
          keyframe: prison_section_4_image_2_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section4/3.png",
          animation: prison_section_4_image_3_animation,
          keyframe: prison_section_4_image_3_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section4/4.png",
          animation: prison_section_4_image_4_animation,
          keyframe: prison_section_4_image_4_keyframe,
        },
        {
          src: "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/image/prison/illustration/section4/5.png",
          animation: prison_section_4_image_5_animation,
          keyframe: prison_section_4_image_5_keyframe,
        },
      ],
    ],
  },
];
