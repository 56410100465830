export const navigatorsInfos = {
  station: [
    "1.시위대 차량시위와 계엄군의 대응",
    "2.전남대(지휘본부)에서 광주역으로 실탄 지원 및 보급",
    "3.신안동 사거리 계엄군 사망사건",
    "4.지휘관의 권총발사와 발포명령",
    "5.기관총 설치와 운용",
    "6.집단발포와 발포에 의한 현장피해자",
    "7.전남대 주둔지 복귀",
    "광주역 피해자 지도",
  ],
  university: [
    "1.시위대 상황과 계엄군의 대응",
    "2.전남대 정,후문 집단발포 상황",
    "3.사망자 가(암)매장",
    "4.이학부 구금자 가혹행위",
    "5.전남대에서 교도소 이동상황",
    "전남대 피해자 지도",
  ],
  prison: [
    "1.연행자 이송과정의 질식사와 가(암)매장",
    "2.교도소 외역창고 구금, 연행자 가혹행위",
    "3.교도소 일원의 총격전과 계엄군의 기관총 발포상황",
    "4.교도소 일원의 사망자 가(암)매장",
    "광주교도소 피해자 지도",
  ],
};
