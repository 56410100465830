export const station_summaries = [
  "1. 강길조		상이 / 무등경기장 / 전신구타, 타박상",
  "2. 고광문		상이 / 광주역 / 두피열상, 뇌진탕",
  "3. 공호택		상이 / 광주역 / 전신구타",
  "4. 기남용		사망 / 광주역 / (자상, 총상 복합 사인에 의한 사망)",
  "5. 김만두		사망 / 광주역 / 목 총상, 과다출혈",
  "6. 김명수		상이 / 신안동 광성여객 내 / 구타",
  "7. 김명환		상이 / 광주역 앞 / 좌측 눈 총상",
  "8. 김성태		상이 / 광주역 / 구타, 좌측 발 부상",
  "9. 김앵도		상이 / 광주 시청앞 / 좌측 팔 총상",
  "10. 김용완	상이 / 광주역 광장 / 만성경막외 혈종. 두개골 우경골 골절, 뇌자상",
  "11. 김용철	상이 / 신역 앞 / 머리구타 부상",
  "12. 김재수	사망 / 광주역 부근 / 흉부,발목 등 총상",
  "13. 김재화	사망 / 광주역 광장 / 좌우흉부 관통상",
  "14. 김현묵	상이 / 북동굴다리근처 / 전신구타",
  "15. 노덕진	상이 / 신안동 롯데제과 앞 / 전신구타",
  "16. 류관열	상이 / 광주역 광장 앞 / 왼쪽어깨 총상",
  "17. 문병호	상이 / 고속버스터미널 중앙고속 뒤 / 좌측둔부 총상",
  "18. 박진천	상이 / 광주역/전신구타, 머리부상",
  "19. 범정진	상이 / 신안동 광성여객 내 / 구타",
  "20. 서수천	상이 / 광주고속앞 한국스레트건물 3층 / 구타",
  "21. 신동남	행방불명자",
  "22. 안승권	상이 / 신안 롯데제과 / 전신구타",
  "23. 여흥용	상이 / 광주역 / 우측 어깨와 좌측 손등에 총상",
  "24. 오일성	상이 / 신안 롯데제과 / 전신구타",
  "25. 유진관 사망 / 광주역 부근 총상(위원회 조사결과 사망 장소와 경위가 다른 것으로 확인)",
  "26. 윤경철	상이 / 중흥동 원예협동조합 정문 도로 / 좌측 무릎 총상",
  "27. 이돈채	상이 / 광주역 광장 / 전신구타",
  "28. 이북일	사망 / 광주역 부근/총상",
  "29. 정영만	상이 / 광주역/머리, 전신구타",
  "30. 정현택	상이 / 광주역 광장/가슴 총상",
  "31. 주영남	상이 / 중흥동 송광교통 / 머리, 전신구타",
  "32. 차전호	상이 / MBC문화방송국 / 두정부열창 타박",
  "33. 한용호	상이 / 광주역/왼쪽 다리 총상",
  "34. 허봉 사망 / 광주역 / 우측두정골 열상, 좌측 전두부 자상",
];
export const station_marks = [
  {
    top: 22,
    right: 47,
    direction: "right",
    names: [
      "1. 강길조",
      "6. 김명수",
      "15. 노덕진",
      "19. 범정진",
      "22. 안승권",
      "24. 오일성",
    ],
  },
  {
    top: 38,
    right: 40,
    direction: "right",
    names: ["7. 김명수", "19. 범정진"],
  },
  {
    top: 33,
    right: 29,
    direction: "right",
    names: [
      "2. 고광문",
      "3. 공호택",
      "4. 기남용",
      "5. 김만두",
      "7. 김명환",
      "8. 김성태",
      "10. 김용완",
      "11. 김용철",
      "12. 김재수",
      "13. 김재화",
      "16. 류관열",
      "18. 박진천",
      "21. 신동남",
      "23. 여흥용",
      "27. 이돈채",
      "28. 이북일",
      "29. 정영만",
      "30. 정현택",
      "33. 한용호",
      "34. 허봉",
    ],
  },
  {
    top: 43,
    right: 24,
    direction: "right",
    names: ["26. 윤경철", "31. 주영남"],
  },
  {
    top: 55,
    right: 34,
    direction: "right",
    names: ["17. 문병호", "20. 서수천"],
  },
  {
    top: 58,
    right: 53,
    direction: "right",
    names: ["14. 김현묵"],
  },
  {
    top: 45,
    right: 10,
    direction: "left",
    names: ["9. 김앵도"],
  },
  {
    top: 90,
    right: 15,
    direction: "left",
    names: ["32. 차전호"],
  },
];
export const university_summaries = [
  "1. 강승우		상이 / 전남대 정문 부근 민가 / 좌측머리봉합, 다리 부상",
  "2. 고영호		상이 / 전남대 사거리 / 타박상",
  "3. 권상헌		상이 / 전남대 부근 / 머리 총상",
  "4. 김광복		행불 후 사망 / 전남대 / 두부관통 총상 위원회 확인",
  "5. 김명동		상이 / 전남대 사거리 주유소 앞 / 머리 구타",
  "6. 김병준		상이 / 연행구금/전남대후문 / 전신구타",
  "7. 김순만		상이 / 전남대 사거리 / 전신구타, 타박상",
  "8. 김연태		상이 / 전남대 앞 / 타박상",
  "9. 김재언		상이 / 전남대 정문 앞 / 대검에 의한 좌상, 구타",
  "10. 김점호	상이 / 전남대 입구 / 머리, 허리 구타",
  "11. 명재관	상이 / 전남대 정문 / 머리 타박상",
  "12. 문병윤	상이 / 전남대 정문 도로변 / 복부관통상M16 총상",
  "13. 박성용	상이 / 전남대 정문 / 하복부 총상",
  "14. 박양규	상이 / 전남대 사거리 / 타박상",
  "15. 박연진	상이 / 전남대 후문 앞 / 다리 구타",
  "16. 신경식	상이 / 전남대 후문 / 타박상",
  "17. 신상균	상이 / 전남대 앞 / 좌측대퇴부 관통 총상",
  "18. 신석현	상이 / 신안동사무서 앞 / 머리, 다리부상, 타박상",
  "19. 신성준	상이 / 광성여객 / 타박상",
  "20. 안남석	상이 / 전남대 정문 / 타박상",
  "21. 안민구	상이 / 광성여객 / 타박상",
  "22. 양일권	상이 / 전남대 앞 / 우측대퇴부 및 하지 총상",
  "23. 우연하	상이 / 광성여객 / 타박상",
  "24. 유구성	상이 / 광성여객 / 타박상",
  "25. 이금영	정관철 제3공수여단 중사 사망 관련 체포, 구금 및 가혹행위",
  "26. 이배식	상이 / 신안동 대한통운 앞 / 대검 좌측 허벅지, 타박상",
  "27. 이성귀	사망 / 전남대 / 자상, 타박상",
  "28. 임성욱	상이 / 전남대 정문 / 머리진압봉 타박상",
  "29. 전성민	상이 / 중흥동 집 / 전신구타, 타박상",
  "30. 전종태	상이 / 전대입구 사거리/전신 구타, 타박상",
  "31. 정락준	상이 / 신안동 롯데제과 앞 사거리 / 전신구타. 타박상",
  "32. 정창진	상이 / 광신여객 뒤 광일여인숙 앞 / 전신구타",
  "33. 조달훈	상이 / 전남대 앞 철다리 / 전신구타, 타박상",
  "34. 조옥현	상이 / 전남대 사거리 / 전신구타, 타박상",
  "35. 조철제	상이 / 전남대 앞 / 타박상",
  "36. 지정용	상이 / 광성여객 / 타박상",
  "37. 최공우	상이 / 신안동사무소 / 전신구타",
  "38. 최미애	사망 / 전남대 중흥동 집 앞 / 두부총상",
  "39. 최성환	상이 / 전남대 철교 밑 / 좌대퇴부 및 슬관절 파편창",
  "40. 최승석	상이 후 사망",
  "41. 최승희	사망 / 전남대 부근 / 우흉부맹관상",
  "42. 최현철	상이 / 전남대 입구 / 전신구타, 머리부상",
  "43. 최형석	상이 / 전남대 정문 앞 / 머리 구타",
  "44. 홍석종	상이 / 전남대 입구 사거리 / 구타, 타박상",
  "45. 홍순영	상이 / 전남대 / 팔, 다리 타박상",
];
export const university_marks = [
  {
    top: 5,
    right: 2,
    direction: "left",
    names: ["16. 신경식"],
  },
  {
    top: 37,
    right: 23,
    direction: "left",
    names: ["25. 이금영", "40. 최승석"],
  },
  {
    top: 37,
    right: 14.5,
    direction: "right",
    names: ["6. 김병준", "15. 박연진"],
  },
  {
    top: 50,
    right: 30,
    direction: "left",
    names: [
      "3. 권상헌",
      "4. 김광복",
      "8. 김연태",
      "9. 김재언",
      "10. 김점호",
      "11. 명재관",
      "12. 문병윤",
      "13. 박성용",
      "17. 신상균",
      "20. 안남석",
      "22. 양일권",
      "27. 이성귀",
      "28. 임성욱",
      "35. 조철제",
      "41. 최승희",
      "42. 최현철",
      "43. 최형석",
      "45. 홍순영",
    ],
  },
  {
    top: 51,
    right: 22,
    direction: "right",
    names: ["1. 강승우"],
  },
  {
    top: 55,
    right: 27.5,
    direction: "right",
    names: ["29. 전성민"],
  },
  {
    top: 55.2,
    right: 18.5,
    direction: "right",
    names: ["38. 최미애"],
  },
  {
    top: 67,
    right: 33.5,
    direction: "right",
    names: [
      "2. 고영호",
      "5. 김명동",
      "7. 김순만",
      "14. 박양규",
      "30. 전종태",
      "33. 조달훈",
      "34. 조옥현",
      "39. 최성환",
      "44. 홍석종",
    ],
  },
  {
    top: 67,
    right: 15,
    direction: "left",
    names: [
      "19. 신성준",
      "21. 안민구",
      "23. 우연하",
      "24. 유구성",
      "26. 이배식",
      "31. 정락준",
      "32. 정창진",
      "36. 지정용",
    ],
  },
  {
    top: 80,
    right: 36.2,
    direction: "left",
    names: ["18. 신석현", "37. 최공우"],
  },
];
export const prison_summaries = [
  "1. 고규석	사망/교도소 부근 광주·당양 고속도로 위/흉부관통총창 및 흉추골 골절상",
  "2. 김병연	사망/교도소부근/좌흉부맹관상",
  "3. 김성수 일가족(딸 김내향, 아내 김춘화)/상이/고속도로 진입로",
  "4. 김인태	사망/광주교도소 앞 부근(추정)/타박사, 두개골 압박골절",
  "5. 김종수	상이/교도소/구타. 우측완관절 염좌. 뇌좌상",
  "6. 김태수	상이/교도소 담벼락 밑/총상(좌측 대퇴부)",
  "7. 김행주	상이/고속도로 진입로/우측손,우측발 총상",
  "8. 민병열	사망/전남대에서 이송 후 교도소 시신/후두부자창 및 두부손상(두개골 골절)",
  "9. 박진천	상이/광주교도소/좌측철장관절, 골관절염 타박상",
  "10. 서만오	사망/교도소 부근/하복부 총상",
  "11. 서종덕	사망/교도소 부근/좌흉상부 맹관창",
  "12. 심영의	상이/교도소내/대검, 타박상",
  "13. 안두환	사망/전남대 앞에서 연행/80.05.27 교도소 시신8구 중 발견",
  "\t타박상(둔기) 좌두정부타박열창(두각내출혈로 추즉).",
  "14. 안병섭	사망/교도소 부근/좌대퇴부 총상",
  "15. 이명진	사망/교도소 부근/좌후두맹관총상(뇌손상_후두부 봉합흔적 있음)",
  "16. 이용충	사망/교도소 부근/안부맹관골절상(뇌손상).안부맹관창(3군데)",
  "17. 이정용	상이/교도소부근/턱아래 목부위에 총상",
  "18. 임은택	사망/교도소 부근 광주·당양 고속도로 위/총상",
  "19. 장방환	사망/교도소 근처/타박상(둔기).후두부 타박및 열창으로 인한 두각외출혈",
  "20. 최열락	사망/교도소 앞/좌흉부관통상/총상",
];
export const prison_marks = [
  {
    top: 32.5,
    right: 21,
    direction: "right",
    names: [
      "5. 김종수",
      "8. 민병열",
      "9. 박진천",
      "12. 심영의",
      "13. 안두환",
      "19. 장방환",
    ],
  },
  {
    top: 46,
    right: 18,
    direction: "right",
    names: ["10. 서만오", "11. 서종덕", "15. 이명진", "16. 이용충"],
  },
  {
    top: 54,
    right: 20,
    direction: "right",
    names: ["14.안병섭"],
  },
  {
    top: 40,
    right: 26,
    direction: "left",
    names: ["4. 김인태", "20. 최열락"],
  },

  {
    top: 38,
    right: 32,
    direction: "right",
    names: ["1. 교규석", "2. 김병연", "17. 이정용", "18. 임은택"],
  },
  {
    top: 54,
    right: 36,
    direction: "right",
    names: ["3. 김성수 일가족", "7. 김행주"],
  },
];
