import styled from "styled-components";
import { notoSans, notoSerif } from "../../../fonts";

export const Wrapper = styled.div`
  background-image: url("/images/station/title/title_background.png");
  background-size: contain;
  background-repeat: round;
  z-index: -1;
`;

export const Container = styled.div`
  //background: black;
  padding: 8vw 5vw;
  color: rgb(255, 255, 255);
  height: 100%;
`;

export const Title = styled.div`
  text-align: center;
  ${notoSans};
  font-weight: 900;
  line-height: 1;
  margin-bottom: 6vw;

  span.big {
    font-size: 8vw;
  }

  span.medium {
    font-size: 6vw;
  }

  span.small {
    font-size: 5.6vw;
  }
`;

export const Location = styled.div`
  font-size: 4vw;
  ${notoSans};
  font-weight: 900;
  text-align: center;
  padding: 1.2vw 5vw 1.4vw;
  width: fit-content;
  height: fit-content;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin: auto;
  line-height: 1;
`;

export const ArrowDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vw 0 7vw;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const ColumnDivider = styled.div`
  width: 1px;
  background: white;
  margin: 0 2.5vw;
`;

export const RowDivider = styled.div`
  height: 1px;
  width: 100%;
  background: white;
  margin: 3vw 0;
`;

export const LargeFont = styled.div`
  width: 47%;
  height: 7vw;
  font-size: 3vw;
  ${notoSans};
  line-height: 1.25;
  white-space: nowrap;
  margin-bottom: 2vw;
`;

export const MediumFont = styled.div`
  width: 47%;
  height: 100%;
  font-size: 1.9vw;
  // ${notoSans};
  ${notoSerif};
  line-height: 1.7;
  text-align: left;
`;
