export const station = {
  bg: "/images/map/station/background.png",
  marks: "/images/map/station/marks.png",
  icons: ["/images/map/icon-army.png", "/images/map/icon-demonstrator.png"],
  title: "광주역 사건 상황",
  fixedAssets: ["/images/map/station/legend.png"],
  scrollLayers: [
    {
      isEmpty: true,
    },
    {
      index: { number: 1, color: "#0d347f" },
      datetime: "1980. 5. 20. 18:00~20:00",
      texts: [
        <>
          18:00경 양동시장 일원에 배치되어 있던 15대대 여단장 지시에 따라
          광주역으로 이동
        </>,
        <>
          19:00경 광주시청에서 기동타격대 임무를 수행하던 12대대는 광주KBS방송국
          보호를 위해 광주역으로 이동
        </>,
        <>
          20:00경 광주역 앞에서 5갈래의 방사형 도로에 바리케이트를 치고 최루탄을
          쏘며 시위대와 대치, 밀고 밀리는 공방전 벌임
        </>,
        <>
          같은 시각 시위대는 드럼통에 휘발유를 넣어 불을 붙여 굴려 보내고, 버스,
          트럭 등 차량 돌진으로 계엄군 저지선 공격
        </>,
        <>(서울중앙지검 수사결과보고 1995. 7.)</>,
      ],
      assets: [
        {
          src: "/images/map/station/details/1-1/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/1-1/2.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/1-1/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/8.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/1-1/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/11.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/1-1/12.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/13.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/14.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/15.png",
          animation: "spreading",
        },
        {
          src: "/images/map/station/details/1-1/16.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/17.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-1/18.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 1, color: "#0d347f" },
      datetime: "1980. 5. 20. 21:20",
      texts: [
        <>① 16대대 지역 투석 중이던 시위대 4명 체포.</>,
        <>
          ② 광주역 인근 KBS 앞에서 2,000여 명의 시위대 운집해 계엄군과 대치함.
        </>,
        <>
          ③ 광주고속터미널에서 광주역 방향으로 횃불 든 시위대 2,000여명 행진.
        </>,
        <>(전교사 전투사령부 상황일지 1980. 5.)</>,
        <>
          22:00경 시위대의 트럭에 하사관 3명 중상, 일부 대대장 권총으로
          차량바퀴에 사격하여 차 량을 정지시킴
        </>,
        <>(서울중앙지검 수사결과보고 1995. 7.)</>,
      ],
      assets: [
        {
          src: "/images/map/station/details/1-2/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/1-2/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-2/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-2/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-2/5.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/1-2/6.png",
          animation: "blinking-slow",
        },
        {
          src: "/images/map/station/details/1-2/7.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/1-2/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-2/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-2/10.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/1-2/11.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-2/12.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/1-2/13.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/1-2/14.png",
          animation: "",
        },
      ],
    },
    {
      index: { number: 1, color: "#0d347f" },
      datetime: "1980. 5. 20.",
      texts: [
        <>
          광주역 일원의 시위현장에서 체포되거나 상가 수색 과정에서 끌려나온
          민간인의 손을 뒤로 묶은 채 집단 구타, 대검에 의한 살상 등의 가혹행위
          계속됨.
          <br />
          (계엄군 다수의 증언, 피해자 진료 기록으로 확인)
        </>,
        // <>12대대 본부 중사 김귀삼 증언</>,
        // <>
        //   "끌려와서 반 초죽음이 되어 있는데 워커발로 차버리고 몽둥이질하고
        //   폭행이 그치지 않자 시범 케이스로 희생타를 만들 생각으로 그래서 그때
        //   M16에다가 저희가 대검을 차고 다녔었으니까 전우들이 폭행하는 것을
        //   중단시켜볼 생각으로 그 사람의 허리 밑을 제가 찌른 것 같아. 그 느낌이
        //   지금도 있어요. 그냥, 누워있었고 총의 무게 때문에 그런지 칼이 들어가는
        //   느낌, 그리고 나서 그 양반이 피가 엄청 낭자했거든."
        // </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/1-3/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/1-3/2.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 2, color: "#3b9f37" },
      datetime: "1980. 5. 20. 22:00~22:30",
      texts: [
        <>
          22:00경 최세창 여단장 제3공수여단 모든 병력 광주역 집결 후 전남대로
          철수 지시
          <br />
          22:30경 신탁은행 공터에 있던 11대대는 공용터미널로 진출하여
          시위진압하다가 광주시청으로 이동한 13대대와 합류하여 광주역으로 이동
          <br />
          22:30경 시위대의 차량공격에 위협을 느낀 대대장들이 실탄지급 요청
          <br />
          22:30경 최세창 여단장 지시로 전남대에서 20명 지원조 편성하여 광주역에
          있는 대대에 경계용 실탄 지급
          <br />
          (서울중앙지검 수사결과보고 1995. 7.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/2/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/2/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/11.png",
          animation: "vibrating-ru",
        },
        {
          src: "/images/map/station/details/2/12.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/2/13.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/2/14.png",
          animation: "vibrating-lu",
        },
        {
          src: "/images/map/station/details/2/15.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/16.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/17.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/18.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/19.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/20.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/2/21.png",
          // 소총
          animation: "trembling1",
        },
        {
          src: "/images/map/station/details/2/22.png",
          animation: "",
        },
      ],
    },
    {
      index: { number: 3, color: "#dd5c32" },
      datetime: "1980. 5. 20. 23:00",
      texts: [
        <>
          18:30경 시내 작전부대 저녁식사 보급을 위해 전남대에서 시내로 가던 중
          신안동 굴다리 부근에서 시위대에 의해 고립
          <br />
          전남대 잔류하고 있던 16대대 출동하여 시위대 진압
          <br />
          20:00경 고속도로 방향에서 광주역으로 향하는 차량 100여 대의 시위대와
          충돌 후 진압하여 시위대 차량 버리고 분산 도주
          <br />
          22:00경 시위대의 11톤 트럭 광주역 방향에서 돌진해 오다가 방향을 틀면서
          전복되었고, 이 과정에서 정관철 중사가 차량에 치여 현장에서 사망
          <br />
          (서울중앙지검 수사결과보고 1995. 7.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/3/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/3/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/3/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/3/4.png",
          // 충돌
          animation: "vibrating-rd",
        },
        {
          src: "/images/map/station/details/3/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/3/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/3/7.png",
          // 충돌
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/3/8.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 4, color: "#3165a8" },
      datetime: "1980. 5. 20. 22:00",
      texts: [
        <>① 13대대는 광주 시청에서 시위대 1천 명과 대치.</>,
        <>② 15대대는 KBS 확보, 도로봉쇄 및 12대대와 합류, 2만 여 명과 대치.</>,
        <>③ 16대대는 전남대 입구 차량 100대 및 시위대와 대치.</>,
        <>
          ④ 광주역 광장에 여단장이 현장 지휘(현장에서 여단장 권총 3발을 공중에
          대고 발사하였으며, 시간은 특정되지 않음).
        </>,
        <>
          ⑤ 광주 고속버스터미널과 KBS 앞에서 시위대가 광주역으로 행진하는 과정에
          총성/시위대의 접근을 막기 위해 공포탄을 발사함.
        </>,
        <>
          ⑥ 광주역에서 시위대 트럭에 하사관 3명이 깔려 중상을 입자 일부 대대장은
          권총을 차량 바퀴 등에 쏘아 돌진 차량을 정지시키고 운전자 등 시위대를
          체포함.
        </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/4/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/2.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/4/3.png",
          animation: "blinking-slow",
        },
        {
          src: "/images/map/station/details/4/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/5.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/4/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/8.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/4/9.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/4/10.png",
          animation: "vibrating-rd",
        },
        {
          src: "/images/map/station/details/4/11.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/12.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/13.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/14.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/15.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/16.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/17.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/4/18.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/19.png",
          animation: "vibrating-lu",
        },
        {
          src: "/images/map/station/details/4/20.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/4/21.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/22.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/23.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/24.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/4/25.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/4/26.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/station/details/4/27.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/4/28.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/station/details/4/29.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/4/30.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/4/31.png",
          animation: "trembling3",
        },
        {
          src: "/images/map/station/details/4/32.png",
          animation: "",
        },
      ],
    },
    {
      index: { number: 5, color: "#703e8c" },
      icons: ["/images/map/icon-army.png"],
      datetime: "1980. 5. 20. 시간미상",
      texts: [
        <>
          광주시청 옥상, 광주 KBS 옥상에 M60 설치하여 예광탄 발사했다는 계엄군
          다수의 증언은 있으나 공식기록은 확인되지 않음
        </>,

        <>
          <b>12대대 본부 중사 김귀삼 증언</b>
          <br />
          광주 신역 빌딩에 M60 기관총을 설치하여 위협사격 및 분수대 오거리 우측
          건물 옥상에 M60 기관총 예광탄으로 위협사격을 하는 것을 목격함.
        </>,
        <>
          <b>12대대 하사 김승식 증언</b>
          <br />
          시청에서 방송국이 불타고 있을 때 M16, M60 기관총 소리가 들렸음.
        </>,
        <>
          <b>13대대 하사 곽석기 증언</b>
          <br />
          광주역 방향에서 기관총과 소총 연발 소리(기관총 및 소총 소리는 구분할
          수 있음)가 많이 들렸고, 분수대를 향해 돌진하는 시위대 차량에 사격하는
          것을 목격했음.
        </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/5/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/5/2.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/station/details/5/3.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/station/details/5/4.png",
          animation: "trembling3",
        },
      ],
    },
    {
      index: { number: 6, color: "#904d23" },
      datetime: "1980. 5. 20. 22:30",
      texts: [
        <>
          실탄지원조 시위대와 충돌하여 광주역 진출이 막히자 권총과 M16으로
          공포를 발사하고, 차량에 거치된 M60기관총으로 위협사격을 하여 시위대
          해산 후 광주역 일원의 대대에 실탄지급
          <br />
          (서울중앙지검 수사결과보고 1995. 7.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/6-1/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-1/2.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-1/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/5.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-1/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/8.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/6-1/9.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/6-1/10.png",
          animation: "vibrating-rd",
        },
        {
          src: "/images/map/station/details/6-1/11.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/12.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/13.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/14.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/15.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/16.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-1/17.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/station/details/6-1/18.png",
          animation: "",
        },
      ],
    },
    {
      index: { number: 6, color: "#904d23" },
      datetime: "1980. 5. 20. 23:00",
      texts: [
        <>
          전남대 쪽과 광주KBS 방송국 앞에서 시위대 차량공격을 저지하기 위해
          장교들이 권총, M16 발포와 E-8발사기 발포하여 시위대 해산시킴
          <br />
          (서울중앙지검 수사결과보고 1995. 7.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/6-2/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-2/2.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-2/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/4.png",
          animation: "vibrating-ru",
        },
        {
          src: "/images/map/station/details/6-2/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/6.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/station/details/6-2/7.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-2/8.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-2/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/11.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/6-2/12.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/station/details/6-2/13.png",
          animation: "vibrating-rd",
        },
        {
          src: "/images/map/station/details/6-2/14.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/15.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/16.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/17.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/18.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/19.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/20.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/station/details/6-2/21.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-2/22.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/23.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-2/24.png",
          animation: "trembling3",
        },
        {
          src: "/images/map/station/details/6-2/25.png",
          animation: "",
        },
      ],
    },
    {
      index: { number: 6, color: "#904d23" },
      datetime: "1980. 5. 20. 23:15",
      texts: [
        <>
          <br />① 11대대는 공영터미널에서 10,000여 명 시위 군중과 대치함.
          <br />② 12대대는 광주역에서 5,000여 명과 대치함.
          <br />③ 13대대는 광주 시청 앞에서 5,000여 명과 대치함.
          <br />④ 15대대는 광주 KBS 앞에서 3,000여 명과 대치함.
          <br />⑤ 16대대는 전남대 입구(신안동 사거리)에서 2,000여 명과 대치함.
          <br />
          (특전사 전투상보 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/6-3/1.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-3/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/3.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/station/details/6-3/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/5.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-3/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/7.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/station/details/6-3/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/9.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-3/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/11.png",
          animation: "trembling3",
        },
        {
          src: "/images/map/station/details/6-3/12.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/13.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-3/14.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/15.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/station/details/6-3/16.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/17.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/6-3/18.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/6-3/19.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/station/details/6-3/20.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 7, color: "#5b692e" },
      datetime: "1980. 5. 21. 00:30~02:10",
      texts: [
        <>
          5. 21. 02:00경 발포로 시위대 돌진 차량 저지하고 시위대 해산시킨 다음
          광주KBS 방송국 에 있던 31사단 병력(4/35)과 함께 전남대로 철수
          <br />
          (서울중앙지검 수사결과보고서 1995. 7.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/station/details/7/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/7/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/7/3.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/7/4.png",
          animation: "blinking-slow",
        },
        {
          src: "/images/map/station/details/7/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/7/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/7/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/7/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/7/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/7/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/station/details/7/11.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/station/details/7/12.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/7/13.png",
          animation: "",
        },
        {
          src: "/images/map/station/details/7/14.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      isEmpty: true,
    },
  ],
};

export const university = {
  bg: "/images/map/university/background.png",
  marks: "/images/map/university/marks.png",
  icons: ["/images/map/icon-army.png", "/images/map/icon-demonstrator.png"],
  title: "전남대 사건 상황",
  fixedAssets: ["/images/map/university/legend.png"],
  scrollLayers: [
    {
      isEmpty: true,
    },
    {
      index: { number: 1, color: "#c81c64" },
      datetime: "1980. 5. 21. 02:20",
      texts: [
        <>
          3공수여단 병력 전남대로 복귀하기 위해 탈출 시도.
          <br />
          앞을 가로막는 시위대를 돌파하기 위해 방망이 구타.
          <br />
          (사망 1명, 중상 3명, 경상 1명 발생)
          <br />
          3공수 철수 시 무장 시위대 40명 체포 신병확보.
          <br />
          (전교사 상황일지 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/1-1/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-1/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-1/3.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/university/details/1-1/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-1/5.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 1, color: "#c81c64" },
      datetime: "1980. 5. 21. 08:00",
      texts: [
        <>
          광주 전역에 '진돗개 하나'발령.
          <br />
          (육군본부 상황일지 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/1-2/1.png",
          animation: "blinking-slow",
        },
        {
          src: "/images/map/university/details/1-2/2.png",
          animation: "",
        },
      ],
    },
    {
      index: { number: 1, color: "#c81c64" },
      datetime: "1980. 5. 21. 09:00",
      texts: [
        <>
          5. 21. 09:00경부터 시위대 모여들기 시작하여 10:00경 정문에 약 4만여명,
          후문에 약 1만 명 운집하여 투석 및 장갑차 등의 차량 공격으로 정문 돌파
          시도
          <br />
          (특전사 전투상보 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/1-3/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-3/2.png",
          // 화살표
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-3/3.png",
          // 빨간점
          animation: "blinking-slow",
        },
        {
          src: "/images/map/university/details/1-3/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-3/5.png",
          // 화살표
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-3/6.png",
          // 빨간점
          animation: "blinking-slow",
        },
      ],
    },
    {
      index: { number: 1, color: "#c81c64" },
      datetime: "1980. 5. 21. 10:00",
      texts: [
        <>
          10:00경 정문 쪽 시위대 차량 공격이 격화되어 500MD, M5가스 살포기로
          시위대 진압 시 도하였으나 정문이 돌파되어 시위대 정문 안으로 300미터
          가량 진입
          <br />
          (특전사 전투상보 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/1-4/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-4/2.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/university/details/1-4/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-4/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-4/5.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/university/details/1-4/6.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 1, color: "#c81c64" },
      datetime: "1980. 5. 21. 11:00",
      texts: [
        <>
          기동예비대 1개 대대는 시위대 선두를 공격하고, 2개 대대는 시위대 좌,
          우측의 측면을 강력하게 타격하여 시위대 해산
          <br />
          (특전사 전투상보 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/1-5/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-5/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-5/3.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/university/details/1-5/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-5/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-5/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-5/7.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/university/details/1-5/8.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 1, color: "#c81c64" },
      datetime: "1980. 5. 21. 11:50~12:00",
      texts: [
        <>
          시위대 300여 명은 전남대 정문을 돌파하여 안으로 진입하였으나 계엄군에
          의해 밀려남. (시위대 선두의 고등학생 총격으로 사망-이성귀)
        </>,
        <>
          전남대 정문 앞에 있던 시위대 선두에 있던 장갑차가 돌진하다가 하천으로
          떨어졌고, 이 장갑차 안에 있던 시위대를 끌어내 집단구타 후 전남대
          안으로 연행함.
          <br />
          (전교사 전투상보 1980. 5. 계엄군 현장 증언 2022. 10.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/1-6/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-6/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-6/3.png",
          animation: "trembling3",
        },
        {
          src: "/images/map/university/details/1-6/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-6/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/1-6/6.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/university/details/1-6/7.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 2, color: "#b49628" },
      datetime: "1980. 5. 21. 12:30",
      texts: [
        <>
          시위대 선두를 공격한 15대대는 정문에서 신안동 굴다리 근처까지 시위대를
          공격하였고, 신안동 굴다리까지 시위대를 해산시키는 과정에서 계엄군
          발포에 의해 최미애 임산부 등 사망
          <br />
          (특전사 전투상보 1980. 5. 계엄군 현장 증언 2022. 10.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/2-1/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-1/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-1/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-1/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-1/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-1/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-1/7.png",
          animation: "blinking-slow",
        },
        {
          src: "/images/map/university/details/2-1/8.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 2, color: "#b49628" },
      datetime: "1980. 5. 21. 13:00",
      texts: [
        <>피해자, 계엄군 다수의 증언이 있으나 공식기록은 확인되지 않음.</>,
        <>
          ① 신안동 사거리 일원 민가수색.
          <br />② 경찰 페퍼포그 탑승자 구타, 대형버스 차량에 총격, 운전자 구타
          후 연행.
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/2-2/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-2/2.png",
          animation: "",
        },
        {
          src: "/images/map/university/details/2-2/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-2/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-2/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-2/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-2/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-2/8.png",
          animation: "",
        },
        {
          src: "/images/map/university/details/2-2/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-2/10.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/university/details/2-2/11.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/university/details/2-2/12.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 2, color: "#b49628" },
      datetime: "1980. 5. 21. 13:40",
      texts: [
        <>
          3공수 여단장의 요청에 따라, 전남대 운집 시위 군중을 해산시키기 위해
          가스 살포용 500MD를 2대 출동시킴.
          <br />
          (특전사 전투상보 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/2-3/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-3/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-3/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-3/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/2-3/5.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/university/details/2-3/6.png",
          animation: "trembling2",
        },
      ],
    },
    {
      index: { number: 3, color: "#CE813D" },
      datetime: "1980. 5. 21. 전남대 가(암)매장",
      texts: [
        <>
          <b>이학부 뒷산</b>
          <br />
          이성귀 고교생 시체 수습
          <br />
          야간 추가 매장 사실 확인 중.
        </>,
        <>
          <b>대학본부 뒷산</b>
          <br />
          시내 연행자 중 사망자 매장 추정.
        </>,
        <>
          <b>정문 좌측 야산</b>
          <br />
          정문 돌파 이후 진입 과정.
          <br />
          사망자 매장 추정.
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/5/1.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 4, color: "#2d9b76" },
      datetime: "1980. 5. 21. 15:00",
      texts: [
        <>
          피해자와 현장에 있었던 계엄군의 다수 증언은 있으나 공식기록은 확인되지
          않음.
        </>,
        <>
          <b>여단 본부 통신병 방온식 증언</b>
          <br />
          "이학부 건물 2층에 체포된 시위대 200여 명이 런닝셔츠, 팬티, 교련복을
          착용하고 꿇어앉아 있었고, 보안대 요원이 취조했음."
        </>,
        <>
          "머리를 빡빡 삭발한 고등학생 2명이 죽어있었고, 머리 등을 맞아 부상당한
          사람들이 많아 보였음."
        </>,
        <>
          <b>여단 본부 경비소대 병장 최명용 증언</b>
          <br />
          "강의실에 수용된 포로는 강일석 상사가 앉아있는 강의실 앞쪽과 뒤쪽,
          앞과 뒤를 오갈 수 있는 통로를 제외하고 강의실 가운데 쪽에 200여 명
          정도가 가득 차 있었음."
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/3/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/3/2.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 5, color: "#581F98" },
      datetime: "1980. 5. 21. 16:30",
      texts: [
        <>
          전남대에서 광주교도소로 호송하는 과정에서 사방이 밀폐된 차에 많은 수의
          인원을 태운 후 최루탄을 던져 넣어 다수의 화상환자가 생겼고, 후송 후
          공수부대에 의해 심한 구타가 있었 던 것으로 판단됨
          <br />
          (서울중앙지검, 광주상황 쟁점별 정리 1995. 7.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/university/details/4/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/4/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/4/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/4/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/university/details/4/5.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      isEmpty: true,
    },
  ],
};

export const prison = {
  bg: "/images/map/prison/background.png",
  marks: "/images/map/prison/marks.png",
  icons: ["/images/map/icon-army.png", "/images/map/icon-demonstrator.png"],
  title: "광주교도소 사건 상황",
  fixedAssets: ["/images/map/prison/legend.png"],

  scrollLayers: [
    {
      isEmpty: true,
    },
    {
      index: { number: 1, color: "#b23832" },
      datetime: "1980. 5. 21. 16:40",
      texts: [
        <>
          광주교도소로 이동하는 계엄군을 향해 무장 시위대 차량이 질주하면서
          총격을 가함.
          <br />
          도로변 논바닥 등에 엎드려 응사하며 상호 교전 발생.
          <br />이 상황으로 제3공수여단 계엄군들은 교도소 주둔 시 항상 시위대의
          사격에 위협을 느끼게 되었음.
          <br />
          (계엄군 다수의 증언)
        </>,
        <>
          전남대에서 광주교도소로 이동 중 폭도의 총격에 의해 3공수여단 15대대장
          무전병 1명 팔 관통상 당함.
          <br />
          (전교사 상황일지 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/1-1/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/1-1/2.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/1-1/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/1-1/4.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/1-1/5.png",
          animation: "vibrating-rd",
        },
        {
          src: "/images/map/prison/details/1-1/6.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/1-1/7.png",
          // animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 1, color: "#b23832" },
      datetime: "1980. 5. 21. 17:00",
      texts: [
        <>피해자와 계엄군 다수의 증언은 있으나 공식 기록은 확인되지 않음.</>,
        <>
          당시 교도소로 이송해온 시위대들 포함한 시신을 밭 같은 곳에 조그마한
          나무가 있는 고랑에 모아 두었다가 본인과 영선반 인원 4~5명과 시신
          10여구 이상을 직접 매장했음.
        </>,
        <>
          전남대에서 시위대를 싣고 온 트럭(2 ½톤)에서 대대 정보장교 통제하에
          중대 동료들과 함께 시신 12구와 중상자들을 하차시켰음.
        </>,
        <>
          시신을 내릴 때 윤행근 중사가 호루(덮개) 밖에서 안에 타고 있던 시위대를
          대검으로 찌르는 등 비인간적인 행동을 하였음.
        </>,
        <>시신의 사진을 찍었고 차량정비고와 교도소 담장 사이에 모아뒀음.</>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/1-2/1.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/1-2/2.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/1-2/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/1-2/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/1-2/5.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 2, color: "#89bf47" },
      datetime: "1980. 5. 21.",
      texts: [
        <>피해자와 계엄군 다수의 증언은 있으나 공식 기록은 확인되지 않음.</>,
        <>
          당시 외역창고에 수용되었던 시위대는 200여 명 정도였고 수용환경이 좋지
          않아 똥, 오물을 그대로 방치하는 등 인간적인 취급을 하지 않았음.
          (여단장 정보보좌관 대위 최명용)
        </>,
        <>
          당시 광주 제 1전투비행단의 공군 방위였던 김병준은 전남대에서 붙잡혀
          광주교도소로 이송되었으며, 피해자들 중 가장 극심한 구타와 가혹행위를
          당했음. 눈동자를 굴렸다는 이유로 계엄군에게 담뱃불로 눈을 지져버려 5년
          동안 고생함. (계엄군, 피해자 복수 증언)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/2/1.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/2/2.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/2/3.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 21. 19:20",
      texts: [
        <>
          고속도로를 차단하기 위한 바리게이트 작업 중 폭도가 고지 위에서 난사.
          구경하던 민간인 2명 사살, 2명 부상.
          <br />
          (특전사 전투상보 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-1/1.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/2.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/6.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-1/7.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/prison/details/3-1/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/11.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/12.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/13.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/14.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-1/15.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-1/16.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 21. 19:30",
      texts: [
        <>
          무장 시위대가 2½톤 10여 대를 타고 접근하자 12, 13대대 인원들이 집중
          사격함.(계엄군의 증언)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-2/1.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-2/2.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-2/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-2/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-2/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-2/6.png",
          animation: "vibrating-rd",
        },
        {
          src: "/images/map/prison/details/3-2/7.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-2/8.png",
          animation: "blinking-normal",
        },
      ],
    },
    // {
    //   index: { number: 3, color: "#4f9abd" },
    //   datetime: "1980. 5. 21. 20:00~20:40",
    //   texts: [
    //     <>
    //       고속도로 위 광주 방향으로 가던 민간인 트럭을 계엄군의 조준사격으로
    //       민간인 3명 사망하여 암매장함.
    //     </>,
    //   ],
    //   assets: [
    //     {
    //       src: "/images/map/prison/details/3-3/1.png",
    //       // animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/2.png",
    //       // animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/3.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/4.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/5.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/6.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/7.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/8.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/9.png",
    //       animation: "trembling1",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/10.png",
    //       animation: "vibrating-lu",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/11.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/12.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-3/13.png",
    //       animation: "blinking-normal",
    //     },
    //   ],
    // },
    // {
    //   index: { number: 3, color: "#4f9abd" },
    //   datetime: "1980. 5. 21. 20:30",
    //   texts: [
    //     <>
    //       각 부대 실탄 장전 지시함. 사격 시, "여기는 군인 배치되어 있다."고 경고
    //       후 가슴 하부에 조준토록 지시함.
    //       <br />
    //       2군 사령관 교도소 경계 강화 지시 하달함.
    //     </>,
    //   ],
    //   assets: [
    //     {
    //       src: "/images/map/prison/details/3-4/1.png",
    //       // animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-4/2.png",
    //       // animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-4/3.png",
    //       // animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-4/4.png",
    //       animation: "blinking-slow",
    //     },
    //   ],
    // },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 21. 21:00",
      texts: [
        <>
          구난차 운전수를 계엄군이 체포, 시민군 APC 공격으로 계엄군과 교전함.
          <br />
          (전교사 상황일지 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-5/1.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/2.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/8.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-5/9.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/prison/details/3-5/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/11.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-5/12.png",
          animation: "trembling3",
        },
      ],
    },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 22. 00:40~00:50",
      texts: [
        <>
          폭도가 사격을 가하면서 차량으로 접근, 바리케이트에 저지되자 계엄군이
          응사. (1명 사살, 1명 체포)
          <br />
          (특전사 전투상보, 전교사 상황일지 1980. 5.)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-6/1.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-6/2.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-6/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-6/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-6/5.png",
          animation: "vibrating-lu",
        },
        {
          src: "/images/map/prison/details/3-6/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-6/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-6/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-6/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-6/10.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-6/11.png",
          animation: "trembling1",
        },
      ],
    },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 22. 08:55",
      texts: [
        <>
          시민군이 탑승한 군용트럭 1대(3명 탑승)가 진입로에서 정문 방향으로 중간
          정도 진입 시 개인 호를 구축 중이던 계엄군이 사격하여 운전수 사망. (3명
          모두 죽었을 것임)
          <br />
          (계엄군의 증언)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-7/1.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-7/2.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-7/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-7/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-7/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-7/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-7/7.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/prison/details/3-7/8.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-7/9.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 22. 10:00경",
      texts: [
        <>
          시위대가 몰고 온 5톤 레커차, 2 ½톤 트럭 등 5대 정도의 차량에 LMG를
          장착하고 와서 계엄군이 응사함. (사살된 시신 2구를 밭에 매장함)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-8/1.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-8/2.png",
          // animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-8/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-8/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-8/5.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/prison/details/3-8/6.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-8/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-8/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-8/9.png",
          animation: "trembling3",
        },
        {
          src: "/images/map/prison/details/3-8/10.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 22. 12:00경",
      texts: [
        <>
          1톤 화물트럭이 담양 방면으로 간다는 연락을 받고 계엄군이 사격하자
          바리게이트를 돌아 나와 광주방향으로 도망감. 무전을 들어보니 폭도가
          아닌 민간인 차량이었고, 어린애가 다친 것으로 알고 있음.
        </>,
        <>
          시위대 차량 8~12대가 교도소로 접근하여 20여 분간 계엄군과 총격전.
          (4~6명 사망)
        </>,
        <>(계엄군 다수의 증언은 있으나 공식 기록은 확인되지 않음)</>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-9/1.png",
        },
        {
          src: "/images/map/prison/details/3-9/2.png",
        },
        {
          src: "/images/map/prison/details/3-9/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-9/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-9/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-9/6.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-9/7.png",
        },
        {
          src: "/images/map/prison/details/3-9/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-9/9.png",
        },
        {
          src: "/images/map/prison/details/3-9/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-9/11.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-9/12.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-9/13.png",
          animation: "trembling1",
        },
      ],
    },
    // {
    //   index: { number: 3, color: "#4f9abd" },
    //   datetime: "1980. 5. 22. 시간미상",
    //   texts: [
    //     <>
    //       고속도로를 지나가는 신혼부부에게 12대대가 기관총을 난사하여
    //       희생당했다는 말을 들었음.
    //     </>,
    //   ],
    //   assets: [
    //     {
    //       src: "/images/map/prison/details/3-10/1.png",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-10/2.png",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-10/3.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-10/4.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-10/5.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-10/6.png",
    //       animation: "blinking-normal",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-10/7.png",
    //       animation: "trembling1",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-10/8.png",
    //       animation: "vibrating-ru",
    //     },
    //     {
    //       src: "/images/map/prison/details/3-10/9.png",
    //       animation: "blinking-normal",
    //     },
    //   ],
    // },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 22. 시간미상",
      texts: [
        <>
          13대대가 고속도로 매복 후 복귀 시 사살한 시위대를 차량에 태워 오는
          것을 목격함.
        </>,
        <>13대대가 시민군과 교전 후 시신 3~4구를 교도소로 가져왔다고 들었음.</>,
        <>(계엄군 다수의 증언은 있으나 공식 기록은 확인되지 않음)</>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-11/1.png",
        },
        {
          src: "/images/map/prison/details/3-11/2.png",
        },
        {
          src: "/images/map/prison/details/3-11/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-11/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-11/5.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-11/6.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/prison/details/3-11/7.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-11/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-11/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-11/10.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-11/11.png",
          animation: "trembling1",
        },
      ],
    },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 23. 10:20",
      texts: [
        <>
          11대대 지역에서 소방차에 탑승하여 공격해오는 폭도 4명에 대해 12대대가
          공격하여 4명 생포함.
        </>,
        <>(계엄군 다수의 증언은 있으나 공식 기록은 확인되지 않음)</>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-12/1.png",
        },
        {
          src: "/images/map/prison/details/3-12/2.png",
        },
        {
          src: "/images/map/prison/details/3-12/3.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-12/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-12/5.png",
          animation: "vibrating-ru",
        },
        {
          src: "/images/map/prison/details/3-12/6.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-12/7.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 3, color: "#4f9abd" },
      datetime: "1980. 5. 23. 19:00~19:40",
      texts: [
        <>
          계엄군이 추진진지에서 철수 중 주유소 삼거리에서 2½톤 1대에 폭도 43명이
          탑승해 CAR 난사하며 접근. (1명 사살, 1명 생포)
        </>,
        <>
          무장 시위대가 2½톤 10여 대를 타고 접근하자 12, 13대대에 인원들이 집중
          사격함. (30~60여 명 사망)
        </>,
        <>(계엄군 다수의 증언은 있으나 공식 기록은 확인되지 않음)</>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/3-13/1.png",
        },
        {
          src: "/images/map/prison/details/3-13/2.png",
        },
        {
          src: "/images/map/prison/details/3-13/3.png",
        },
        {
          src: "/images/map/prison/details/3-13/4.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-13/5.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-13/6.png",
          animation: "trembling1",
        },
        {
          src: "/images/map/prison/details/3-13/7.png",
          animation: "vibrating-ru",
        },
        {
          src: "/images/map/prison/details/3-13/8.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-13/9.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-13/10.png",
          animation: "vibrating-ld",
        },
        {
          src: "/images/map/prison/details/3-13/11.png",
          animation: "trembling2",
        },
        {
          src: "/images/map/prison/details/3-13/12.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/3-13/13.png",
        },
        {
          src: "/images/map/prison/details/3-13/14.png",
          animation: "trembling1",
        },
      ],
    },
    {
      index: { number: 4, color: "#b23894", isFirst: true },
      datetime: "가(암)매장 장소",
      texts: [
        <>여단 본부 중사 김OO 증언</>,
        <>
          경비소대 이상덕 중사가 시신을 실은 리어카를 인솔하여 병사들과 함께
          1~2감시대 출입문(당시 개방)으로 나가 매장하러 가는 모습을 3~4회 직접
          목격했음.
        </>,
        <>
          1~2일 차 주간에 각 지역대 지휘소까지 야전선을 가설하고 TA-312를
          설치하던 중 3감시대 일대에서 야전선을 잡아당겼는데, 끌려오지 않아 확인
          시 야전선 위에 시신이 매장되어 있는 것을 목격함.
        </>,
        <>
          1~2일 차 주간에 교도소 내부 담벼락으로 야전선을 가설하던 중, 정문으로
          들어가 좌측(9시 방향) 담벼락 아래 화단(50~60CM 사철나무 혹은 측백나무
          같은 수종이 정렬되어 식재되어 있었음)에서 시신이 있는 것을 목격하고
          치우라고 했는데, 이후 어떻게 처리했는지는 모름.
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/4-1/1.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 4, color: "#b23894", isFirst: true },
      alphabet: { text: "A", color: "#d23633" },
      datetime: "가(암)매장 장소",
      texts: [
        <>
          동문 건너편 '미나리깡'부지
          <br />
          윤석기 / 보안반장
          <br />
          5.21 - 매장목격
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/4-2/1.png",
        },
        {
          src: "/images/map/prison/details/4-2/2.png",
          animation: "blinking-normal",
        },
      ],
      exceptionIcons: [{ src: '"/images/map/prison/details/4-2/a.png' }],
    },
    {
      index: { number: 4, color: "#b23894", isFirst: true },
      alphabet: { text: "B", color: "#d23633" },
      datetime: "가(암)매장 장소",
      texts: [
        <>
          광주교도소 뒤편 공동묘지
          <br />
          정보보좌관 대위 최명용
          <br />
          지원된 병사들과 함께 12구 정도 직접 매장함.
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/4-3/1.png",
        },
        {
          src: "/images/map/prison/details/4-3/2.png",
          animation: "blinking-normal",
        },
      ],
      exceptionIcons: [{ src: '"/images/map/prison/details/4-2/b.png' }],
    },
    {
      index: { number: 4, color: "#b23894", isFirst: true },
      alphabet: { text: "C", color: "#d23633" },
      datetime: "가(암)매장 장소",
      texts: [
        <>
          공동묘지 일원
          <br />
          여단 본부대장 소령 김승부
          <br />
          5. 23. 저녁 시신 11구 매장
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/4-4/1.png",
        },
        {
          src: "/images/map/prison/details/4-4/2.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 4, color: "#b23894", isFirst: true },
      alphabet: { text: "D", color: "#d23633" },
      datetime: "가(암)매장 장소",
      texts: [
        <>
          3감시대 일원
          <br />
          여단 군수처 중사 윤춘기
          <br />
          5. 22. 주간 3감시대 부근 5구 매장 목격
        </>,
        <>
          15대대 중사 박재만
          <br />
          5. 22. 주간 3감시대 울타리 내부 5~6구 매장 목격
        </>,
        <>
          여단 본부 통신대 중사 김OO
          <br />
          5. 22. 주간 3감시대 부근 3~4구 매장 목격
        </>,
        <>
          김종원 / 15대대
          <br />
          일자 불상 야간 3감시대 부근 3~4구 매장 목격
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/4-5/1.png",
        },
        {
          src: "/images/map/prison/details/4-5/2.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 4, color: "#b23894", isFirst: true },
      alphabet: { text: "E", color: "#d23633" },
      datetime: "가(암)매장 장소",
      texts: [
        <>
          오수 처리장(1990년 공사)
          <br />
          여단 본부 군수처 영선반장 상사 홍봉칠
          <br />
          병사들과 가마니에 싸서 묘목장에 10구 이상 매장
        </>,
        <>
          여단 본부 인사처 병장 유종경
          <br />
          5. 21. 저녁 동료들과 시신 9구를 가마니에 싸서 오수 처리장 부근에 갔다
          놓았고, 누군가가 그곳에 매장하였음.
        </>,
        <>
          15대대 하사 김연철
          <br />
          일자 불상 4감시대 우측에 7~8구 매장 목격
        </>,
        <>
          15대대 중사 김용하
          <br />밭 이랑 사이 5~6구 매장 목격
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/4-6/1.png",
        },
        {
          src: "/images/map/prison/details/4-6/2.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 4, color: "#b23894", isFirst: true },
      alphabet: { text: "F", color: "#d23633" },
      datetime: "가(암)매장 장소",
      texts: [
        <>
          교도소장 관사
          <br />
          여단 본부 취사병 이상래
          <br />
          5. 22. 새벽 동료 4~5명과 정비소 앞
          <br />
          소나무 숲에 가마니로 싼 시신 5구를
          <br />
          리어카로 이동해 매장함.
        </>,
        <>
          여단 본부 상사 김석송, 12대대 중사 임영규
          <br />
          5. 22. 소장 관사 뒤 울타리에 10구의 시신을 매장하는 것을 목격함.
        </>,
        <>
          광주시청 직원 조성갑
          <br />
          5. 29. 시신 5구를 발굴함.
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/4-7/1.png",
        },
        {
          src: "/images/map/prison/details/4-7/2.png",
          animation: "blinking-normal",
        },
      ],
    },
    {
      index: { number: 4, color: "#b23894", isFirst: true },
      datetime: "실제 가(암)매장 장소",
      texts: [
        <>
          1. 고규석
          <br />
          2. 민병열
          <br />
          3. 서만오
          <br />
          4. 서종덕
          <br />
          5. 안병섭
          <br />
          6. 이명진
          <br />
          7. 이용충
          <br />
          8. 임은택
          <br />
          9. 장방환
          <br />
          10. 최열락
          <br />
          11. 허봉. 또는 조일기(위원회 확인 중)
        </>,
      ],
      assets: [
        {
          src: "/images/map/prison/details/4-8/1.png",
          animation: "blinking-normal",
        },
        {
          src: "/images/map/prison/details/4-8/2.png",
          // animation: "blinking-normal",
        },
      ],
    },
    {
      isEmpty: true,
    },
  ],
};
