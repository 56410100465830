import styled from "styled-components";

export const Container = styled.div`
  z-index: 1;
  min-width: 7vw;
  width: max-content;
  background-color: black;
  position: absolute;
  top: 2.2vw;
  left: -0.5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  max-height: ${({ isShow }) => (isShow ? "unset" : 0)};
  overflow-y: hidden;
  font-family: "Noto Sans KR", sans-serif;
  opacity: 0.9;
  font-size: max(1vw, 12px);
  border-radius: 8px;
  border: ${({ isShow }) =>
    isShow ? "1px solid rgba(255, 255, 255, 0.9)" : "none"};
`;

export const Content = styled.div`
  padding: 8px;
  font-weight: 400;
  :first-of-type {
    padding-top: 10px;
  }

  &:hover {
    font-weight: 700;
  }
  z-index: 100;
`;
