import { keyframes } from "styled-components";

/** section1 **/
export const prison_section_1_image_1_keyframe = null;
export const prison_section_1_image_1_animation = null;

export const prison_section_1_image_2_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const prison_section_1_image_2_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_1_image_3_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const prison_section_1_image_3_animation = {
  animationDuration: "3.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_1_image_4_keyframe =
  prison_section_1_image_2_keyframe;
export const prison_section_1_image_4_animation =
  prison_section_1_image_2_animation;

export const prison_section_1_image_5_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const prison_section_1_image_5_animation = {
  animationDuration: "5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_1_image_6_keyframe = null;
export const prison_section_1_image_6_animation = null;

export const prison_section_1_image_7_keyframe = keyframes`
  0% {
    transform: translateX(100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const prison_section_1_image_7_animation = {
  animationDuration: "7s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

/** prison_section2 **/
export const prison_section_2_image_1_keyframe = null;
export const prison_section_2_image_1_animation = null;

export const prison_section_2_image_2_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%) ;
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const prison_section_2_image_2_animation = {
  animationDuration: "1s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_2_image_3_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  } 
`;
export const prison_section_2_image_3_animation = {
  animationDuration: "1.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_2_image_4_keyframe = keyframes`
  0% {
    transform: translateX(100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const prison_section_2_image_4_animation = {
  animationDuration: "1.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_2_image_5_keyframe = keyframes`
  0% {
    transform: translateY(-100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const prison_section_2_image_5_animation = {
  animationDuration: "5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_2_image_6_keyframe = keyframes`
  0% {
    transform: translateX(100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const prison_section_2_image_6_animation = {
  animationDuration: "6s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_2_image_8_keyframe =
  prison_section_2_image_6_keyframe;
export const prison_section_2_image_8_animation =
  prison_section_2_image_6_animation;

export const prison_section_2_image_7_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  73% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  87% {
    opacity: 0;
  }
  100% {
   opacity: 1; 
  }
`;
export const prison_section_2_image_7_animation = {
  animationDuration: "9s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

/** prison_section3 **/
export const prison_section_3_image_1_keyframe = null;
export const prison_section_3_image_1_animation = null;

export const prison_section_3_image_2_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(200%); 
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const prison_section_3_image_2_animation = {
  animationDuration: "1.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_3_image_3_keyframe = keyframes`
  0% {
    opacity: 0;  
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const prison_section_3_image_3_animation = {
  animationDuration: "2s",
  animationDelay: "3.2s",
  animationDirection: "alternate",
  animationIterationCount: "infinite",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const prison_section_3_image_4_keyframe = null;
export const prison_section_3_image_4_animation = null;

export const prison_section_3_image_5_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
export const prison_section_3_image_5_animation = {
  animationDuration: "1s",
  animationDelay: "3.7s",
  animationDirection: "alternate",
  animationIterationCount: "infinite",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

/** prison_section4 **/
export const prison_section_4_image_1_keyframe = null;
export const prison_section_4_image_1_animation = null;

export const prison_section_4_image_2_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
  
`;
export const prison_section_4_image_2_animation = {
  animationDuration: "7s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_4_image_3_keyframe = null;
export const prison_section_4_image_3_animation = null;

export const prison_section_4_image_4_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const prison_section_4_image_4_animation = {
  animationDuration: "4.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_4_image_5_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const prison_section_4_image_5_animation = {
  animationDuration: "2s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const prison_section_4_image_6_keyframe = null;
export const prison_section_4_image_6_animation = null;

export const prison_section_4_image_7_keyframe = null;
export const prison_section_4_image_7_animation = null;

export const prison_section_4_image_8_keyframe = null;
export const prison_section_4_image_8_animation = null;

export const prison_section_4_image_9_keyframe = null;
export const prison_section_4_image_9_animation = null;
