export const ArrowDownSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="78.5px"
    height="65.5px"
  >
    <path
      fillRule="evenodd"
      stroke="rgb(255, 255, 255)"
      strokeWidth="1px"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      fill="none"
      d="M38.455,1.019 L39.455,1.019 L39.455,46.362 L38.455,46.362 L38.455,1.019 Z"
    />
    <path
      fillRule="evenodd"
      stroke="rgb(255, 255, 255)"
      strokeWidth="1px"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      fill="none"
      d="M2.175,31.644 L2.878,30.941 L34.509,62.565 L33.806,63.269 L2.175,31.644 Z"
    />
    <path
      fillRule="evenodd"
      stroke="rgb(255, 255, 255)"
      strokeWidth="1px"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      fill="none"
      d="M76.029,31.409 L76.733,32.112 L45.044,63.795 L44.339,63.091 L76.029,31.409 Z"
    />
  </svg>
);
