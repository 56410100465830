import styled from "styled-components";
import { notoSans } from "../../fonts";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 120%;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  line-height: 6vw;
`;
export const Container = styled.div`
  //padding-top: 5%;
  padding-left: 30%;
  padding-right: 30%;
`;

export const Title = styled.img`
  width: 100%;
  margin-bottom: 25px;
`;
export const Text = styled.div`
  font-family: ${notoSans};
  font-weight: 850;
  font-size: 5vw;
  text-align: center;
  color: white;
  //margin-bottom: 40px;
`;
export const Text2 = styled.div`
  font-family: ${notoSans};
  font-weight: 850;
  font-size: 7vw;
  text-align: center;
  color: white;
  //margin-bottom: 40px;
`;
export const Input = styled.input`
  width: calc(99.5% - 1vw);
  height: 35px;
  margin-bottom: 25px;
  color: antiquewhite;
  background: RGB(76, 76, 76);
  border: white solid 1px;
  //padding: 0 0 0 1vw;
  //outline: 1px white solid;
  outline-offset: -2px;
  padding-left: 1vw;
  //padding-right: -1vw;
  //justify-content: center;
  //align-items: center;
  //:focus {
  //  border: none;
  //  outline: none;
  //}
`;
export const LoginButton = styled.button`
  padding: 0;
  width: 100.1%;
  height: 45px;
  background: RGB(40, 40, 40);
  border: white solid 1px;
  color: white;
  margin-bottom: 10vh;
  cursor: pointer;
`;
export const Logo = styled.img`
  width: 15%;
  margin-bottom: 5vh;
  position: absolute;
  bottom: 0;
`;
