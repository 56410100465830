import styled from "styled-components";
import { notoSans } from "../../fonts";

const IllustHeader = ({ index, title, type }) => {
  return (
    <Container>
      {/*<Index color={index.color}>{index.number}</Index>*/}
      <IndexImg src={`/images/${type}/header/header_${index.number}.png`} />
      <Title>{title}</Title>
    </Container>
  );
};

export default IllustHeader;

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px 5vw;
  background: black;
  display: flex;
  align-items: center;
  /*gap: 60px;*/
  gap: 3%;
  border-bottom: white solid 1px;
  border-top: white solid 1px;
`;

const IndexImg = styled.img`
  width: 5vw;
`;

const Index = styled.div`
  //width: 120px;
  //height: 120px;
  ${notoSans};
  font-weight: 900;
  width: 5vw;
  height: 5vw;
  border-radius: 60px;
  border: solid 1px white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  color: white;
  background: ${({ color }) => color};
  padding-bottom: 1px;
`;

const Title = styled.div`
  ${notoSans};
  font-weight: 900;
  /*font-size: 60px;*/
  font-size: 3.8vw;
  color: white;
`;
