import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Interview from "./pages/interview/Interview";
import { Station } from "./pages";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import Gnb from "./components/organisms/Gnb/Gnb";
import Login from "./pages/Login/Login";
import LoginCheck from "./components/atoms/LoginCheck";
import IntroPage from "./pages/Intro/IntroPage";
import University from "./pages/University/University";
import Prison from "./pages/Prison/Prison";
import UserInfoChange from "./pages/Login/UserInfoChange";

const router = createBrowserRouter([
  {
    path: "/",
    element: <IntroPage />,
    // errorElement: <>error</>,
  },
  {
    path: "/intro",
    element: <IntroPage />,
    // errorElement: <>error</>,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <>error</>,
  },
  {
    path: "/user_change",
    element: <UserInfoChange />,
    errorElement: <>error</>,
  },
  {
    path: "/station",
    element: <Station />,
    errorElement: <>error</>,
  },
  {
    path: "/interview",
    element: <Interview />,
  },
  {
    path: "/university",
    element: <University />,
  },
  {
    path: "/prison",
    element: <Prison />,
  },
  {
    path: "/video",
    element: <VideoPlayer open={true} />,
  },
]);

export const gnbRef = React.createRef();
window.gnbRef = gnbRef;
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    {/*<Layout>*/}
    <Gnb ref={gnbRef} />
    <LoginCheck />
    <RouterProvider router={router} />
    {/*</Layout>*/}
  </React.StrictMode>
);
