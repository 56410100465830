import styled from "styled-components";
import { notoSans, notoSerif } from "../../../fonts";

export const Wrapper = styled.div`
  background: black;
`;
export const Screen = styled.div`
  position: relative;
  width: 100vw;
  height: min(56vw, calc(${({ gnbHeight }) => `99vh - ${gnbHeight}px`}));
  z-index: 0;
  background-image: url(${({ bg }) => `${bg}`});
  // 하드코딩
  background-size: calc(100%) auto;
  background-position: calc(100%) top;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${({ isVerticalLong, gnbHeight }) => {
    if (isVerticalLong) {
      return `  width: calc((99vh - ${gnbHeight}px) * 100 / 56);
  height: calc(99vh - ${gnbHeight}px);
  margin: auto;
  `;
    } else {
      return `  width: 100vw;
  height: 56vw;`;
    }
  }};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Layer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  z-index: ${({ isActive, isFixed }) => (isActive && !isFixed ? 5 : 3)};
`;

export const Asset = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};

  &.blinking-normal {
    -webkit-animation: blink 0.5s ease-in-out infinite alternate;
    -moz-animation: blink 0.5s ease-in-out infinite alternate;
    animation: blink 0.5s ease-in-out infinite alternate;
  }

  &.blinking-slow {
    -webkit-animation: blink 1s ease-in-out infinite alternate;
    -moz-animation: blink 1s ease-in-out infinite alternate;
    animation: blink 1s ease-in-out infinite alternate;
  }

  &.vibrating-ru {
    animation: vibration-ru 1.4s infinite alternate;
  }

  &.vibrating-rd {
    animation: vibration-rd 1.4s infinite alternate;
  }

  &.vibrating-lu {
    animation: vibration-lu 1.4s infinite alternate;
  }

  &.vibrating-ld {
    animation: vibration-ld 1.4s infinite alternate;
  }

  &.trembling1 {
    animation: tremble1 0.2s ease-in-out infinite alternate;
  }

  &.trembling2 {
    animation: tremble2 0.2s ease-in-out infinite alternate;
  }

  &.trembling3 {
    animation: tremble3 0.2s ease-in-out infinite alternate;
  }

  @-webkit-keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes vibration-ru {
    0% {
      transform: translate(-2px, 2px);
    }
    10%,
    90% {
      transform: translate(2px, -2px);
      opacity: 1;
    }
    30%,
    70% {
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes vibration-rd {
    0% {
      transform: translate(2px, 2px);
    }
    10%,
    90% {
      transform: translate(-2px, -2px);
      opacity: 1;
    }
    30%,
    70% {
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes vibration-lu {
    0% {
      transform: translate(-2px, -2px);
    }
    10%,
    90% {
      transform: translate(2px, 2px);
      opacity: 1;
    }
    30%,
    70% {
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes vibration-ld {
    0% {
      transform: translate(2px, -2px);
    }
    10%,
    90% {
      transform: translate(-2px, 2px);
      opacity: 1;
    }
    30%,
    70% {
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes tremble1 {
    0%,
    100% {
      transform: rotate(0);
    }
    10% {
      transform: rotate(0.5deg);
    }
    30% {
      transform: rotate(-0.5deg);
    }
  }
  @keyframes tremble2 {
    0%,
    100% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(-0.5deg);
    }
    70% {
      transform: rotate(0.5deg);
    }
  }
  @keyframes tremble3 {
    0%,
    100% {
      transform: rotate(0);
    }
    30% {
      transform: rotate(-0.5deg);
    }
    50% {
      transform: rotate(0.5deg);
    }
  }
`;

export const TextContentLayer = styled.div`
  position: absolute;
  color: white;
  overflow-y: clip;
  width: 35%;
  max-height: 78%;
  z-index: 6;
  ${({ isVerticalLong }) => {
    if (isVerticalLong) {
      return "top: calc(5vh + 6vw); left: 4vh;";
    } else {
      return "top: 8vw; left: 4vw;";
    }
  }};
`;

export const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  ${({ isVerticalLong }) => {
    if (isVerticalLong) {
      return "margin-left: -2.5vh;";
    } else {
      return "margin-left: -1.5vw;";
    }
  }};
`;

export const HeaderIcon = styled.img`
  ${({ isVerticalLong }) => {
    if (isVerticalLong) {
      return "height: 10vh; width: auto; margin-left: -1vh";
    } else {
      return "height: 7vw; width: auto; margin-left: -1vw";
    }
  }};
`;

export const HeaderIndex = styled.div`
  ${notoSans};
  font-weight: 900;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  border-width: 2px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isVerticalLong, isFirst }) => {
    if (isVerticalLong) {
      if (isFirst) {
        return (
          "font-size: 3vh;" +
          "width: 4.5vh;" +
          "height: 4.5vh;" +
          "margin-left: 5vh;"
        );
      } else {
        return (
          "font-size: 3vh;" +
          "width: 4.5vh;" +
          "height: 4.5vh;" +
          "margin-left: 2vh;"
        );
      }
    } else {
      if (isFirst) {
        return (
          "font-size: 2.5vw;" +
          "width: 3vw;" +
          "height: 3vw;" +
          "margin-left: 2.5vw;"
        );
      } else {
        return (
          "font-size: 2.5vw;" +
          "width: 3vw;" +
          "height: 3vw;" +
          "margin-left: 1vw;"
        );
      }
    }
  }};

  div {
    width: fit-content;
    line-height: 1;
    height: fit-content;

    ${({ isVerticalLong }) => {
      if (isVerticalLong) {
        return "margin-top: -0.2vh;";
      } else {
        return "margin-top: -0.2vw;";
      }
    }};
  }
`;

export const HeaderDatetime = styled.div`
  ${notoSerif};
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1;
  ${({ isVerticalLong }) => {
    if (isVerticalLong) {
      return "font-size: 4vh; margin-top: -1vh;";
    } else {
      return "font-size: 2.6vw; margin-top: -1vw;";
    }
  }};
}
`;

export const Texts = styled.div`
  padding-top: ${({ isActive }) => (!isActive ? "200px" : 0)};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: 0.4s;
`;
export const TextPhrase = styled.div`
  ${notoSans};
  color: rgb(255, 255, 255);
  font-weight: 400;
  line-height: 1.6;
  word-break: keep-all;
  ${({ isVerticalLong }) => {
    if (isVerticalLong) {
      return "font-size: 2.2vh; margin-top: 2vh;";
    } else {
      return "font-size: 1.25vw; margin-top: 1.4vw;";
    }
  }};
}
`;

export const ScrollLayerContainer = styled.div`
  width: 100%;
  //margin-top: -100%;
  height: 100%;
  position: relative;
  z-index: 2;
`;

export const AssetContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  //overflow: hidden;
  z-index: 3;
`;

export const Title = styled.div`
  font-family: ${notoSans};
  font-weight: 900;
  color: rgb(255, 255, 255);
  border-top: 0.5px solid white;
  border-bottom: 0.5px solid white;
  font-size: 4vw;
  padding: 1vw 2vw;
  background: black;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 96vw;
  z-index: 3;
`;

export const OnOffButton = styled.div`
  position: absolute;
  right: 5%;
  bottom: 3%;
  color: white;
  z-index: 10;
  cursor: pointer;
  font-family: ${notoSans};
  font-weight: 900;
  line-height: 0.328;
  border-top: 1px solid white;
  border-bottom: 1px solid white;

  ${({ isVerticalLong }) => {
    if (isVerticalLong) {
      return "font-size: 1vh; padding: 1vh 0.5vh;";
    } else {
      return "font-size: 1vw; padding: 1vw 0.5vw;";
    }
  }};
`;
