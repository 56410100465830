import styled from "styled-components";
import { notoSans } from "../../fonts";

export const Wrapper = styled.div`
  background-color: black;
  //position: absolute;
`;

export const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/intro/intro.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
`;

export const TopText = styled.div`
  font-family: ${notoSans};
  font-size: 1.5vw;
  letter-spacing: 0.2vw;
  line-height: 5vh;
  padding-top: 12%;
  text-align: center;
  display: flex;
  justify-content: center;
  //align-items: center;
  color: white;
  @media (min-width: 2000px) {
    font-size: 1.2vw;
  }
`;

export const Line = styled.hr`
  display: flex;
  width: 100%;
  margin-top: 5%;
  color: white;
  line-height: 1px;
`;
export const BottomTextContainer = styled.div`
  font-family: ${notoSans};
  font-size: 1.5vw;
  letter-spacing: 0.1vw;
  line-height: 4vh;
  margin-top: 2%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const BottomText = styled.div`
  & span {
    color: #5aaadf;
    font-weight: bold;
  }
`;

export const BlueText = styled.div`
  color: #5aaadf;
  font-weight: bold;
`;

export const LogoContainer = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
`;
export const Logo = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  text-align: center;
  flex-direction: column;
`;
