import * as S from "./Illustration.style";
import QuoteBox from "../QuoteBox/QuoteBox";
import { useEffect, useRef } from "react";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import IllustImage from "../../atoms/illustImage/IllustImage";

const exceptionStation6_contents =
  "상황이 악화되자 여단장이 \n" +
  "유선으로 전교사령관에게 발포 건의를 했으나, \n" +
  "승인받지 못하고, 정호용 특전사령관과 통화했어요";

const exceptionStation6_source = "- 여단 통신대 중사 김OO 인터뷰 내용 중에서 -";

const Illustration = ({ illustData, imageData }) => {
  const { quotation } = illustData;
  const quoteRef = useRef(null);
  const quoteRef2 = useRef(null);

  useEffect(() => {
    if (quoteRef.current) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.style.opacity = 1;
            } else {
              entry.target.style.opacity = 0;
            }
          });
        },
        { threshold: 0.5 }
      );
      observer.observe(quoteRef.current);
    }
  }, [quoteRef.current]);

  useEffect(() => {
    if (quoteRef2.current) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.style.opacity = 1;
            } else {
              entry.target.style.opacity = 0;
            }
          });
        },
        { threshold: 0.5 }
      );
      observer.observe(quoteRef2.current);
    }
  }, [quoteRef2.current]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Illustration>
          <S.ImageBox>
            {imageData.map((image) => {
              return (
                <IllustImage
                  key={`image-content-${image.src}`}
                  src={image.src}
                  keyframe={image.keyframe}
                  animation={image.animation}
                  animationName={"ani"}
                />
              );
            })}
          </S.ImageBox>
          <QuoteBox
            drilledRef={quoteRef}
            position={quotation.position}
            contents={quotation.contents}
            source={quotation.source}
            isAbsolute={true}
            color={"white"}
          />
          {quotation.exception && (
            <QuoteBox
              drilledRef={quoteRef2}
              position={{ horizontal: "50%", vertical: "5%" }}
              contents={exceptionStation6_contents}
              source={exceptionStation6_source}
              isAbsolute={true}
              color={"white"}
              exception={quotation.exception}
            />
          )}
        </S.Illustration>
      </S.Container>
    </S.Wrapper>
  );
};

export default Illustration;
