import * as S from "./IntroPrison.style";
import { ArrowDownSvg } from "./IntroPrison.svg";

export const IntroPrison = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>
          <span className={"medium"}>5·18민주화운동</span>
          <br />
          <span className={"big"}>제3공수여단</span>
          <br />
          <span className={"small"}>진압작전 주요상황</span>
        </S.Title>
        <S.Location>광주교도소</S.Location>
        <S.ArrowDown>
          <ArrowDownSvg />
        </S.ArrowDown>

        <S.Row>
          <S.MediumFont>
            <S.LargeFont>
              1980.5.21~5.24
              <br />
              광주교도소 일원의 주요 상황
            </S.LargeFont>
            제3공수여단은 광주-담양, 순천 간 도로 차단에 의한
            <br />
            광주 외곽 봉쇄 작전과 광주교도소 방어 작전으로
            <br />
            전환하기 위해 전남대에서 광주교도소로 이동하였다.
            <br />
            광주교도소 일원에서 작전을 수행하면서
            <br />
            시위대와 민간인을 구분하지 않고 광주교도소 인근
            <br />
            도로를 지나가는 차량과 민간인들에게 총격을 가해
            <br />
            다수의 시민들이 사망하거나 부상당했다.
            <br />
            제3공수여단은 전남대에서 광주교도소로 이동하면서
            <br />
            함께 이송해 온 연행자 중 이송 차량에서 질식한
            <br />
            민간인과 광주교도소 주둔 기간 총격에 의해 사망한
            <br />
            민간인들을 현장에 가(암)매장하였다.
          </S.MediumFont>
          {/*<S.ColumnDivider />*/}
          <S.MediumFont>
            <S.LargeFont>
              <br />
            </S.LargeFont>
            또한 광주교도소 외역 창고에 구금되어 있던
            <br />
            시민들을 대상으로 한 가혹행위를 지속하였으며,
            <br />이 과정에서 사망에 이른 민간인들도 있었다.
            <br />
          </S.MediumFont>
        </S.Row>
      </S.Container>
    </S.Wrapper>
  );
};
