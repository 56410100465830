import styled from "styled-components";

export const Wrapper = styled.div`
  border-top: white 1px solid;
  width: 100%;
  padding-bottom: 63%;
  background: #b5b5b6;
  position: relative;
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const TextBook = styled.div`
  display: flex;
  justify-content: center;
  gap: 2%;
  height: 100%;
`;
// margin: 5vh 3.5vw 5vh 2.5vw;
export const Page = styled.div`
  ${({ isRight }) => (isRight ? `margin-top: 5vh; margin-bottom: 5vh;` : "")};
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ isRight }) => (isRight ? "baseline" : "center")};
`;

export const VideoButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  column-gap: 3vw;
  row-gap: 2vw;
`;

export const VideoButton = styled.div`
  background-color: rgb(255, 255, 255);
  width: 15vw;
  height: 6vw;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  font-family: "Noto Sans CJK KR";
  color: rgb(0, 0, 0);
  line-height: 1.715;
  text-align: center;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: rgb(255, 255, 255);
`;

export const Sentences = styled.div`
  font-size: 1.5vw;
  height: 90%;
  overflow-y: scroll;
  padding-left: 2vw;
  padding-right: 1vw;
`;

export const Sentence = styled.div`
  font-family: source-han-sans-korean, sans-serif;
  font-style: normal;
  color: rgb(0, 0, 0);
  line-height: 1.714;
  text-align: left;
  margin-bottom: 5vh;
  font-weight: lighter;
  white-space: pre-line;

  span {
    font-weight: bold;
  }
`;
