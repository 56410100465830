import QuoteBox from "../QuoteBox/QuoteBox";
import * as S from "./TextBook.style";
import { useState } from "react";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";

const TextBook = ({ textBookData }) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const onToggleModal = () => {
    setOpen(!open);
  };
  const onClickThumbnail = (videoUrl) => {
    setUrl(videoUrl);
    onToggleModal();
  };

  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.TextBook>
            <S.Page isRight={false}>
              <QuoteBox
                contents={textBookData.quotation.contents}
                source={textBookData.quotation.source}
                isAbsolute={false}
                color={"black"}
              />

              <S.VideoButtons>
                {textBookData.videos.map((video, i) => (
                  <S.VideoButton
                    key={`video-link-${video.name}-${i}`}
                    onClick={() => onClickThumbnail(video.src)}
                  >
                    인터뷰 동영상 보기
                    <br />
                    {video.name}
                  </S.VideoButton>
                ))}
              </S.VideoButtons>
            </S.Page>
            <S.Divider />
            <S.Page isRight={true}>
              <S.Sentences>
                {textBookData.sentences.map((sentence, i) => (
                  <S.Sentence key={`sentence-${sentence.contents}-${i}`}>
                    <span>{sentence.source}</span>
                    <br />
                    {sentence.contents}
                  </S.Sentence>
                ))}
              </S.Sentences>
            </S.Page>
          </S.TextBook>
        </S.Container>
      </S.Wrapper>
      {open && <VideoPlayer toggleModal={onToggleModal} url={url} />}
    </>
  );
};

export default TextBook;
