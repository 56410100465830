import styled from "styled-components";

export const Container = styled.div`
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  width: 10%;
`;
