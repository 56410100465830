import styled from "styled-components";
import { notoSans } from "../../fonts";

export const Container = styled.div`
  padding: 50px 55px 100px 55px;
  background-color: black;
  background-image: url("/images/interview/background.png");
  font-family: ${notoSans};
`;
export const ContainerTitle = styled.div`
  color: white;
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: 900;
`;
export const Section = styled.div``;
export const Line = styled.hr`
  margin-top: 50px;
  color: white;
`;
export const SectionTitle = styled.div`
  margin-top: 15px;
  color: white;
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 900;
`;

export const ThumbContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-row-gap: 10px;
  justify-content: center;
`;
export const Thumb = styled.img`
  width: 100%;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;
