export const getCookie = (name) => {
  if (typeof window !== "undefined") {
    const value = `${document.cookie}`;
    const parts = value.split(`${name}=`);
    return parts[1];
  }
};

export const setCookie = (name, value, days) => {
  if (typeof window !== "undefined") {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const domain = document.location.hostname;
    const expires = `; domain=${domain}; expires=${date.toUTCString()}`;
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
};
