import * as S from "./gnb.style";
import { navigatorsInfos } from "../../../models/gnb";
import Dropdown from "../../atoms/dropdown/Dropdown";
import { forwardRef, useEffect, useState } from "react";
import { stationIllustRefs } from "../../../pages/Station/Station";
import { useAtom } from "jotai";
import { usersAtom } from "../../../stores/user";
import { setCookie } from "../../../logics/auth";
import { universityIllustRefs } from "../../../pages/University/University";
import { prisonIllustRefs } from "../../../pages/Prison/Prison";

const Gnb = forwardRef((props, ref) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [user, setUser] = useAtom(usersAtom);
  let isShow = true;

  /** 로그인 페이지의 경우 GNB 안보이게 하기 **/
  const url = new URL(window.location.href);
  if (url.pathname === "/login") {
    isShow = false;
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentPath(window.location.pathname.split("?")[0]);
    }
  }, []);

  const onClickNavigator = (page) => {
    if (currentPath === page) {
      setShowDropdown(!showDropdown);
    } else {
      window.location.href = page;
    }
  };

  return (
    <>
      {isShow ? (
        <S.Container ref={ref}>
          <S.Title>5·18민주화운동 제3공수여단 진압작전 주요상황</S.Title>
          <S.NavContainer>
            <S.Navigator onClick={() => onClickNavigator("/intro")}>
              인트로
            </S.Navigator>
            <S.Navigator onClick={() => onClickNavigator("/station")}>
              광주역
              <Dropdown
                items={navigatorsInfos.station.map((section, i) => ({
                  name: section,
                  ref: stationIllustRefs?.current[i],
                  onClick: () => {
                    window.scrollTo({
                      top:
                        stationIllustRefs?.current[i].offsetTop -
                        window.gnbRef.current?.offsetHeight,
                      behavior: "smooth",
                    });
                  },
                }))}
                isShow={
                  (currentPath === "/station" || currentPath === "/") &&
                  showDropdown
                }
                setIsShow={setShowDropdown}
              />
            </S.Navigator>
            <S.Navigator onClick={() => onClickNavigator("/university")}>
              전남대
              <Dropdown
                items={navigatorsInfos.university.map((section, i) => ({
                  name: section,
                  ref: universityIllustRefs?.current[i],
                  onClick: () => {
                    window.scrollTo({
                      top:
                        universityIllustRefs?.current[i].offsetTop -
                        window.gnbRef.current?.offsetHeight,
                      behavior: "smooth",
                    });
                  },
                }))}
                isShow={currentPath === "/university" && showDropdown}
                setIsShow={setShowDropdown}
              />
            </S.Navigator>
            <S.Navigator onClick={() => onClickNavigator("/prison")}>
              광주교도소
              <Dropdown
                items={navigatorsInfos.prison.map((section, i) => ({
                  name: section,
                  ref: prisonIllustRefs?.current[i],
                  onClick: () => {
                    window.scrollTo({
                      top:
                        prisonIllustRefs?.current[i].offsetTop -
                        window.gnbRef.current?.offsetHeight,
                      behavior: "smooth",
                    });
                  },
                }))}
                isShow={currentPath === "/prison" && showDropdown}
                setIsShow={setShowDropdown}
              />
            </S.Navigator>
            <S.Navigator onClick={() => (window.location.href = "/interview")}>
              인터뷰
            </S.Navigator>
            <S.AccountNavigator
              onClick={() => {
                setShowLogin(!showLogin);
              }}
            >
              {showLogin && (
                <Dropdown
                  items={[
                    {
                      name: "ID,PW 변경",
                      onClick: () => {
                        if (user.isSignIn) {
                          window.location.href = "/user_change";
                        }
                      },
                    },
                    {
                      name: "로그아웃",
                      onClick: () => {
                        setUser({ isSignIn: false });
                        setCookie("accessToken", null);
                        window.location.href = "/login";
                      },
                    },
                  ]}
                  isShow={true}
                  setIsShow={setShowDropdown}
                />
              )}
            </S.AccountNavigator>
          </S.NavContainer>
        </S.Container>
      ) : (
        <></>
      )}
    </>
  );
});

export default Gnb;
