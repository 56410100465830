import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: ${({ top }) => `${top}%`};
  right: ${({ right }) => `${right}%`};
  //z-index: 1;
`;

export const Container = styled.div`
  position: relative;
`;

const dotAnimation = keyframes`
  0% {
    opacity: 0;    
  }
  100% {
    opacity: 1;
  }
`;

export const Dot = styled.div`
  width: 3.7vw;
  height: 3.7vw;
  border-radius: 50%;
  cursor: pointer;
  background-image: url("/images/station/last/dot.png");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  animation: ${dotAnimation} 1s infinite linear alternate;
`;

export const DiagonalLine = styled.div`
  position: absolute;
  ${({ direction }) => {
    if (direction === "right") {
      return `top: 46%; right: 50%`;
    } else {
      return `top: 60%; right: 36%`;
    }
  }};
  background: linear-gradient(-20deg, rgba(0, 0, 0, 0), rgba(255, 0, 0, 100%));
  width: 9vw;
  transform: ${({ direction }) =>
    direction === "right"
      ? "rotate(135deg)"
      : "rotate(45deg) translate(-10px, 0px)"};
  transform-origin: right;
  z-index: 2;
`;

export const HorizontalLine = styled.div`
  position: relative;
  border-bottom: 2px solid red;
  transform: ${({ direction }) =>
    direction === "right"
      ? "rotate(-135deg) "
      : "rotate(-45deg) translate(-100%, 0px)"};
  width: 9vw;
  transform-origin: left;
`;

export const Names = styled.div`
  position: absolute;
  //width: fit-content;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 2;
`;

export const Name = styled.div`
  background-color: rgba(255, 0, 0, 20%);
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  padding-left: 1vw;
  font-size: 1vw;
  font-weight: bold;
  color: white;
  z-index: 4;
  &:first-child {
    padding-top: 0.4vw;
  }
  &:last-child {
    padding-bottom: 0.4vw;
  }
`;
