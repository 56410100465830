import ReactPlayer from "react-player";
import * as S from "./videoplayer.style";
import { useRef, useEffect, useLayoutEffect, useState } from "react";

function VideoPlayer({ toggleModal, url }) {
  const playerRef = useRef(null);
  function clickOutside(event) {
    if (playerRef.current && !playerRef.current?.contains(event.target)) {
      toggleModal();
    }
  }

  return (
    <S.Background onClick={clickOutside}>
      <S.Player ref={playerRef}>
        <ReactPlayer url={url} width="100%" playing={true} controls={true} />
      </S.Player>
    </S.Background>
  );
}
export default VideoPlayer;

let i = 0;
function useOnOutsideClick(ref, callback) {
  useLayoutEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // 2번 호출되서
        if (i % 2 === 0) {
          callback();
        }
        i++;
      } else {
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
}
