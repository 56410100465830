import styled from "styled-components";
import { notoSans } from "../../../fonts";

export const Container = styled.div`
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: max-content;
  ${notoSans};
  font-weight: 900;
  color: ${({ color }) => color};
  line-height: 1.4;
  /*font-size: 34px;*/
  font-size: 2.5vw;
  opacity: ${({ isAbsolute }) => (isAbsolute ? 0 : 1)};
  transition: all 6s ease-in-out;

  ${({ isAbsolute }) =>
    isAbsolute ? "" : "margin-top: 3vw;\n" + "margin-bottom: 3vw;"}

  ${({ isAbsolute, position }) =>
    isAbsolute
      ? "position: absolute;\n" +
        `    top: ${position.vertical};\n` +
        `    left: ${position.horizontal};\n` +
        "    transform: translateX(-50%);"
      : ""};

  span {
    margin-top: 2vw;
    margin-bottom: 2vw;
    font-size: 1.7vw;
    font-weight: 400;
  }
`;
