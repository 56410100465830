import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 63%;
  background: #b5b5b6;
  position: relative;
  overflow: hidden;
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
`;

export const Illustration = styled.div`
  overflow: hidden;
`;

export const Bg = styled.img``;

export const ImageBox = styled.div`
  overflow: hidden;
`;

// /** Key Frames**/
// const section_1_image_2 = keyframes`
//     0% {
//       transform: translateX(-100%);
//     }
//   100$ {
//     transform: translateX(0%);
//   }
// `;

export const ImageContent = styled.img`
  //transform: translateY(-7%);
  position: absolute;
  width: 100%;

  &.animation {
    animation-name: ${({ animationName }) =>
      animationName ? animationName : ""};
    animation-iteration-count: 1;
    animation-play-state: running;
    animation-duration: 3s;

    ${({ animationName, keyframe }) => {
      return (
        `@keyframes ${animationName} { \n` +
        "    from {\n" +
        "       transform: translateX(-10%);\n" +
        "}" +
        "   to { transform: translateX(10%);}" +
        "}"
      );
    }}
`;

// const a = @keyframes animationName {
//     0% {
//         transform: translateX(0%)
//     }
//     100% {
//
//     }
// }

/**
 * 내가 뭘 하려고 하는가?
 * 1) model.ts에 있는 값들을 통해서 css를 조작하려고 한다.
 * 2) string 값으로 넘어오기는 한다.
 * 3) keyframe을 이용하지 않는다면? 음..
 * **/
