import React, { useRef } from "react";
import {
  prisonIllustrations,
  universityIllustrations,
} from "../../models/illustrations";
import { useAtom } from "jotai";
import { usersAtom } from "../../stores/user";
import Map from "../../components/organisms/Map/Map";
import { prison, station, university } from "../../models/maps";
import IllustContainer from "../../components/organisms/IllustContainer/IllustContainer";
import UniversitySummary from "../../components/organisms/UniversitySummary/UniversitySummary";
import {
  prison_marks,
  prison_summaries,
  university_marks,
  university_summaries,
} from "../../models/illustrations/summary";
import Footer from "../../components/organisms/Footer/Footer";
import { IntroPrison } from "../../components/organisms/IntroPrison/IntroPrison";
import PrisonSummary from "../../components/organisms/PrisonSummary/PrisonSummary";
import { isMobile } from "react-device-detect";
import MapMobile from "../../components/organisms/Map/MapMobile";

export let prisonIllustRefs;
const Prison = () => {
  prisonIllustRefs = useRef(
    new Array(prisonIllustrations.length + 1).fill(null)
  );
  const [user] = useAtom(usersAtom);
  return (
    user.isSignIn && (
      <>
        <div id={"upper"}>
          <IntroPrison />
          {isMobile ? <MapMobile title={"prison"} /> : <Map data={prison} />}
        </div>
        {prisonIllustrations.map((illustration, i) => (
          <IllustContainer
            data={illustration}
            refs={prisonIllustRefs}
            index={i}
            key={`illustContainer-${illustration.title}`}
            type={"prison"}
          />
        ))}
        <PrisonSummary sumarries={prison_summaries} marks={prison_marks} />
        {/*<Footer />*/}
      </>
    )
  );
};
export default Prison;
