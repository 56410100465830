import React, { useRef } from "react";
import { universityIllustrations } from "../../models/illustrations";
import { useAtom } from "jotai";
import { usersAtom } from "../../stores/user";
import Map from "../../components/organisms/Map/Map";
import { station, university } from "../../models/maps";
import IllustContainer from "../../components/organisms/IllustContainer/IllustContainer";
import {
  university_marks,
  university_summaries,
} from "../../models/illustrations/summary";
import Footer from "../../components/organisms/Footer/Footer";
import UniversitySummary from "../../components/organisms/UniversitySummary/UniversitySummary";
import { IntroUniversity } from "../../components/organisms/IntroUniversity/IntroUniversity";
import { isMobile } from "react-device-detect";
import MapMobile from "../../components/organisms/Map/MapMobile";

export let universityIllustRefs;
const University = () => {
  universityIllustRefs = useRef(
    new Array(universityIllustrations.length + 1).fill(null)
  );
  const [user] = useAtom(usersAtom);
  return (
    user.isSignIn && (
      <>
        <div id={"upper"}>
          <IntroUniversity />
          {isMobile ? (
            <MapMobile title={"university"} />
          ) : (
            <Map data={university} />
          )}
        </div>
        {universityIllustrations.map((illustration, i) => (
          <IllustContainer
            data={illustration}
            refs={universityIllustRefs}
            index={i}
            key={`illustContainer-${illustration.title}`}
            type={"university"}
          />
        ))}
        <UniversitySummary
          sumarries={university_summaries}
          marks={university_marks}
        />
        {/*<Footer />*/}
      </>
    )
  );
};
export default University;
