import * as S from "./Mark.style";
import { useState } from "react";

export const Mark = ({ mark }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <S.Wrapper top={mark.top} right={mark.right}>
      <S.Container>
        <S.Dot
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        />
        {isHover && (
          <S.DiagonalLine direction={mark.direction}>
            <S.HorizontalLine direction={mark.direction}>
              <S.Names>
                {mark.names.map((name, idx) => (
                  <S.Name idx={{ idx }}>{name}</S.Name>
                ))}
              </S.Names>
            </S.HorizontalLine>
          </S.DiagonalLine>
        )}
      </S.Container>
    </S.Wrapper>
  );
};
