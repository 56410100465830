import { useEffect, useState } from "react";

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    setIntersecting(entry.isIntersecting);
  });

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

export const isFullScreen = (ref) =>
  ref.current?.getBoundingClientRect().height === window.innerHeight;

export const useInScreen = (ref) => {
  const [isInScreen, setIsInScreen] = useState(false);

  const isOnScreen = useOnScreen(ref);
  const rect = ref.current?.getBoundingClientRect();
  const isLongerThanScreen = rect?.height > window.innerHeight;

  const isOverflowScreen =
    isFullScreen(ref) &&
    rect?.top > -window.innerHeight / 5 &&
    rect?.top <= window.innerHeight / 5;

  const isTopInScreen = rect?.top > 1;
  const isBottomInScreen = rect?.bottom < window.innerHeight;
  useEffect(() => {
    setIsInScreen(
      isOnScreen && (isOverflowScreen || (isTopInScreen && isBottomInScreen))
    );
  }, [isOnScreen, isLongerThanScreen, isTopInScreen, isBottomInScreen]);
  console.log("isInScreen: ", isInScreen);
  return isInScreen;
};
