import * as S from "./intro.style";
import { usersAtom } from "../../stores/user";
import { useAtom } from "jotai";

const IntroPage = () => {
  const [user] = useAtom(usersAtom);
  return (
    user.isSignIn && (
      <S.Wrapper>
        <S.Container>
          <S.TopText>
            1980년 5월 20일 제3공수여단은 광주에 도착한 이후 전남대에 숙영지를
            편성하고 <br />
            금남로, 광주시청, 공용터미널, 광주고속버스터미널, 광주역, 전남대,
            광주교도소 일원에서 <br />
            시위 진압작전과 광주 외곽 봉쇄작전을 실시하였다.
            <br />
            본 자료는 광주역 일원에서 계엄군과 최초 집단 발포부터 광주교도소
            일원에서의 민간인 희생자 가(암)매장까지의 과정을 <br />
            주요 사건 중심으로 계엄군의 증언과 진술을 바탕으로 재구성하였다.
          </S.TopText>
          <S.Line />
          <S.BottomTextContainer>
            <S.BottomText>
              이 영상과 자료는 5·18민주화운동 현장에서 제3공수여단의 진압 작전
              상황을 재구성한 것입니다.
              <br />
              <span>이 자료와 영상의 내용은 최종 확인된 것이 아니라 </span>
              현장에 있었던{" "}
              <span>계엄군 일부의 진술과 증언을 바탕으로 재구성</span>한
              것이므로
              <br /> <span>내용이 사실과 다를 수 있습니다.</span>
              <br />
              이와 관련된 증언을 추가 확인하고 교차 검증하기 위해 제시된
              내용입니다.
            </S.BottomText>
          </S.BottomTextContainer>
          <S.LogoContainer>
            <S.Logo src={"/images/logo/logo_en.png"} />
          </S.LogoContainer>
        </S.Container>
      </S.Wrapper>
    )
  );
};
export default IntroPage;
