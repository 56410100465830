import { useEffect, useRef, useState } from "react";
import * as S from "./Map.style";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { gnbRef } from "../../../index";
import { isMobile } from "react-device-detect";

const Map = ({ data }) => {
  const { bg, marks, title, icons, fixedAssets, scrollLayers } = data;
  const screenRef = useRef(null);
  window.screenRef = screenRef;
  const isOnScreen = useOnScreen(screenRef);
  const [on, setOn] = useState(false);
  const [activeLayer, setActiveLayer] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  const setScrolling = () => {
    if (!isScrolling) {
      setIsScrolling(true);
      setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    }
  };

  const onChangeLayer = (direction) => {
    const validate = (current, direction) =>
      current + direction >= 0 && current + direction < scrollLayers.length;
    if (!isScrolling) {
      if (validate(activeLayer, direction)) {
        setActiveLayer(activeLayer + direction);
        setScrolling();
      }
    }
  };

  const handleScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      isOnScreen &&
      screenRef.current?.getBoundingClientRect().bottom <= window.innerHeight &&
      activeLayer < scrollLayers.length - 1 &&
      e.deltaY > 0
    ) {
      if (e.deltaY > 40) {
        onChangeLayer(+1);
      }
    } else if (
      isOnScreen &&
      screenRef.current?.getBoundingClientRect().top >=
        gnbRef.current?.getBoundingClientRect().bottom &&
      activeLayer > 0 &&
      e.deltaY <= 0
    ) {
      if (e.deltaY < -40) {
        onChangeLayer(-1);
      }
    } else {
      window.scrollTo({ top: window.scrollY + e.deltaY });
    }
  };

  useEffect(() => {
    document
      .querySelector("#upper")
      .addEventListener("wheel", handleScroll, { passive: false });
    return function cleanup() {
      document
        .querySelector("#upper")
        .removeEventListener("wheel", handleScroll);
    };
  }, [isOnScreen, activeLayer, isScrolling]);

  const handleResize = () => {
    console.log(
      (window.innerHeight - gnbRef.current?.offsetHeight) / window.innerWidth <
        0.56
    );
    if (!!gnbRef.current) {
      setIsVerticalLong(
        (window.innerHeight - gnbRef.current?.offsetHeight) /
          window.innerWidth <
          0.56
      );
    }
  };
  const [isVerticalLong, setIsVerticalLong] = useState(false);
  useEffect(() => {
    document.addEventListener("readystatechange", handleResize);
    document.addEventListener("resize", handleResize);
    return function cleanUp() {
      document.removeEventListener("readystatechange", handleResize);
      document.removeEventListener("resize", handleResize);
    };
  }, [gnbRef.current]);

  return (
    <S.Wrapper>
      <S.Screen
        bg={bg}
        id="screen"
        ref={screenRef}
        gnbHeight={gnbRef.current?.offsetHeight}
        isVerticalLong={isVerticalLong}
      >
        <S.Title>{title}</S.Title>
        <S.Layer isActive={true} isFixed={true}>
          <S.AssetContainer>
            {fixedAssets.map((src) => (
              <S.Asset key={`asset-${src}`} src={src} />
            ))}
            <S.OnOffButton
              onClick={() => setOn((prevState) => !prevState)}
              isVerticalLong={isVerticalLong}
            >
              지역&건물표시 {on ? "OFF" : "ON  "}
            </S.OnOffButton>
            <S.Asset hidden={!on} src={marks} />
          </S.AssetContainer>
        </S.Layer>
        <S.ScrollLayerContainer>
          {scrollLayers.map((layer, groupIdx) => (
            <S.Layer
              key={`scroll-${groupIdx}`}
              id={`scroll-${groupIdx}`}
              index={groupIdx}
              isActive={activeLayer === groupIdx}
            >
              {!layer.isEmpty && (
                <>
                  <S.TextContentLayer isVerticalLong={isVerticalLong}>
                    <S.HeaderIcons isVerticalLong={isVerticalLong}>
                      {title === "광주교도소 사건 상황" &&
                      layer.index.number === 4 ? (
                        <S.HeaderIcon />
                      ) : layer.index.number === 3 &&
                        layer.index.color === "#CE813D" ? (
                        <S.HeaderIcon
                          key={`icon-exception`}
                          src={`/images/map/icon-army.png`}
                          isVerticalLong={isVerticalLong}
                        />
                      ) : (
                        (layer.icons ? layer.icons : icons).map((src) => (
                          <S.HeaderIcon
                            key={`icon-${src}`}
                            src={src}
                            isVerticalLong={isVerticalLong}
                          />
                        ))
                      )}

                      {layer.index.isFirst ? (
                        <S.HeaderIndex
                          color={layer.index.color}
                          isVerticalLong={isVerticalLong}
                          isFirst={true}
                        >
                          <div>{layer.index.number}</div>
                        </S.HeaderIndex>
                      ) : (
                        <S.HeaderIndex
                          color={layer.index.color}
                          isVerticalLong={isVerticalLong}
                          isFirst={false}
                        >
                          <div>{layer.index.number}</div>
                        </S.HeaderIndex>
                      )}

                      {layer.alphabet && (
                        <S.HeaderIndex
                          color={layer.alphabet.color}
                          isVerticalLong={isVerticalLong}
                        >
                          <div>{layer.alphabet.text}</div>
                        </S.HeaderIndex>
                      )}
                    </S.HeaderIcons>
                    <S.HeaderDatetime isVerticalLong={isVerticalLong}>
                      {layer.datetime}
                    </S.HeaderDatetime>
                    <S.Texts isActive={activeLayer === groupIdx}>
                      {layer.texts.map((content, c) => (
                        <S.TextPhrase
                          key={`phrase-${groupIdx}-${c}`}
                          isVerticalLong={isVerticalLong}
                        >
                          {content}
                        </S.TextPhrase>
                      ))}
                    </S.Texts>
                  </S.TextContentLayer>
                  <S.AssetContainer>
                    {layer.assets.map((asset) => (
                      <S.Asset
                        src={asset.src}
                        animation={asset.animation}
                        className={asset.animation}
                        key={`asset-${asset.src}`}
                      />
                    ))}
                  </S.AssetContainer>
                </>
              )}
            </S.Layer>
          ))}
        </S.ScrollLayerContainer>
      </S.Screen>
    </S.Wrapper>
  );
};
export default Map;
