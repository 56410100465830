import styled, { css, keyframes } from "styled-components";

export const Container = styled.div`
  width: 120%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
`;

export const ImageContent = styled.img`
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  //transform: translateY(-7%);
  //position: absolute;
  

  &.animation {
    animation-name: ${({ keyframe }) => keyframe};
    ${({ animation }) => (animation ? animation : null)};
`;
