import Illustration from "../../molecules/Illustration/Illustration";
import TextBook from "../../molecules/TextBook/TextBook";
import IllustHeader from "../../atoms/IllustHeader";

const IllustContainer = ({ data, refs, index, type }) => {
  const range = (number) => {
    let tmp = [];
    for (let i = 0; i < number; i++) {
      tmp.push(i);
    }
    return tmp;
  };
  return (
    <div
      ref={(el) => {
        if (!!refs) refs.current[index] = el;
      }}
    >
      <IllustHeader index={data.index} title={data.title} type={type} />
      {range(data.illustCnt).map((i) => (
        <Illustration
          key={`illust-${i}`}
          illustData={data.illust[i]}
          imageData={data.images[i]}
        />
      ))}
      <TextBook textBookData={data.textBook} />
    </div>
  );
};

export default IllustContainer;
