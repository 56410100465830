import * as S from "./Intro.style";
import { ArrowDownSvg } from "./Intro.svg";

export const Intro = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>
          <span className={"medium"}>5·18민주화운동</span>
          <br />
          <span className={"big"}>제3공수여단</span>
          <br />
          <span className={"small"}>진압작전 주요상황</span>
        </S.Title>
        <S.Location>광주역</S.Location>
        <S.ArrowDown>
          <ArrowDownSvg />
        </S.ArrowDown>

        <S.Row>
          <S.MediumFont>
            <S.LargeFont>
              1980. 5. 20
              <br />
              광주역 일원의 주요 상황
            </S.LargeFont>
            5. 20. 18:00 경 시작된 차량시위가 금남로에서
            <br />
            해산된 후 차량시위 대열은 무등경기장에서
            <br />
            나중에 출발한 차량시위 대열과 함께 광주역으로
            <br />
            향했다. 광주역으로 향하는 공용터미널, <br />
            광주고속버스터미널, 광주KBS 앞 도로에 <br />
            차량시위 대열이 형성되었다.
            <br />
            차량시위는 계엄군 저지선을 향해 차량으로
            <br /> 돌진하다가 광주역 분수대를 들이받고
            <br /> 정지하기도 했으며, 광주역 앞 방사형 도로를 <br />
            차단하고 있던 계엄군에게는 매우 위협적이었다.
            <br /> 현장의 지휘관들은 차량 공격을 저지하기 위해
            <br />
            <br />
          </S.MediumFont>
          {/*<S.ColumnDivider />*/}
          <S.MediumFont>
            <S.LargeFont>
              <br />
            </S.LargeFont>
            돌진해 오는 차량의 바퀴에 권총을 발사하기도
            <br />
            했다. 특히 광주역과 신안동 사거리 사이에 있던 <br />
            차량시위 대열 중에 트럭 한 대가 <br />
            신안동 사거리에서 진압작전을 하고 있던 <br />
            계엄군 중에 1명을 치어 사망케 한 사건이 <br />
            발생하였으며 이 사실은 무전으로 제3공수여단
            <br />
            대부분의 병력에게 전파되어 계엄군들의 심리를 <br />
            자극하였다. 광주 시청을 방어하던 제3공수여단이
            <br /> 광주역으로 이동하는 과정에서 시위대를 향한
            <br />
            집단발포가 있었고, 이 발포에 의해 민간인 다수가 <br />
            부상을 입거나 사망한 경우도 있었다.
          </S.MediumFont>
        </S.Row>
      </S.Container>
    </S.Wrapper>
  );
};
