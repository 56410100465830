import { useState } from "react";
import { useAtom } from "jotai";
import { usersAtom } from "../../stores/user";
import * as S from "./userinfochange.style";
import { instance } from "./Login";

const UserInfoChange = () => {
  const [user, _] = useAtom(usersAtom);
  const [newId, setNewId] = useState("");
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const onClickChangeId = async () => {
    const changeResult = await instance.post("/", {
      feature: "changeId",
      id,
      password,
      newId: newId,
    });

    if (changeResult.data.ok) {
      alert("아이디 변경을 성공했습니다!");
      window.location.href = "/intro";
    } else {
      alert("아이디 또는 비밀번호를 잘못입력했습니다.");
    }
  };

  const onClickChangePassword = async () => {
    if (newPassword1 !== newPassword2) {
      alert("비밀번호를 같게 입력해주셔야 합니다!");
      return;
    }

    const changeResult = await instance.post("/", {
      feature: "changePassword",
      id,
      password,
      newPassword: newPassword1,
    });

    if (changeResult.data.ok) {
      alert("비밀번호 변경에 성공했습니다!");
      window.location.href = "/intro";
    } else {
      alert("비밀번호 변경에 실패했습니다!");
    }
  };

  return (
    user.isSignIn && (
      <S.Wrapper>
        <S.Container>
          <S.Text>ID 변경하기</S.Text>
          <br />
          <S.Input
            placeholder={"아이디"}
            type={"text"}
            onChange={(e) => setId(e.target.value)}
          />
          <S.Input
            placeholder={"비밀번호"}
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <S.Input
            placeholder={"변경할 아이디 입력"}
            type={"text"}
            onChange={(e) => setNewId(e.target.value)}
          />
          <S.LoginButton onClick={() => onClickChangeId()}>
            아이디 변경하기
          </S.LoginButton>

          <br />
          <br />
          <S.Text>비밀번호 변경하기</S.Text>
          <br />
          <S.Input
            placeholder={"아이디"}
            type={"text"}
            onChange={(e) => setId(e.target.value)}
          />
          <S.Input
            placeholder={"비밀번호"}
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <S.Input
            placeholder={"변경할 비밀번호 입력"}
            type={"password"}
            onChange={(e) => setNewPassword1(e.target.value)}
          />
          <S.Input
            placeholder={"변경할 비밀번호 확인"}
            type={"password"}
            onChange={(e) => setNewPassword2(e.target.value)}
          />
          <S.LoginButton onClick={() => onClickChangePassword()}>
            비밀번호 변경하기
          </S.LoginButton>
        </S.Container>
      </S.Wrapper>
    )
  );
};
export default UserInfoChange;
