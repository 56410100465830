import { keyframes } from "styled-components";

/** section1 **/
export const section_1_image_1_keyframe = null;
export const section_1_image_1_animation = null;
export const section_1_image_2_keyframe = keyframes`
  0% {
    transform: translateX(100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_1_image_2_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  // animationTimingFunction: "ease-in",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_1_image_3_keyframe = keyframes`
  0% {
    transform: translateX(100%) scale(200%);
  }
  //70%{
  //  transform: translateX(30%) scale(200%);
  //}
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_1_image_3_animation = {
  animationDuration: "3.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_1_image_4_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(200%);
  }
  //70%{
  //  transform: translateX(-30%) scale(200%);
  //}
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_1_image_4_animation = {
  animationDuration: "2.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

/** section2 **/
export const section_2_image_1_keyframe = null;
export const section_2_image_1_animation = null;

export const section_2_image_2_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(200%) ;
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_2_image_2_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_2_image_3_keyframe = section_2_image_2_keyframe;
export const section_2_image_3_animation = section_2_image_2_animation;

export const section_2_image_4_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(150%) ;
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_2_image_4_animation = {
  animationDuration: "8s",
  animationDelay: "1.5s",
  animationDirection: "alternate",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_2_image_5_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(200%) ;
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_2_image_5_animation = {
  animationDuration: "4s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "ease-out",
  animationFillMode: "forwards",
  transition: "all",
};

/** section3 **/
export const section_3_image_1_keyframe = null;
export const section_3_image_1_animation = null;

export const section_3_image_2_keyframe = keyframes`
  0% {
    transform: translateX(-0.6%);
  }
  
  1% {
    transform: translateX(0.6%);
  }
  
  2% {
    transform: translateX(-0.6%);
  }
  
  3% {
    transform: translateX(0.6%);
  }
  
  4% {
    transform: translateX(-0.6%);
  }
  
  5% {
    transform: translateX(+0.6%);
  }
  
  6% {
    transform: translateX(-0.6%);
  }
  
  7% {
    transform: translateX(+0.6%);
  }
  
  7% {
    transform: translateX(-0.6%);
  }
  
  8% {
    transform: translateX(+0.6%);
  }
  
  9% {
    transform: translateX(-0.6%);
  }
  
  10% {
    transform: translateX(0.6%);
  }
  
  11% {
    transform: translateX(0%);
  }
  
  100% {
    transform: translateX(0%);
  }
`;
export const section_3_image_2_animation = {
  animationDuration: "1s",
  animationDelay: "3.5s",
  animationDirection: "alternate",
  animationIterationCount: "infinite",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const section_3_image_3_keyframe = keyframes`
  0% {
    opacity: 0;  
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const section_3_image_3_animation = {
  animationDuration: "2s",
  animationDelay: "3.5s",
  animationDirection: "alternate",
  animationIterationCount: "infinite",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const section_3_image_4_keyframe = keyframes`
  0% {
    transform: translateY(-80%) translateX(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) translateX(0%) scale(100%);
  }
`;
export const section_3_image_4_animation = {
  animationDuration: "2s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  // animationTimingFunction: "cubic-bezier(1,0,0.5,0.5)",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_3_image_5_keyframe = keyframes`
  0% {
    transform: scale(100%) translateX(-3%);  
  }
  //50% {
  //  transform: scale(150%) translateX(0%);
  //}
  100% {
    transform: scale(100%) translateX(3%);
  }
`;
export const section_3_image_5_animation = {
  animationDuration: "1s",
  animationDelay: "4s",
  animationDirection: "alternate",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const section_3_image_6_keyframe = section_3_image_3_keyframe;
export const section_3_image_6_animation = section_3_image_3_animation;

/** section4 **/
export const section_4_image_1_keyframe = null;
export const section_4_image_1_animation = null;

export const section_4_image_2_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_4_image_2_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_4_image_3_keyframe = keyframes`
  0% {
    transform: translateX(-3%);
  } 
  100% {
    transform: translateX(0%);
  }
`;
export const section_4_image_3_animation = {
  animationDuration: "4s",
  animationDelay: "7.8s",
  animationDirection: "alternate",
  animationIterationCount: "2",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const section_4_image_4_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const section_4_image_4_animation = {
  animationDuration: "1s",
  animationDelay: "5.8s",
  animationDirection: "alternate",
  animationIterationCount: "8",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const section_4_image_5_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_4_image_5_animation = {
  animationDuration: "4s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

/** section5 **/
export const section_5_image_1_keyframe = null;
export const section_5_image_1_animation = null;

export const section_5_image_2_keyframe = keyframes`
  0% {
    transform: translateX(100%) scale(200%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_5_image_2_animation = {
  animationDuration: "2s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_5_image_3_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const section_5_image_3_animation = {
  animationDuration: "0.7s",
  animationDelay: "5s",
  animationDirection: "alternate",
  animationIterationCount: "10",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const section_5_image_4_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(300%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_5_image_4_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_5_image_5_keyframe = section_5_image_3_keyframe;
export const section_5_image_5_animation = section_5_image_3_animation;

export const section_5_image_6_keyframe = keyframes`
  0% {
    transform: translateX(120%) scale(200%) ;
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;

export const section_5_image_6_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "ease-out",
  animationFillMode: "forwards",
  transition: "all",
};

/** section 6 - 1 **/
/**
 * 2번 - 6번 - 4번
 * 5번 연기
 * 3번 피 -> 2번이랑 같이 올라오는게 맞겠다.
 * **/

export const section_6_1_image_1_keyframe = null;
export const section_6_1_image_1_animation = null;

export const section_6_1_image_2_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_6_1_image_2_animation = {
  animationDuration: "2s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_6_1_image_3_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_6_1_image_3_animation = {
  animationDuration: "6s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_6_1_image_4_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_6_1_image_4_animation = {
  animationDuration: "4s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_6_1_image_5_keyframe = keyframes`
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(5%);
    opacity: 1;
  }
`;
export const section_6_1_image_5_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "alternate",
  animationIterationCount: "10",
  animationPlayState: "running",
  animationTimingFunction: "ease-out",
  animationFillMode: "",
  transition: "all",
};

export const section_6_1_image_6_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_6_1_image_6_animation = {
  animationDuration: "4s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "ease-out",
  animationFillMode: "both",
  transition: "all",
};

/** section 6 - 2 **/
/**
 * 2번 사람
 * 3번이 불꽃
 *
 * **/
export const section_6_2_image_1_keyframe = null;
export const section_6_2_image_1_animation = null;

export const section_6_2_image_2_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(300%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_6_2_image_2_animation = {
  animationDuration: "2s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "both",
  transition: "all",
};

export const section_6_2_image_3_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const section_6_2_image_3_animation = {
  animationDuration: "0.5s",
  animationDelay: "3s",
  animationDirection: "alternate",
  animationIterationCount: "10",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

const vibrate = (x, y) => {
  let ret = "";
  for (let i = 0; i <= 100; i++) {
    let nowX = x * (1 - 0.01 * i);
    if (i !== 100) {
      if (i % 2 === 0) {
        nowX -= 0.2;
      } else {
        nowX += 0.2;
      }
    }

    let nowY = (y * (1 - 0.01 * i)).toFixed(1);
    ret += `${i}% {\n   transform: translateX(${nowX}%) translateY(${nowY}%)\n}\n`;
  }
  return ret;
};

export const section_6_2_image_4_keyframe = keyframes`
  ${vibrate(-5, 5)}
`;
export const section_6_2_image_4_animation = {
  animationDuration: "3s",
  animationDelay: "3s",
  animationDirection: "alternate",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const section_6_2_image_5_keyframe = section_6_2_image_4_keyframe;
export const section_6_2_image_5_animation = section_6_2_image_4_animation;

export const section_6_2_image_6_keyframe = keyframes`
`;
export const section_6_2_image_6_animation = {};

/** section7 **/
export const section_7_image_1_keyframe = null;
export const section_7_image_1_animation = null;

export const section_7_image_2_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(150%);
  }
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_7_image_2_animation = {
  animationDuration: "5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_7_image_3_keyframe = keyframes`
  0% {
    transform: translateX(-100%) translateY(50%) scale(150%);
  }
  
  100% {
    transform: translateX(0%) translateY(0%) scale(100%);
  }
`;
export const section_7_image_3_animation = {
  animationDuration: "2s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_7_image_4_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(150%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_7_image_4_animation = {
  animationDuration: "1s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "ease-out",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_7_image_5_keyframe = keyframes`
  0% {
    transform: translateX(-100%) scale(150%);
    opacity: 0;
  }
  
  20% {
    opacity: 1;
  }
  
  100% {
    transform: translateX(0%) scale(100%);
  }
`;
export const section_7_image_5_animation = {
  animationDuration: "5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "forwards",
  transition: "all",
};

export const section_7_image_6_keyframe = section_7_image_5_keyframe;
export const section_7_image_6_animation = section_7_image_5_animation;

export const section_7_image_7_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(150%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const section_7_image_7_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "ease-out",
  animationFillMode: "forwards",
  transition: "all",
};
