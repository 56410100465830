const interviewBaseURL =
  "https://gwanjoo.s3.ap-northeast-2.amazonaws.com/interview/";

export const SectionOneThumbInfos = [
  {
    thumb: interviewBaseURL + "thumb/" + "1.jpg",
    video:
      interviewBaseURL + "video/" + "station/" + "section1/" + "kwaksukgi.mp4",
  },
  // {
  //   thumb: interviewBaseURL + "thumb/" + "2.jpg",
  //   video:
  //     interviewBaseURL + "video/" + "station/" + "section1/" + "kimgwisam.mp4",
  // },
  {
    thumb: interviewBaseURL + "thumb/" + "3.jpg",
    video:
      interviewBaseURL + "video/" + "station/" + "section1/" + "kimyoungha.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "4.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "station/" +
      "section2/" +
      "parkseunghyun.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "5.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "station/" +
      "section2/" +
      "choimyungyoung.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "6.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "station/" +
      "section3/" +
      "kwonmyeongsin.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "7.jpg",
    video:
      interviewBaseURL + "video/" + "station/" + "section4/" + "kimjongwon.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "8.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "station/" +
      "section4/" +
      "kimjongwon_3d.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "9.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "station/" +
      "section4/" +
      "choimyeongyoung.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "10.jpg",
    video:
      interviewBaseURL + "video/" + "station/" + "section5/" + "kwaksukki.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "11.jpg",
    video:
      interviewBaseURL + "video/" + "station/" + "section5/" + "kimgwisam.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "12.jpg",
    video:
      interviewBaseURL + "video/" + "station/" + "section6/" + "kimgwisam.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "13.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "station/" +
      "section6/" +
      "yoonhanggeun.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "14.jpg",
    video:
      interviewBaseURL + "video/" + "station/" + "section6/" + "leeyangyo.mp4",
  },
];

export const SectionTwoThumbInfos = [
  {
    thumb: interviewBaseURL + "thumb/" + "15.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "university/" +
      "section1/" +
      "choiyoungbeom.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "16.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "university/" +
      "section2/" +
      "parksunghyun.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "17.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "university/" +
      "section2/" +
      "parksunghyun_site.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "18.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "university/" +
      "section2/" +
      "yoonchunki.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "19.jpg",
    video:
      interviewBaseURL + "video/" + "university/" + "section3/" + "ryukoo.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "20.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "university/" +
      "section3/" +
      "youjongkyung.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "21.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "university/" +
      "section3/" +
      "choimyeongyoung.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "22.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "university/" +
      "section4/" +
      "choiyongbeom.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "23.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "university/" +
      "section5/" +
      "choimyeongyoung.mp4",
  },
];
export const SectionThreeThumbInfos = [
  {
    thumb: interviewBaseURL + "thumb/" + "24.jpg",
    video:
      interviewBaseURL + "video/" + "prison/" + "section1/" + "kimseungsik.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "25.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "prison/" +
      "section1/" +
      "youjongyeong.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "26.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "prison/" +
      "section1/" +
      "choimyeongyoung.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "27.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "prison/" +
      "section1/" +
      "hongbongchil-kimseungboo.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "28.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "prison/" +
      "section2/" +
      "kimbyeongjun.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "29.jpg",
    video:
      interviewBaseURL + "video/" + "prison/" + "section2/" + "leesangdeok.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "30.jpg",
    video:
      interviewBaseURL + "video/" + "prison/" + "section2/" + "leeyangyo.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "31.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "prison/" +
      "section2/" +
      "choiyoongbeom.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "32.jpg",
    video:
      interviewBaseURL + "video/" + "prison/" + "section3/" + "kimyoungha.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "33.jpg",
    video: interviewBaseURL + "video/" + "prison/" + "section3/" + "ryugoo.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "34.jpg",
    video:
      interviewBaseURL + "video/" + "prison/" + "section3/" + "yoonhangeun.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "35.jpg",
    video:
      interviewBaseURL + "video/" + "prison/" + "section3/" + "leeyangyo.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "36.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "prison/" +
      "section3/" +
      "choiyoungbeom.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "37.jpg",
    video:
      interviewBaseURL + "video/" + "prison/" + "section4/" + "kimyoungha.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "38.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "prison/" +
      "section4/" +
      "parkseunghyun.mp4",
  },
  {
    thumb: interviewBaseURL + "thumb/" + "39.jpg",
    video:
      interviewBaseURL +
      "video/" +
      "prison/" +
      "section4/" +
      "choiyoungbeom.mp4",
  },
];
