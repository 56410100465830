import { keyframes } from "styled-components";

/** section1 **/
export const university_section_1_image_1_keyframe = null;
export const university_section_1_image_1_animation = null;

export const university_section_1_image_2_keyframe = keyframes`
  0% {
    transform: translateX(-100%) translateY(70%) scale(200%);
  }
  100% {
    transform: translateX(0%) translateY(0%) scale(100%);
  }
`;
export const university_section_1_image_2_animation = {
  animationDuration: "2s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_1_image_3_keyframe = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`;
export const university_section_1_image_3_animation = {
  animationDuration: "3s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_1_image_4_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const university_section_1_image_4_animation = {
  animationDuration: "7s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_1_image_6_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const university_section_1_image_6_animation = {
  animationDuration: "5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_1_image_7_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) scale(100%);
  }
`;
export const university_section_1_image_7_animation = {
  animationDuration: "5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_1_image_5_keyframe = keyframes`
  0% {
    transform: translateX(-3%) translateY(100%) scale(200%);
  }
  80% {
    transform: translateX(-3%) translateY(0%) scale(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;
export const university_section_1_image_5_animation = {
  animationDuration: "8s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "forwards",
  transition: "all",
};

/** university_section2 **/
export const university_section_2_image_1_keyframe = null;
export const university_section_2_image_1_animation = null;

export const university_section_2_image_2_keyframe = keyframes`
  0% {
    transform: translateX(-2.5%);
  }
  100% {
    transform: translateX(2.5%);
  }
`;
export const university_section_2_image_2_animation = {
  animationDuration: "3s",
  animationDelay: "1s",
  animationDirection: "alternate",
  animationIterationCount: "5",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const university_section_2_image_3_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const university_section_2_image_3_animation = {
  animationDuration: "1s",
  animationDelay: "1s",
  animationDirection: "alternate",
  animationIterationCount: "20",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const university_section_2_image_4_keyframe = null;
export const university_section_2_image_4_animation = null;

/** university_section3 **/
export const university_section_3_image_1_keyframe = null;
export const university_section_3_image_1_animation = null;

export const university_section_3_image_2_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }  
  100%{
    transform: translateY(0%) scale(100%);
  }
`;
export const university_section_3_image_2_animation = {
  animationDuration: "1.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_3_image_3_keyframe =
  university_section_3_image_2_keyframe;
export const university_section_3_image_3_animation = {
  animationDuration: "2.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_3_image_4_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) sacle(100%);
  }
`;
export const university_section_3_image_4_animation = {
  animationDuration: "5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

/** university_section4 **/
export const university_section_4_image_1_keyframe = null;
export const university_section_4_image_1_animation = null;

export const university_section_4_image_2_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) sacle(100%);
  }
`;
export const university_section_4_image_2_animation = {
  animationDuration: "2s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_4_image_3_keyframe =
  university_section_4_image_2_keyframe;
export const university_section_4_image_3_animation =
  university_section_4_image_2_animation;

export const university_section_4_image_4_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100% {
    transform: translateY(0%) sacle(100%);
  }
`;
export const university_section_4_image_4_animation = {
  animationDuration: "3.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_4_image_5_keyframe =
  university_section_4_image_4_keyframe;
export const university_section_4_image_5_animation =
  university_section_4_image_4_animation;

export const university_section_4_image_6_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
    transform: translateY(10%) translateX(-10%);
  }
  49% {
    opacity: 0;
  }
  50% {
    transform: translateY(10%) translateX(-10%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%) translateX(0%);
  }
`;
export const university_section_4_image_6_animation = {
  animationDuration: "6s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_4_image_7_keyframe =
  university_section_4_image_6_keyframe;
export const university_section_4_image_7_animation =
  university_section_4_image_6_animation;

export const university_section_4_image_8_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
    transform: translateY(10%) translateX(10%);
  }
  49% {
    opacity: 0;
  }
  50% {
    transform: translateY(10%) translateX(10%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%) translateX(0%);
  }
`;
export const university_section_4_image_8_animation = {
  animationDuration: "5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_4_image_9_keyframe =
  university_section_4_image_8_keyframe;
export const university_section_4_image_9_animation =
  university_section_4_image_8_animation;

/** section5 (사실은 section3)**/
export const university_section_5_image_1_keyframe = null;
export const university_section_5_image_1_animation = null;

export const university_section_5_image_2_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100%{
    transform: translateY(0%) scale(100%);
  }
`;
export const university_section_5_image_2_animation = {
  animationDuration: "1.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_5_image_3_keyframe = keyframes`
  0% {
    transform: translateY(100%) scale(200%);
  }
  100%{
    transform: translateY(0%) scale(100%);
  }
`;
export const university_section_5_image_3_animation = {
  animationDuration: "2.5s",
  animationDelay: "1.5s",
  animationDirection: "normal",
  animationIterationCount: "1",
  animationPlayState: "running",
  animationTimingFunction: "cubic-bezier(0.47, 0.71, 0, 1.02)",
  animationFillMode: "forwards",
  transition: "all",
};

export const university_section_5_image_4_keyframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const university_section_5_image_4_animation = {
  animationDuration: "1s",
  animationDelay: "3.8s",
  animationDirection: "alternate",
  animationIterationCount: "8",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const university_section_5_image_5_keyframe = keyframes`
  0% {
    transform: translateX(-0.6%);
  }

  1% {
    transform: translateX(0.6%);
  }

  2% {
    transform: translateX(-0.6%);
  }

  3% {
    transform: translateX(0.6%);
  }

  4% {
    transform: translateX(-0.6%);
  }

  5% {
    transform: translateX(+0.6%);
  }

  6% {
    transform: translateX(-0.6%);
  }

  7% {
    transform: translateX(+0.6%);
  }

  7% {
    transform: translateX(-0.6%);
  }

  8% {
    transform: translateX(+0.6%);
  }

  9% {
    transform: translateX(-0.6%);
  }

  10% {
    transform: translateX(0.6%);
  }

  11% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(0%);
  }
`;
export const university_section_5_image_5_animation = {
  animationDuration: "2.8s",
  animationDelay: "4.8s",
  animationDirection: "alternate",
  animationIterationCount: "2",
  animationPlayState: "running",
  animationTimingFunction: "linear",
  animationFillMode: "both",
  transition: "all",
};

export const university_section_5_image_6_keyframe =
  university_section_5_image_4_keyframe;
export const university_section_5_image_6_animation =
  university_section_5_image_4_animation;
