import * as S from "./login.style";
import { useEffect, useState } from "react";
import axios from "axios";
import { getCookie, setCookie } from "../../logics/auth";
import { usersAtom } from "../../stores/user";
import { useAtom } from "jotai";
const baseURL =
  "https://zz4p8gd4c9.execute-api.ap-northeast-2.amazonaws.com/default/gwangjoo2";
axios.defaults.baseURL = baseURL;

export const instance = axios.create({
  baseURL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
  },
});

const Login = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useAtom(usersAtom);

  const onClickLoginButton = async () => {
    const loginResult = await instance.post("/", {
      feature: "login",
      id,
      password,
    });

    if (loginResult.data.ok) {
      setCookie(
        "accessToken",
        loginResult.data["accessToken"],
        parseInt(loginResult.data["days"])
      );
      setUser({ isSignIn: true, id: id });
      window.location.href = "/intro";
    } else {
      alert("id 또는 패스워드가 틀렸습니다.");
      setUser({ isSignIn: false });
    }
  };

  const onClickLogoutButton = async () => {
    setUser({ isSignIn: false });
    setCookie("accessToken", null);
  };

  if (user.isSignIn) {
    return (
      <S.Wrapper>
        <S.Container>
          <S.LoginButton onClick={() => onClickLogoutButton()}>
            LOGOUT
          </S.LoginButton>
        </S.Container>
      </S.Wrapper>
    );
  } else {
    return (
      <S.Wrapper>
        <S.Container>
          <S.Title src={"images/login/title.png"} />

          <S.Input
            placeholder={`ID`}
            type={"text"}
            onChange={(e) => setId(e.target.value)}
          />
          <S.Input
            placeholder={" PASSWORD"}
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <S.LoginButton onClick={() => onClickLoginButton()}>
            LOGIN
          </S.LoginButton>
        </S.Container>
        <S.Logo src={"/images/logo/logo_en.png"} />
      </S.Wrapper>
    );
  }
};
export default Login;
