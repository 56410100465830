import React, { useRef } from "react";
import Map from "../../components/organisms/Map/Map";
import { station } from "../../models/maps";
import { stationIllustrations } from "../../models/illustrations";
import IllustContainer from "../../components/organisms/IllustContainer/IllustContainer";
import { Intro } from "../../components/organisms/Intro/Intro";
import StationSummary from "../../components/organisms/StationSummary/StationSummary";
import {
  station_marks,
  station_summaries,
} from "../../models/illustrations/summary";
import { useAtom } from "jotai";
import { usersAtom } from "../../stores/user";
import Footer from "../../components/organisms/Footer/Footer";
import { isMobile } from "react-device-detect";
import MapMobile from "../../components/organisms/Map/MapMobile";

export let stationIllustRefs;
const Station = () => {
  stationIllustRefs = useRef(
    new Array(stationIllustrations.length + 1).fill(null)
  );
  const [user] = useAtom(usersAtom);
  return (
    user.isSignIn && (
      <>
        <div id={"upper"}>
          <Intro />
          {isMobile ? <MapMobile title={"station"} /> : <Map data={station} />}
        </div>
        {stationIllustrations.map((illustration, i) => (
          <IllustContainer
            data={illustration}
            refs={stationIllustRefs}
            index={i}
            key={`illustContainer-${illustration.title}`}
            type={"station"}
          />
        ))}
        <StationSummary sumarries={station_summaries} marks={station_marks} />
        {/*<Footer />*/}
      </>
    )
  );
};

export default Station;
