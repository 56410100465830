import * as S from "./IntroUniversity.style";
import { ArrowDownSvg } from "./IntroUniversity.svg";

export const IntroUniversity = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>
          <span className={"medium"}>5·18민주화운동</span>
          <br />
          <span className={"big"}>제3공수여단</span>
          <br />
          <span className={"small"}>진압작전 주요상황</span>
        </S.Title>
        <S.Location>전남대학교</S.Location>
        <S.ArrowDown>
          <ArrowDownSvg />
        </S.ArrowDown>

        <S.Row>
          <S.MediumFont>
            <S.LargeFont>
              1980. 5. 21
              <br />
              전남대 일원의 주요 상황
            </S.LargeFont>
            이른 아침부터 제3공수여단이 주둔하고 있던
            <br /> 전남대학교 정문에는 수많은 시위대가 몰려들기 <br />
            시작했다. 오전에 아세아자동차에서 몰고 나온
            <br />
            장갑차와 대형버스, 경찰 페퍼포그 등이 전남대 <br />
            정문에서 계엄군과 대치하였고, 11시경 시위대는 <br />
            전남대 정문을 돌파하여 학교 안으로 진입하였다. <br />
            이를 저지하기 위해 제3공수여단은 총격으로 <br />
            고등학생 1명을 사망케 하였고, 이 고등학생은 <br />
            전남대 야산에 가매장되었다가 이튿날 시민들에 <br />
            의해 수습되었다. 이날 13시경 도청 앞 금남로에서 <br />
            시작된 집단 발포와 동시에 전남대 정문에서도 <br />
          </S.MediumFont>
          {/*<S.ColumnDivider />*/}
          <S.MediumFont>
            <S.LargeFont>
              <br />
            </S.LargeFont>
            제3공수여단의 발포가 있었고, 이 과정에서 <br />
            전남대 앞 주택가에 살고 있던 임신 8개월 <br />된 주부가 피격되어
            현장에서 사망하였다.
            <br />
            전남대에 주둔하고 있던 제3공수여단은 5월 21일
            <br />
            16시 이후 전남대에서 광주교도소로 이동하였고,
            <br />
            전남대 이학부 강당에서 구금하고 있던
            <br />
            체포된 민간인들을 트럭에 싣고 이동하면서
            <br />
            트럭 적재함을 밀폐시킨 후 그 안에 최루탄을
            <br />
            터뜨려 이 안에서 일부 민간인들이 질식에 의해
            <br />
            사망하는 사건이 발생하기도 했다.
          </S.MediumFont>
        </S.Row>
      </S.Container>
    </S.Wrapper>
  );
};
