import * as S from "./station.summary.style";
import { Mark } from "../../molecules/Mark/Mark";
import { useState } from "react";
import { stationIllustRefs } from "../../../pages/Station/Station";

function StationSummary({ sumarries, marks }) {
  const [on, setOn] = useState(true);
  return (
    <S.Wrapper
      ref={(el) => {
        stationIllustRefs.current[stationIllustRefs.current.length - 1] = el;
      }}
    >
      <S.HeaderContainer>
        <S.HeaderText>광주역 피해자 지도</S.HeaderText>
      </S.HeaderContainer>

      <S.Container>
        <S.OnOffButton onClick={() => setOn((prevState) => !prevState)}>
          지역&건물표시 {on ? "OFF" : "ON  "}
        </S.OnOffButton>
        <S.Asset hidden={!on} src={"/images/map/station/marks.png"} />
        <S.TextContainer>
          {sumarries.map((summary, idx) => {
            return (
              <S.TextContent key={`summary-${idx}`}>
                <span>{summary}</span>
                <br />
              </S.TextContent>
            );
          })}
        </S.TextContainer>
        {marks.map((mark) => (
          <Mark mark={mark} key={`mark-${mark.top}-${mark.right}`} />
        ))}
      </S.Container>
    </S.Wrapper>
  );
}

export default StationSummary;
